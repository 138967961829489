import PropTypes from "prop-types";
import React, { Component }  from "react";
import mask from "./_mask.js";
import "./_polyfill";

// IE* parseFloat polyfill
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/parseFloat#Polyfill
Number.parseFloat = parseFloat;

class CurrencyInput extends Component {
  constructor(props) {
    super(props);
    this.prepareProps = this.prepareProps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = this.prepareProps(this.props);
  }

  getMaskedValue() {
    return this.state.maskedValue;
  }

  prepareProps(props) {
    let customProps = {...props}; // babeljs converts to Object.assign, then polyfills.
    delete customProps.onChange;
    delete customProps.onChangeEvent;
    delete customProps.value;
    delete customProps.decimalSeparator;
    delete customProps.thousandSeparator;
    delete customProps.precision;
    delete customProps.inputType;
    delete customProps.allowNegative;
    delete customProps.allowEmpty;
    delete customProps.prefix;
    delete customProps.suffix;
    delete customProps.selectAllOnFocus;

    let initialValue = props.value;
    if (initialValue === null) {
      initialValue = props.allowEmpty ? null : "";
    } else {
      if (typeof initialValue == "string") {
        // Strip out thousand separators, prefix, and suffix, etc.
        if (props.thousandSeparator === "."){
          // special handle the . thousand separator
          initialValue = initialValue.replace(/\./g, "");
        }

        if (props.decimalSeparator !== "."){
          // fix the decimal separator
          initialValue = initialValue.replace(new RegExp(props.decimalSeparator, "g"), ".");
        }

        //Strip out anything that is not a digit, -, or decimal separator
        initialValue = initialValue.replace(/[^0-9-.]/g, "");

        // now we can parse.
        initialValue = Number.parseFloat(initialValue);
      }
      initialValue = Number(initialValue).toLocaleString(undefined, {
        style: "decimal",
        minimumFractionDigits: props.precision,
        maximumFractionDigits: props.precision
      })

    }

    const { maskedValue, value } = mask(
      initialValue,
      props.precision,
      props.decimalSeparator,
      props.thousandSeparator,
      props.allowNegative,
      props.prefix,
      props.suffix
    );

    return { maskedValue, value, customProps };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.prepareProps(nextProps));
  }

  handleChange(event) {
    event.preventDefault();
    let { maskedValue, value } = mask(
      event.target.value,
      this.props.precision,
      this.props.decimalSeparator,
      this.props.thousandSeparator,
      this.props.allowNegative,
      this.props.prefix,
      this.props.suffix
    );

    event.persist();  // fixes issue #23

    this.setState({ maskedValue, value }, () => {
      this.props.onChange(maskedValue, value, event);
      this.props.onChangeEvent(event, maskedValue, value);
    });
  }

  render() {
    return (
      <input
        ref={(input) => { this.theInput = input; }}
        type={this.props.inputType}
        value={this.state.maskedValue}
        onChange={this.handleChange}
        {...this.state.customProps}
      />
    )
  }
}

/**
 * Prop validation.
 * @see https://facebook.github.io/react/docs/component-specs.html#proptypes
 */

CurrencyInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  precision: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputType: PropTypes.string,
  allowNegative: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  selectAllOnFocus: PropTypes.bool
};


CurrencyInput.defaultProps = {
  onChange: function(maskValue, value, event) {/*no-op*/},
  onChangeEvent: function(event, maskValue, value) {/*no-op*/},
  value: null,
  decimalSeparator: ".",
  thousandSeparator: ",",
  precision: "0",
  inputType: "text",
  allowEmpty: true,
  allowNegative: false,
  prefix: "",
  suffix: "",
  selectAllOnFocus: false
};

export default CurrencyInput;
