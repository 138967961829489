import React, {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {Redirect} from "react-router-dom";
import {AUTH_LOGOUT_ACTION} from "../../redux/actions";

const LogoutView = () => {
  const dispatch = useDispatch();
  const doLogout = useCallback(() => dispatch(AUTH_LOGOUT_ACTION()), [dispatch]);

  useEffect(() => {
    doLogout();
  });

  return <Redirect to="/" />;
};

export default LogoutView;
