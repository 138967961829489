import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {DOWN_PAYMENT_STATED_BANK_COUNT_SAVE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const DownPaymentStatedBankCount = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveAnswer = useCallback((value) => dispatch(DOWN_PAYMENT_STATED_BANK_COUNT_SAVE_ACTION(value)), [dispatch]);

  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const downPaymentStatedBankCount = useSelector( state => state.wizardStore.meta.downPaymentStatedBankCount);
  const applicantIndex = parseInt(currentApplicant.toString());

  const _clickHandler = (key, value) => {
    saveAnswer(value);
    navigateNext("down_payment_bank_accounts", false, applicantIndex);
  };

  return (
    <CardComponent title="Since some of the down payment is gifted / assisted, we will need to verify where funds originated.">
      <p className="cp-font-r18w400g500 mb-1 md:mb-4 text-justify">
        How many bank accounts will the down payment come from?
      </p>
      <OptionsComponent onClick={_clickHandler} options={downPaymentStatedBankCount} />
    </CardComponent>
  )
};

export default DownPaymentStatedBankCount;
