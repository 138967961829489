import {
  PERSONAL_DETAILS,
  PERSONAL_CURRENT_ADDRESS_LESS_THAN_TWO,
  PERSONAL_CURRENT_ADDRESS_MAIN,
  PERSONAL_CURRENT_ADDRESS_OWNERSHIP,
  PERSONAL_CURRENT_ADDRESS_MONTHLY_EXPENSES,
  PERSONAL_CURRENT_ADDRESS_YEARS_AT,
  PERSONAL_MAILING_ADDRESS_DIFFERENT,
  PERSONAL_MAILING_ADDRESS_MAIN,
  PERSONAL_MAILING_ADDRESS_YEARS_AT,
  PERSONAL_FORMER_ADDRESS_MAIN,
  PERSONAL_FORMER_ADDRESS_OWNERSHIP,
  PERSONAL_FORMER_ADDRESS_MONTHLY_EXPENSES,
  PERSONAL_FORMER_ADDRESS_YEARS_AT,
  PERSONAL_MILITARY_STATUS,
  PERSONAL_SUMMARY
} from "../../constants/loan/_personal_constants";

export const PERSONAL_DETAILS_ACTION = (info) => ({
  type: PERSONAL_DETAILS,
  payload: info
});

export const PERSONAL_CURRENT_ADDRESS_LESS_THAN_TWO_ACTION = (flag) => ({
  type: PERSONAL_CURRENT_ADDRESS_LESS_THAN_TWO,
  payload: flag
});

export const PERSONAL_CURRENT_ADDRESS_MAIN_ACTION = (info) => ({
  type: PERSONAL_CURRENT_ADDRESS_MAIN,
  payload: info
});

export const PERSONAL_CURRENT_ADDRESS_OWNERSHIP_ACTION = (value) => ({
  type: PERSONAL_CURRENT_ADDRESS_OWNERSHIP,
  payload: value
});

export const PERSONAL_CURRENT_ADDRESS_MONTHLY_EXPENSES_ACTION = (value) => ({
  type: PERSONAL_CURRENT_ADDRESS_MONTHLY_EXPENSES,
  payload: value
});

export const PERSONAL_CURRENT_ADDRESS_YEARS_AT_ACTION = (value) => ({
  type: PERSONAL_CURRENT_ADDRESS_YEARS_AT,
  payload: value
});

export const PERSONAL_MAILING_ADDRESS_DIFFERENT_ACTION = (flag) => ({
  type: PERSONAL_MAILING_ADDRESS_DIFFERENT,
  payload: flag
});

export const PERSONAL_MAILING_ADDRESS_MAIN_ACTION = (info) => ({
  type: PERSONAL_MAILING_ADDRESS_MAIN,
  payload: info
});

export const PERSONAL_MAILING_ADDRESS_YEARS_AT_ACTION = (value) => ({
  type: PERSONAL_MAILING_ADDRESS_YEARS_AT,
  payload: value
});

export const PERSONAL_FORMER_ADDRESS_MAIN_ACTION = (info) => ({
  type: PERSONAL_FORMER_ADDRESS_MAIN,
  payload: info
});

export const PERSONAL_FORMER_ADDRESS_OWNERSHIP_ACTION = (value) => ({
  type: PERSONAL_FORMER_ADDRESS_OWNERSHIP,
  payload: value
});

export const PERSONAL_FORMER_ADDRESS_MONTHLY_EXPENSES_ACTION = (value) => ({
  type: PERSONAL_FORMER_ADDRESS_MONTHLY_EXPENSES,
  payload: value
});

export const PERSONAL_FORMER_ADDRESS_YEARS_AT_ACTION = (value) => ({
  type: PERSONAL_FORMER_ADDRESS_YEARS_AT,
  payload: value
});

export const PERSONAL_MILITARY_STATUS_ACTION = (value) => ({
  type: PERSONAL_MILITARY_STATUS,
  payload: value
});

export const PERSONAL_SUMMARY_ACTION = (value) => ({
  type: PERSONAL_SUMMARY,
  payload: value
});
