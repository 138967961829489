import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SET_NEXT_PAGE, SET_SECTION_FLAG_ACTION} from "../../../redux/actions";
import {CardComponent} from "../../../components";

const DeclarationsSummary = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setSectionFlag = useCallback((section, flag) => dispatch(SET_SECTION_FLAG_ACTION(section, flag)), [dispatch]);
  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const totalApplicants = useSelector( state => state.wizardStore.meta.totalApplicants);
  const currentIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[currentIndex];
  const total = parseInt(totalApplicants.toString());

  const _continueHandler = () => {
    if (currentIndex < total - 1) {
      navigateNext("declarations_statements_first", false, currentIndex+1);
    } else {
      setSectionFlag("declarations", true);
      navigateNext("hmda_ethnicity", false, 0);
    }
  };

  const _editHandler = (state) => {
    navigateNext(state, true, currentIndex);
  };

  return (
    <CardComponent title={`${applicant.firstName}'s Declarations Summary`}>
      <div className="flex flex-col items-start max-w-xl">
        {
          (applicant && applicant.declarations != null) &&
          <div className="flex items-center justify-between mb-3 w-full">
            <span className="cp-font-r14w300g800">Declarations Page 1</span>
            <button className="cp-btn-review" onClick={() => _editHandler("declarations_statements_first")}>
              Edit
            </button>
          </div>
        }
        {
          (applicant && applicant.declarations != null) &&
          <div className="flex items-center justify-between mb-3 w-full">
            <span className="cp-font-r14w300g800">Declarations Page 2</span>
            <button className="cp-btn-review" onClick={() => _editHandler("declarations_statements_second")}>
              Edit
            </button>
          </div>
        }
      </div>
      <div className="flex flex-col items-center max-w-xl">
        <button className="cp-btn-primary" onClick={() => _continueHandler()}>
          Continue
        </button>
      </div>
    </CardComponent>
  )
};

export default DeclarationsSummary;
