import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {DOWN_PAYMENT_SELECTION_SAVE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const DownPaymentSelection = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveSelection = useCallback((value) => dispatch(DOWN_PAYMENT_SELECTION_SAVE_ACTION(value)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const downPaymentOptions = useSelector( state => state.wizardStore.meta.downPaymentOptions);
  const downPaymentOptionsWithVA = useSelector( state => state.wizardStore.meta.downPaymentOptionsWithVA);
  const applicantIndex = parseInt(currentApplicant.toString());

  const [options, setOptions] = useState(downPaymentOptions);
  const nonMilitary = applicants.filter(item => item.militaryStatus && item.militaryStatus === "None");

  useEffect(() => {
    if (applicants && applicants.length > 0 && nonMilitary) {
      setOptions(nonMilitary.length === applicants.length ? downPaymentOptions : downPaymentOptionsWithVA);
    }
  }, [applicants, downPaymentOptions, downPaymentOptionsWithVA, nonMilitary]);

  const _clickHandler = (key, value) => {
    saveSelection(value);
    navigateNext("down_payment_amount", false, applicantIndex);
  };

  return (
    <CardComponent title="How much would you like to put down?">
      <OptionsComponent onClick={_clickHandler} options={options} />
    </CardComponent>
  )
};

export default DownPaymentSelection;
