import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {EMPLOYMENT_START_DATE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, DigitsComponent, ErrorsComponent} from "../../../components";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  yearsWithCurrentEmployer: Yup
    .number()
    .positive("Years with current employer must be a positive number.")
    .max(99, "Value for years with current employer is way too high. :)")
    .required("Years with current employer is required.")
    .typeError("Value for years with current employer must be a number."),
  yearsInIndustry: Yup
    .number()
    .positive("Years in industry value must be a positive number.")
    .max(99, "Value for years in industry value is way too high. :)")
    .required("Value for years in industry is required.")
    .typeError("Value for years in industry must be a number.")
});

const EmploymentStartDate = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveData = useCallback((data) => dispatch(EMPLOYMENT_START_DATE_ACTION(data)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const currentEmploymentIndex = useSelector( state => state.wizardStore.meta.currentEmploymentIndex);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const {
    yearsWithCurrentEmployer,
    yearsInIndustry
  } = applicant && applicant.employment && applicant.employment[currentEmploymentIndex] ?
    applicant.employment[currentEmploymentIndex] : {};

  let employerName = "";
  if (applicant && applicant.employment && applicant.employment[currentEmploymentIndex]) {
    const employmentInfo = applicant.employment[currentEmploymentIndex];
    employerName = employmentInfo.name ? employmentInfo.name : "employer";
  }

  return (
    <Formik
      initialValues={{ yearsWithCurrentEmployer, yearsInIndustry }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        saveData(values);
        resetForm({});
        setSubmitting(false);
        navigateNext(inEditMode ? "employment_summary" : "employment_percent_ownership", inEditMode, applicantIndex);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent title={`Tell us a little about ${applicant.firstName}'s Employer`}>
          <DigitsComponent
            label={`How many years ${applicant.firstName} has already working for ${employerName}?`}
            name="yearsWithCurrentEmployer"
            onChange={handleChange}
            value={values.yearsWithCurrentEmployer}
          />
          <DigitsComponent
            label={`How many years in this industry has ${applicant.firstName}?`}
            name="yearsInIndustry"
            onChange={handleChange}
            value={values.yearsInIndustry}
          />
          {
            errors && touched.yearsWithCurrentEmployer && touched.yearsInIndustry &&
            <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  );
};

export default EmploymentStartDate;
