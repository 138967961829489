import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {
  MODEL_APPLICANTS_ALLOC_ACTION,
  MODEL_APPLICANTS_POP_ACTION,
  MODEL_SET_NUMBER_OF_STATED_APPLICANTS_ACTION,
  SET_NEXT_PAGE
} from "../../../redux/actions";

const IntroNumberOfApplicants = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const popApplicants = useCallback(() => dispatch(MODEL_APPLICANTS_POP_ACTION()), [dispatch]);
  const setApplicants = useCallback((value) => dispatch(MODEL_SET_NUMBER_OF_STATED_APPLICANTS_ACTION(value)), [dispatch]);
  const allocApplicants = useCallback(() => dispatch(MODEL_APPLICANTS_ALLOC_ACTION()), [dispatch]);

  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const numberOfStatedApplicantsOptions = useSelector( state => state.wizardStore.meta.numberOfStatedApplicantsOptions);
  const totalApplicants = useSelector( state => state.wizardStore.meta.totalApplicants);
  const cIndex = parseInt(currentApplicant.toString());
  const total = parseInt(totalApplicants.toString());

  const _clickHandler = (key, value) => {
    setApplicants(value);

    // we check if we are in edit mode and set the current applicant index according to what we know
    if (inEditMode) {
      if (total < parseInt(value)) {
        allocApplicants();
        // we set edit to false so that we make sure to advance and complete missing applicants
        navigateNext("intro_applicant_details", false, total);
      } else {
        popApplicants();
        navigateNext("intro_summary", false, 0);
      }
    } else {
      allocApplicants();
      navigateNext("intro_applicant_details", false, cIndex);
    }
  };

  return (
    <CardComponent hasProgress={true} title="How many people would you like to be included on the loan?">
      <OptionsComponent onClick={_clickHandler} options={numberOfStatedApplicantsOptions} />
    </CardComponent>
  )
};

export default IntroNumberOfApplicants;
