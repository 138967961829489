import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent} from "../../../components";
import {SET_NEXT_PAGE, SET_SECTION_FLAG_ACTION} from "../../../redux/actions";

const PersonalSummary = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setSectionFlag = useCallback((section, flag) => dispatch(SET_SECTION_FLAG_ACTION(section, flag)), [dispatch]);
  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const totalApplicants = useSelector( state => state.wizardStore.meta.totalApplicants);
  const applicantIndex = parseInt(currentApplicant.toString());
  const total = parseInt(totalApplicants.toString());
  const applicant = applicants[applicantIndex];

  const _continueHandler = () => {
    if (applicantIndex < total - 1) {
      navigateNext("personal_current_address_main", false, applicantIndex + 1);
    } else {
      setSectionFlag("personal", true);
      navigateNext("employment_question", false, 0);
    }
  };

  const _editHandler = (state) => {
    navigateNext(state, true, applicantIndex);
  };

  return (
    <CardComponent title="Summary - Personal Information">
      <div className="flex flex-col items-start max-w-xl">
        {
          (applicant && applicant.currentAddress != null) &&
          <div className="flex group items-center justify-between mb-3 w-full">
            <span className="cp-font-r14w300g800 group-hover:text-teal-500">
              <b>{applicant.firstName}'s</b> Current Address
            </span>
            <button className="cp-btn-review" onClick={() => _editHandler("personal_current_address_main")}>
              Edit
            </button>
          </div>
        }
        {
          (applicant && applicant.mailingAddress != null) &&
          <div className="flex group items-center justify-between mb-3 w-full">
            <span className="cp-font-r14w300g800 group-hover:text-teal-500">
              <b>{applicant.firstName}'s</b> Mailing Address
            </span>
            <button className="cp-btn-review" onClick={() => _editHandler("personal_mailing_address_question")}>
              Edit
            </button>
          </div>
        }
        {
          (applicant && applicant.formerAddress != null) &&
          <div className="flex group items-center justify-between mb-3 w-full">
            <span className="cp-font-r14w300g800 group-hover:text-teal-500">
              <b>{applicant.firstName}'s</b> Prior Address
            </span>
            <button className="cp-btn-review" onClick={() => _editHandler("personal_former_address_main")}>
              Edit
            </button>
          </div>
        }
        {
          (applicant && applicant.militaryStatus != null) &&
          <div className="flex group items-center justify-between mb-3 w-full">
            <span className="cp-font-r14w300g800 group-hover:text-teal-500">
              <b>{applicant.firstName}'s</b> Military Status
            </span>
            <button className="cp-btn-review" onClick={() => _editHandler("personal_military_status")}>
              Edit
            </button>
          </div>
        }
      </div>
      <div className="flex flex-col items-center max-w-xl">
        <button className="cp-btn-primary" onClick={() => _continueHandler()}>
          Continue
        </button>
      </div>
    </CardComponent>
  )
};

export default PersonalSummary;
