import _ from "lodash";
import allConfigs from "./config.json";

// Get our environment from the domain
const hostname = window && window.location && window.location.hostname;
let environment = "development";
const defaultConfig = allConfigs[environment];

if(hostname === "consumer.introlend.com") {
  environment = "production";
}

const environmentConfig = allConfigs[environment];
const Config = _.merge(defaultConfig, environmentConfig);

export default Config
