import React from "react";
import {Link} from "react-router-dom";

const RouteNotMatched = ({location}) => {
  return (
    <div className="bg-teal-500 font-mono flex flex-col items-center justify-center h-screen text-white w-full">
      <div className="mb-8">
        <h1 className="text-8xl">404</h1>
        <p className="text-4xl">Not Found</p>
      </div>
      <div className="mb-8">
        <p>Page "<code>{location.pathname}</code>" doesn't exist.</p>
      </div>
      <div className="flex justify-center">
        <Link className="cp-btn-primary" to="/">
          Take Me Home
        </Link>
      </div>
    </div>
  )
};

export default RouteNotMatched;
