import RUF from "../../util/redux";

import {
  GET_LOANS_PENDING,
  GET_LOANS_FULFILLED,
  GET_LOANS_REJECTED,
  GET_LOAN_BIDS_PENDING,
  GET_LOAN_BIDS_FULFILLED,
  GET_LOAN_BIDS_REJECTED,
  SELECT_LOAN_BID_PENDING,
  SELECT_LOAN_BID_FULFILLED,
  SELECT_LOAN_BID_REJECTED,
  GET_LOAN_STIPULATIONS_PENDING,
  GET_LOAN_STIPULATIONS_FULFILLED,
  GET_LOAN_STIPULATIONS_REJECTED,
  GET_USER_STIPULATIONS_PENDING,
  GET_USER_STIPULATIONS_FULFILLED,
  GET_USER_STIPULATIONS_REJECTED,
  UPLOAD_STIPULATION_PENDING,
  UPLOAD_STIPULATION_FULFILLED,
  UPLOAD_STIPULATION_REJECTED
} from "../constants/_docs_constants";

const asyncFlowInitialState = {
  isCompleted: false,
  inProgress: false,
  errors: null
};

const initialState = {
  loans: {
    selectedLoanID: null,
    selectedLoan: null,
    list: null,
    ...asyncFlowInitialState
  },
  loanBids: {
    list: null,
    ...asyncFlowInitialState
  },
  loanStipulations: {
    list: null,
    ...asyncFlowInitialState
  },
  userStipulations: {
    list: null,
    ...asyncFlowInitialState
  },
  uploadingStipulations: false,
  selectingBid: false
};

export default function docsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOANS_PENDING: {
      return RUF.updateObject(state, {
        loans: RUF.updateObject(state.loans, {
          list: [],
          selectedLoanID: null,
          selectedLoan: null,
          isCompleted: false,
          inProgress: true,
          errors: null
        })
      });
    }
    case GET_LOANS_FULFILLED: {
      return RUF.updateObject(state, {
        loans: RUF.updateObject(state.loans, {
          list: action.payload.data,
          selectedLoanID: (action.payload.data || []).length > 0 ? action.payload.data[0].guid : null,
          selectedLoan: (action.payload.data || []).length > 0 ? action.payload.data[0] : null,
          isCompleted: true,
          inProgress: false
        })
      });
    }
    case GET_LOANS_REJECTED: {
      return RUF.updateObject(state, {
        loans: RUF.updateObject(state.loans, {
          list: [],
          selectedLoanID: null,
          selectedLoan: null,
          isCompleted: true,
          inProgress: false,
          errors: [action.payload]
        })
      });
    }
    case GET_LOAN_BIDS_PENDING: {
      return RUF.updateObject(state, {
        loanBids: RUF.updateObject(state.loanBids, {
          list: [],
          isCompleted: false,
          inProgress: true,
          errors: null
        })
      });
    }
    case GET_LOAN_BIDS_FULFILLED: {
      return RUF.updateObject(state, {
        loanBids: RUF.updateObject(state.loanBids, {
          list: action.payload.data,
          isCompleted: true,
          inProgress: false
        })
      });
    }
    case GET_LOAN_BIDS_REJECTED: {
      return RUF.updateObject(state, {
        loanBids: RUF.updateObject(state.loanBids, {
          list: [],
          isCompleted: true,
          inProgress: false,
          errors: [action.payload]
        })
      });
    }
    case SELECT_LOAN_BID_PENDING: {
      return RUF.updateObject(state, {
        selectingBid: true
      });
    }
    case SELECT_LOAN_BID_FULFILLED: {
      return RUF.updateObject(state, {
        selectingBid: false
      });
    }
    case SELECT_LOAN_BID_REJECTED: {
      return RUF.updateObject(state, {
        selectingBid: false
      });
    }
    case GET_LOAN_STIPULATIONS_PENDING: {
      return RUF.updateObject(state, {
        loanStipulations: RUF.updateObject(state.loanStipulations, {
          list: [],
          isCompleted: false,
          inProgress: true,
          errors: null
        })
      });
    }
    case GET_LOAN_STIPULATIONS_FULFILLED: {
      return RUF.updateObject(state, {
        loanStipulations: RUF.updateObject(state.loanStipulations, {
          list: action.payload.data,
          isCompleted: true,
          inProgress: false
        })
      });
    }
    case GET_LOAN_STIPULATIONS_REJECTED: {
      return RUF.updateObject(state, {
        loanStipulations: RUF.updateObject(state.loanStipulations, {
          list: [],
          isCompleted: true,
          inProgress: false,
          errors: [action.payload]
        })
      });
    }
    case GET_USER_STIPULATIONS_PENDING: {
      return RUF.updateObject(state, {
        userStipulations: RUF.updateObject(state.userStipulations, {
          list: [],
          isCompleted: false,
          inProgress: true,
          errors: null
        })
      });
    }
    case GET_USER_STIPULATIONS_FULFILLED: {
      return RUF.updateObject(state, {
        userStipulations: RUF.updateObject(state.userStipulations, {
          list: action.payload.data,
          isCompleted: true,
          inProgress: false
        })
      });
    }
    case GET_USER_STIPULATIONS_REJECTED: {
      return RUF.updateObject(state, {
        userStipulations: RUF.updateObject(state.userStipulations, {
          list: [],
          isCompleted: true,
          inProgress: false,
          errors: [action.payload]
        })
      });
    }
    case UPLOAD_STIPULATION_PENDING: {
      return RUF.updateObject(state, {
        uploadingStipulations: true
      });
    }
    case UPLOAD_STIPULATION_FULFILLED: {
      return RUF.updateObject(state, {
        uploadingStipulations: false
      });
    }
    case UPLOAD_STIPULATION_REJECTED: {
      return RUF.updateObject(state, {
        uploadingStipulations: false
      });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
