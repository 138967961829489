import RUF from "../../../util/redux";

export function modelApplicantsPop(state) {
  const array = state.model.applicants;
  const numberOfStatedApplicants = parseInt(state.model.numberOfStatedApplicants);

  return RUF.updateObject(state, {
    model: RUF.updateObject(state.model, {
      applicants: numberOfStatedApplicants > -1 ? array.splice(0, numberOfStatedApplicants) : array
    })
  });
}
