// Debts Information related Redux Constants
export const [
  DEBTS_OTHER_OBLIGATIONS,
  DEBTS_PERMISSION_QUESTION,
  DEBTS_SET_SSN
] = [
  "DEBTS_OTHER_OBLIGATIONS",
  "DEBTS_PERMISSION_QUESTION",
  "DEBTS_SET_SSN"
];
