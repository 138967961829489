import RUF from "../../util/redux";

import {
  AUTH_CHECK_USER_EXISTENCE_PENDING,
  AUTH_CHECK_USER_EXISTENCE_FULFILLED,
  AUTH_CHECK_USER_EXISTENCE_REJECTED,
  AUTH_LOGIN_PENDING,
  AUTH_LOGIN_FULFILLED,
  AUTH_LOGIN_REJECTED,
  AUTH_LOGOUT_FULFILLED,
  AUTH_LOGOUT_PENDING,
  AUTH_LOGOUT_REJECTED,
  AUTH_SET_IS_AUTHENTICATED,
  AUTH_SET_LINK_AUTH_OBJECT,
  AUTH_SET_PASSWORD_PENDING,
  AUTH_SET_PASSWORD_FULFILLED,
  AUTH_SET_PASSWORD_REJECTED,
  CONSUMER_REGISTER_PENDING,
  CONSUMER_REGISTER_FULFILLED,
  CONSUMER_REGISTER_REJECTED
} from "../constants/auth";

const asyncFlowInitialState = {
  isCompleted: false,
  inProgress: false,
  hasErrors: false,
  errors: null
};

const initialState = {
  main: {
    isAuthenticated: false,
    isUserFoundInDb: false
  },
  checkExists: {
    ...asyncFlowInitialState
  },
  consumerRegister: {
    ...asyncFlowInitialState,
  },
  linkAuth: {
    hasPerformed: false,
    isNotTheSameAsLoggedOne: false,
    applicationID: null,
    destination: null,
    email: null,
    hash: null,
    userID: null
  },
  loginAuth: {
    ...asyncFlowInitialState
  },
  logoutAuth: {
    ...asyncFlowInitialState
  },
  setPass: {
    ...asyncFlowInitialState
  }
};

const _makeFlowObj = (isCompleted, inProgress, hasErrors, errors) => {
  return {isCompleted, inProgress, hasErrors, errors}
};

export default function authReducer(state = initialState, action) {
  switch(action.type) {
    case AUTH_CHECK_USER_EXISTENCE_PENDING: {
      return RUF.updateObject(state, {
        checkExists: RUF.updateObject(state.checkExists,
          _makeFlowObj(false, true, false, null)
        )
      });
    }
    case AUTH_CHECK_USER_EXISTENCE_FULFILLED: {
      return RUF.updateObject(state, {
        checkExists: RUF.updateObject(state.checkExists,
          _makeFlowObj(true, false, false, null)
        ),
        main: RUF.updateObject(state.main, {
          isUserFoundInDb: action.payload.data && action.payload.data.Exists ? action.payload.data.Exists : false,
        })
      });
    }
    case AUTH_CHECK_USER_EXISTENCE_REJECTED: {
      return RUF.updateObject(state, {
        checkExists: RUF.updateObject(state.checkExists,
          _makeFlowObj(true, false, true, action.payload)
        )
      });
    }
    case AUTH_LOGIN_PENDING: {
      return RUF.updateObject(state, {
        loginAuth: RUF.updateObject(state.loginAuth,
          _makeFlowObj(false, true, false, null)
        ),
        main: RUF.updateObject(state.main, {
          isAuthenticated: false
        })
      });
    }
    case AUTH_LOGIN_FULFILLED: {
      return RUF.updateObject(state, {
        loginAuth: RUF.updateObject(state.loginAuth,
          _makeFlowObj(true, false, false, null)
        ),
        main: RUF.updateObject(state.main, {
          isAuthenticated: true
        })
      });
    }
    case AUTH_LOGIN_REJECTED: {
      return RUF.updateObject(state, {
        loginAuth: RUF.updateObject(state.loginAuth,
          _makeFlowObj(true, false, true, action.payload)
        ),
        main: RUF.updateObject(state.main, {
          isAuthenticated: false
        })
      });
    }
    case AUTH_LOGOUT_PENDING: {
      return RUF.updateObject(state, {
        logoutAuth: RUF.updateObject(state.logoutAuth,
          _makeFlowObj(false, true, false, null)
        )
      });
    }
    case AUTH_LOGOUT_FULFILLED: {
      return RUF.updateObject(state, {
        logoutAuth: RUF.updateObject(state.logoutAuth,
          _makeFlowObj(true, false, false, null)
        ),
        main: RUF.updateObject(state.main, {
          isAuthenticated: false
        })
      });
    }
    case AUTH_LOGOUT_REJECTED: {
      return RUF.updateObject(state, {
        logoutAuth: RUF.updateObject(state.logoutAuth,
          _makeFlowObj(true, false, true, action.payload)
        )
      });
    }
    case AUTH_SET_IS_AUTHENTICATED: {
      return RUF.updateObject(state, {
        main: RUF.updateObject(state.main, {
          isAuthenticated: action.payload
        })
      });
    }
    case AUTH_SET_LINK_AUTH_OBJECT: {
      return RUF.updateObject(state, {
        linkAuth: RUF.updateObject(state.linkAuth, action.payload)
      });
    }
    case AUTH_SET_PASSWORD_PENDING: {
      return RUF.updateObject(state, {
        setPass: RUF.updateObject(state.setPass,
          _makeFlowObj(false, true, false, null)
        )
      });
    }
    case AUTH_SET_PASSWORD_FULFILLED: {
      return RUF.updateObject(state, {
        setPass: RUF.updateObject(state.setPass,
          _makeFlowObj(true, false, false, null)
        )
      });
    }
    case AUTH_SET_PASSWORD_REJECTED: {
      return RUF.updateObject(state, {
        setPass: RUF.updateObject(state.setPass,
          _makeFlowObj(true, false, true, action.payload)
        )
      });
    }
    case CONSUMER_REGISTER_PENDING: {
      return RUF.updateObject(state, {
        consumerRegister: RUF.updateObject(state.consumerRegister,
          _makeFlowObj(false, true, false, null)
        )
      });
    }
    case CONSUMER_REGISTER_FULFILLED: {
      return RUF.updateObject(state, {
        consumerRegister: RUF.updateObject(state.consumerRegister,
          _makeFlowObj(true, false, false, null)
        )
      });
    }
    case CONSUMER_REGISTER_REJECTED: {
      return RUF.updateObject(state, {
        consumerRegister: RUF.updateObject(state.consumerRegister,
          _makeFlowObj(true, false, true, action.payload)
        )
      });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
