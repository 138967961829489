import {SET_LOAN_PLANS_DATA} from "../../constants/_plans_constants";
import {SET_SPINNER_FLAG_ACTION} from "../spinner/_spinner_actions";
import {_callApi} from "../../../util/fetch";

export function SET_LOAN_PLANS_DATA_ACTION(data) {
  return {
    type: SET_LOAN_PLANS_DATA,
    payload: data
  };
}

export function SELECT_LOAN_PLAN_ACTION(loanApplicationID, loanDefinitionID) {
  return (dispatch) => {
    dispatch(SET_SPINNER_FLAG_ACTION(true));
    _callApi(
      "loanApplications",
      "/LoanApplications/preapprove",
      "PUT",
      {
        "loanApplicationID": loanApplicationID,
        "loanDefinitionID": loanDefinitionID,
        "sendEmailToConsumer": true
      }
    ).then((response) => {
      dispatch(SET_SPINNER_FLAG_ACTION(false));
      if (response.status === 200 && response.data) {
        window.appHistory.push("/docs");
      } else {
        window.appHistory.push("/referred-to-finance");
      }
    }).catch((error) => {
      console.log(error);
    })
  };
}
