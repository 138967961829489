import React, {useCallback} from "react";
import {CardComponent} from "../../../components";
import {SET_NEXT_PAGE, SET_SECTION_FLAG_ACTION} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";

const ContactSummary = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setSectionFlag = useCallback((section, flag) => dispatch(SET_SECTION_FLAG_ACTION(section, flag)), [dispatch]);
  const applicants = useSelector( state => state.wizardStore.model.applicants);

  const _editApplicant = (index) => {
    navigateNext("contact_details", true, index);
  };

  const _startHandler = () => {
    setSectionFlag("contact", true);
    navigateNext("personal_current_address_main", false, 0);
  };

  return (
    <CardComponent title="Summary - Contact Information">
      {
        applicants && applicants.map((applicant, index) =>
          <div className="flex group items-center justify-between mb-3" key={`review-applicant-contact-${index}`}>
                <span className="cp-font-r14w300g800 group-hover:text-teal-500">
                  <b>{applicant.firstName}'s</b> Contact Information
                </span>
            <button className="cp-btn-review" onClick={() => _editApplicant(index)}>
              Edit
            </button>
          </div>
        )
      }
      <div className="flex flex-col items-center max-w-xl">
        <button className="cp-btn-primary" onClick={() => _startHandler()}>Continue</button>
      </div>
    </CardComponent>
  )
};

export default ContactSummary;
