import React from "react";

const WithGradientLayout = (WrappedComponent) => {
  return (
    <div className="bg-gradient-white-teal h-full min-h-screen w-screen">
      <WrappedComponent />
    </div>
  );
};

export default WithGradientLayout;
