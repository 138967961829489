import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {DOWN_PAYMENT_GIFTED_QUESTION_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const DownPaymentGiftedQuestion = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveAnswer = useCallback((answer) => dispatch(DOWN_PAYMENT_GIFTED_QUESTION_ACTION(answer)), [dispatch]);

  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const yesNoAnswerOptions = useSelector( state => state.wizardStore.meta.yesNoAnswerOptions);
  const applicantIndex = parseInt(currentApplicant.toString());

  const _clickHandler = (key, value) => {
    saveAnswer(value);
    navigateNext("down_payment_assisted_question", false, applicantIndex);
  };

  return (
    <CardComponent title="Is any part of the down payment gifted?">
      <OptionsComponent onClick={_clickHandler} options={yesNoAnswerOptions} />
    </CardComponent>
  )
};

export default DownPaymentGiftedQuestion;
