import React, {useState} from "react";
import Radio from "./_radio";
import {ButtonComponent} from "../index";

const RadioGroup = ({defaultValue, options, submitHandler}) => {
  const [answer, setAnswer] = useState(defaultValue);

  const _handleChange = (event) => setAnswer(event.target.value);
  const _handleSubmit = () => submitHandler(answer);

  return (
    <div className="mt-4">
      {
        options.map(item => (
          <Radio
            checked={answer === item.value}
            key={item.key}
            label={item.label}
            onChange={_handleChange}
            value={item.value}
          />
        ))
      }
      <ButtonComponent onClick={_handleSubmit} text="Continue" />
    </div>
  );
};

export default RadioGroup;
