import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import Welcome from "@haiku/cosmin-welcome/react";
import {SET_NEXT_PAGE} from "../../../redux/actions";

const IntroWelcome = () => {
  const dispatch = useDispatch();
  const startIntro = useCallback(() => dispatch(SET_NEXT_PAGE("intro_loan_purpose")), [dispatch]);

  return (
    <div className="flex flex-col items-center max-w-xl">
      <div className="h-auto max-w-md mb-4 md:mb-10 w-auto">
        <Welcome loop={true} />
      </div>
      <h1 className="cp-font-r36w500g800 text-center">Hi There!</h1>
      <h1 className="cp-font-r36w500g800 mb-6 text-center">And Welcome to IntroLend.</h1>
      <p className="cp-font-r18w400g500 mb-1 md:mb-4 text-justify" style={{textIndent: "40px"}}>
        Getting approved for a mortgage and connected with a preferred lender has never been this easy.
        For the next few minutes, our application process will guide you through the mortgage interview.
        We will be saving your work as you progress so you can always step away and come back if needed.
      </p>
      <button className="cp-btn-primary mt-6" onClick={startIntro}>
        Let's get started
      </button>
    </div>
  );
};

export default IntroWelcome;
