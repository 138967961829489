import React, {Fragment, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Field, FieldArray, Formik, getIn} from "formik";
import {DOWN_PAYMENT_BANK_ACCOUNTS_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent} from "../../../components";
import * as Yup from "yup";

const DownPaymentBankAccounts = () => {
  const dispatch = useDispatch();
  const saveBankAccounts = useCallback((array) => dispatch(DOWN_PAYMENT_BANK_ACCOUNTS_ACTION(array)), [dispatch]);
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);

  let bankAccounts = useSelector( state => state.wizardStore.model.downPayment.bankAccounts);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const numberOfBankAccounts = useSelector( state => state.wizardStore.model.downPayment.statedBankCount);
  const applicantIndex = parseInt(currentApplicant.toString());

  if (!bankAccounts || bankAccounts.length === 0) {
    bankAccounts = Array(numberOfBankAccounts).fill({accountNumber: "", name: ""});
  }

  return (
    <Formik
      initialValues={{ bankAccounts }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        saveBankAccounts(values);
        resetForm({});
        setSubmitting(false);
        navigateNext("down_payment_closing_costs", false, applicantIndex);
      }}
      render={({values, errors, handleSubmit }) => (
        <CardComponent title={`Please provide the account information for these ${numberOfBankAccounts} bank accounts.`}>
          <FieldArray
            name="bankAccounts"
            render={() => (
            <div className="flex flex-col">
              {
                values.bankAccounts && values.bankAccounts.map((account, index) => {
                    const nameField = `bankAccounts[${index}].name`;
                    const accountNumberField = `bankAccounts[${index}].accountNumber`;
                    const nameError = getIn(errors, nameField);
                    const numberError = getIn(errors, accountNumberField);

                    return (
                      <Fragment key={index}>
                        <div className="flex flex-row">
                          <Field
                            name={nameField}
                            render={({ field /* _form */ }) => (
                              <div className="flex flex-col border-b border-teal-500 py-2 mb-2 md:mb-4">
                                <label className="block cp-font-r14w300g800 mb-1">Name of Bank</label>
                                <input className="cp-input" {...field} />
                              </div>
                            )}
                          />
                          <div className="m-5" />
                          <Field
                            name={accountNumberField}
                            render={({ field /* _form */ }) => (
                              <div className="flex flex-col border-b border-teal-500 py-2 mb-2 md:mb-4">
                                <label className="block cp-font-r14w300g800 mb-1">Account Number</label>
                                <input className="cp-input" {...field} />
                              </div>
                            )}
                          />
                        </div>
                        {
                          nameError && <div className="font-sans font-normal my-2 text-red-500">{nameError}</div>
                        }
                        {
                          numberError && <div className="font-sans font-normal mt-2 mb-4 text-red-500">{numberError}</div>
                        }
                      </Fragment>
                    )
                })
              }
            </div>
            )}
          />
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={() => {
        return Yup.object().shape({
          bankAccounts: Yup.array().of(
            // these constraints take precedence
            Yup.object().shape({
              name: Yup.string()
              .min(2, "Bank account name is too short.")
              .required("Bank account name is required."),
              accountNumber: Yup.string()
              .min(3, "Bank account number is too short.")
              .required("Bank account number is required.")
            })
          )
          // these constraints are shown if and only if inner constraints are satisfied
          .required("In order to continue, please fill all input fields.")
          .min(numberOfBankAccounts, `All fields related to this ${numberOfBankAccounts} bank accounts should be completed.`)
        });
      }}
    />
  );
};

export default DownPaymentBankAccounts;
