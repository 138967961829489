import {
  EMPLOYMENT_QUESTION,
  EMPLOYMENT_MAIN,
  EMPLOYMENT_START_DATE,
  EMPLOYMENT_PERCENT_OWNERSHIP,
  EMPLOYMENT_AGI_AND_DEPRECIATION,
  EMPLOYMENT_MONTHLY_EARNINGS,
  EMPLOYMENT_MULTIPLE_EMPLOYMENTS_QUESTION,
  EMPLOYMENT_MISC_INCOME
} from "../../constants/loan/_employment_constants";

export const EMPLOYMENT_QUESTION_ACTION = (flag) => ({
  type: EMPLOYMENT_QUESTION,
  payload: flag
});

export const EMPLOYMENT_MAIN_ACTION = (value) => ({
  type: EMPLOYMENT_MAIN,
  payload: value
});

export const EMPLOYMENT_START_DATE_ACTION = (value) => ({
  type: EMPLOYMENT_START_DATE,
  payload: value
});

export const EMPLOYMENT_PERCENT_OWNERSHIP_ACTION = (value) => ({
  type: EMPLOYMENT_PERCENT_OWNERSHIP,
  payload: value
});

export const EMPLOYMENT_AGI_AND_DEPRECIATION_ACTION = (value) => ({
  type: EMPLOYMENT_AGI_AND_DEPRECIATION,
  payload: value
});

export const EMPLOYMENT_MONTHLY_EARNINGS_ACTION = (value) => ({
  type: EMPLOYMENT_MONTHLY_EARNINGS,
  payload: value
});

export const EMPLOYMENT_MULTIPLE_EMPLOYMENTS_QUESTION_ACTION = (value) => ({
  type: EMPLOYMENT_MULTIPLE_EMPLOYMENTS_QUESTION,
  payload: value
});

export const EMPLOYMENT_MISC_INCOME_ACTION = (value) => ({
  type: EMPLOYMENT_MISC_INCOME,
  payload: value
});
