import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import CheckboxContainer from "../../../components/checkbox/_container";
import {DECLARATIONS_STATEMENTS_SAVE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent} from "../../../components";

const DeclarationsStatementsSecond = () => {
  const dispatch = useDispatch();
  const saveInformation = useCallback((data) => dispatch(DECLARATIONS_STATEMENTS_SAVE_ACTION(data)), [dispatch]);
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const declarations = applicant.declarations ? applicant.declarations : {
    "alimonyChildSupport": false,
    "downpaymentBorrowed": false,
    "coMakerEndorser": false,
    "usCitizen": false,
    "permanentResident": false,
    "primaryResidence": false,
    "ownershipInterestInPropertyLastThreeYears": false
  };

  const _handleSubmit = (response) => {
    saveInformation(response);
    navigateNext("declarations_summary", false, applicantIndex);
  };

  return (
    <CardComponent title={`${applicant.firstName}, select any and all of the following statements that are true.`}>
      <CheckboxContainer checkboxes={
        [{
          name: "alimonyChildSupport",
          value: declarations.alimonyChildSupport || false,
          key: "declarations-statements-alimonyChildSupport",
          label: "Are you obligated to pay alimony, child support, or separate maintenance?"
        }, {
          name: "downpaymentBorrowed",
          value: declarations.downpaymentBorrowed || false,
          key: "declarations-statements-downpaymentBorrowed",
          label: "Is any part of your downpayment borrowed?"
        }, {
          name: "coMakerEndorser",
          value: declarations.coMakerEndorser || false,
          key: "declarations-statements-coMakerEndorser",
          label: "Are you a co-maker of endorser on a note?"
        }, {
          name: "usCitizen",
          value: declarations.usCitizen || false,
          key: "declarations-statements-usCitizen",
          label: "Are you a U.S. citizen?"
        }, {
          name: "permanentResident",
          value: declarations.permanentResident || false,
          key: "declarations-statements-permanentResident",
          label: "Are you a permanent resident alien?"
        }, {
          name: "primaryResidence",
          value: declarations.primaryResidence || false,
          key: "declarations-statements-primaryResidence",
          label: "Do you intent to occupy the property as your primary residence?"
        }, {
          name: "ownershipInterestInPropertyLastThreeYears",
          value: declarations.ownershipInterestInPropertyLastThreeYears || false,
          key: "declarations-statements-ownershipInterestInPropertyLastThreeYears",
          label: "Have you had an ownership interest in a property in the last three years?"
        }]
      }
      submitHandler={_handleSubmit}
      />
    </CardComponent>
  );
};

export default DeclarationsStatementsSecond;
