// Customer Register Constants
export const [
  CONSUMER_REGISTER,
  CONSUMER_REGISTER_PENDING,
  CONSUMER_REGISTER_FULFILLED,
  CONSUMER_REGISTER_REJECTED
] = [
  "CONSUMER_REGISTER",
  "CONSUMER_REGISTER_PENDING",
  "CONSUMER_REGISTER_FULFILLED",
  "CONSUMER_REGISTER_REJECTED"
];
