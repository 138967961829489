import {
  AUTH_CHECK_USER_EXISTENCE,
  AUTH_SET_IS_AUTHENTICATED
} from "../../constants/auth";
import AuthUtils from "../../../util/auth";
import {_callApi} from "../../../util/fetch";

export function AUTH_SET_IS_AUTHENTICATED_ACTION(flag) {
  return {
    type: AUTH_SET_IS_AUTHENTICATED,
    payload: flag
  };
}

export function AUTH_CHECK_AUTH_ACTION() {
  return dispatch => {
    const status = AuthUtils._isAuthenticated();
    dispatch(AUTH_SET_IS_AUTHENTICATED_ACTION(status));
  };
}

export function AUTH_CHECK_USER_EXISTENCE_PROMISE(email) {
  return {
    type: AUTH_CHECK_USER_EXISTENCE,
    payload: _callApi(
      "identity",
      "/identity/exists?emailAddress=" + email,
      "GET",
      null
    )
  }
}
