import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {PERSONAL_CURRENT_ADDRESS_YEARS_AT_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const PersonalCurrentAddressYearsAt = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setCurrentAddressYearsAt = useCallback((value) => dispatch(PERSONAL_CURRENT_ADDRESS_YEARS_AT_ACTION(value)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const yearsAtAddressOptions = useSelector( state => state.wizardStore.meta.yearsAtAddressOptions);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const applicantStreet = applicant && applicant.currentAddress ? applicant.currentAddress.street || "" : "";

  const _clickHandler = (key, value) => {
    setCurrentAddressYearsAt(value);
    navigateNext("personal_mailing_address_question", false, applicantIndex);
  };

  return (
    <CardComponent title={`How many years has ${applicant.firstName} lived at ${applicantStreet}?`}>
      <OptionsComponent onClick={_clickHandler} options={yearsAtAddressOptions} />
    </CardComponent>
  );
};

export default PersonalCurrentAddressYearsAt;
