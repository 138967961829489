import React from "react";

const WithIntroLayout = (WrappedComponent) => {
  return (
    <div className="bg-teal-100 flex flex-col h-full leading-none m-0 min-h-screen">
      <div className="container flex flex-col flex-grow items-center justify-start md:justify-center md:p-0 md:-mt-32 mx-auto p-4">
        <WrappedComponent />
      </div>
    </div>
  );
};

export default WithIntroLayout;
