import {combineReducers} from "redux";
import authReducer from "./_auth_reducer";
import docsReducer from "./_docs_reducer";
import loanApplicationsReducer from "./_loan_applications_reducer";
import menuReducer from "./_menu_reducer";
import plansReducer from "./_plans_reducer";
import spinnerReducer from "./_spinner_reducer";
import wizardReducer from "./_wizard_reducer";

export default combineReducers({
  authStore: authReducer,
  docsStore: docsReducer,
  loanAppsStore: loanApplicationsReducer,
  plansStore: plansReducer,
  menuStore: menuReducer,
  spinnerStore: spinnerReducer,
  wizardStore: wizardReducer,
});
