import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {META_SET_CURRENT_EMPLOYMENT_INDEX_ACTION, SET_NEXT_PAGE, SET_SECTION_FLAG_ACTION} from "../../../redux/actions";
import {CardComponent} from "../../../components";

const EmploymentSummary = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const resetEmploymentIndex = useCallback(() => dispatch(META_SET_CURRENT_EMPLOYMENT_INDEX_ACTION(-1)), [dispatch]);
  const setSectionFlag = useCallback((section, flag) => dispatch(SET_SECTION_FLAG_ACTION(section, flag)), [dispatch]);
  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const totalApplicants = useSelector( state => state.wizardStore.meta.totalApplicants);
  const currentIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[currentIndex];
  const total = parseInt(totalApplicants.toString());

  const _continueHandler = () => {
    if (currentIndex < total-1) {
      resetEmploymentIndex();
      navigateNext("employment_question", false, currentIndex+1);
    } else {
      setSectionFlag("employment", true);
      navigateNext("debts_permission_question", false, 0);
    }
  };

  const _editHandler = (state) => {
    navigateNext(state, true, currentIndex);
  };

  return (
    <CardComponent title="Summary - Employment Information">
      <div className="flex flex-col items-start max-w-xl">
        {
          (applicant && applicant.contactInfo != null) &&
          <div className="flex group items-center justify-between mb-3 w-full">
              <span className="cp-font-r14w300g800 group-hover:text-teal-500">
                <b>{applicant.firstName}'s</b> Employment Information
              </span>
            <button className="cp-btn-review" onClick={() => _editHandler("employment_question")}>
              Edit
            </button>
          </div>
        }
      </div>
      <div className="flex flex-col items-center max-w-xl">
        <button className="cp-btn-primary" onClick={() => _continueHandler()}>
          Continue
        </button>
      </div>
    </CardComponent>
  )
};

export default EmploymentSummary;
