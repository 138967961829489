import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import CheckboxContainer from "../../../components/checkbox/_container";
import {DECLARATIONS_STATEMENTS_SAVE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent} from "../../../components";

const DeclarationsStatementsFirst = () => {
  const dispatch = useDispatch();
  const saveInformation = useCallback((data) => dispatch(DECLARATIONS_STATEMENTS_SAVE_ACTION(data)), [dispatch]);
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const declarations = applicant.declarations ? applicant.declarations : {
    "outstandingJudgements": false,
    "bankruptLastSevenYears": false,
    "propertyForeclosedLastSevenYears": false,
    "partyToLawsuit": false,
    "loanForeclosedLastSevenYears": false,
    "federalLoanDelinquent": false
  };

  const _handleSubmit = (response) => {
    saveInformation(response);
    navigateNext("declarations_statements_second", false, applicantIndex);
  };

  return (
    <CardComponent title={`${applicant.firstName}, select any and all of the following statements that are true.`}>
      <CheckboxContainer checkboxes={
        [{
          name: "outstandingJudgements",
          value: declarations.outstandingJudgements || false,
          key: "declarations-statements-outstandingJudgements",
          label: "Are there any outstanding judgements against you?"
        }, {
          name: "bankruptLastSevenYears",
          value: declarations.bankruptLastSevenYears || false,
          key: "declarations-statements-bankruptLastSevenYears",
          label: "Have you been declared bankrupt within the past 7 years?"
        }, {
          name: "propertyForeclosedLastSevenYears",
          value: declarations.propertyForeclosedLastSevenYears || false,
          key: "declarations-statements-propertyForeclosedLastSevenYears",
          label: "Have you had a property foreclosed upon or given title or deed in lieu thereof in the last 7 years?"
        }, {
          name: "partyToLawsuit",
          value: declarations.partyToLawsuit || false,
          key: "declarations-statements-partyToLawsuit",
          label: "Are you a party to a lawsuit?"
        }, {
          name: "loanForeclosedLastSevenYears",
          value: declarations.loanForeclosedLastSevenYears || false,
          key: "declarations-statements-loanForeclosedLastSevenYears",
          label: "Have you directly or indirectly been obligated on any loan which resulted in foreclosure, transfer of title in lieu of foreclosure, or judgement?"
        }, {
          name: "federalLoanDelinquent",
          value: declarations.federalLoanDelinquent || false,
          key: "declarations-statements-federalLoanDelinquent",
          label: "Are you presently delinquent or in default on any Federal debt or any other loan mortgage, financial obligation, bond, or loan guarantee?"
        }]
      }
      submitHandler={_handleSubmit}
      />
    </CardComponent>
  );
};

export default DeclarationsStatementsFirst;
