import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import Utils from "../../../util/format";
import {CardComponent} from "../../../components";
import {SET_NEXT_PAGE, SET_SECTION_FLAG_ACTION} from "../../../redux/actions";

const IntroSummary = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setSectionFlag = useCallback((section, flag) => dispatch(SET_SECTION_FLAG_ACTION(section, flag)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const loanAmount = useSelector( state => state.wizardStore.model.loanAmount);
  const loanPurpose = useSelector( state => state.wizardStore.model.loanPurpose);
  const loanTerm = useSelector( state => state.wizardStore.model.loanTerm);
  const numberOfStatedApplicants = useSelector( state => state.wizardStore.model.numberOfStatedApplicants);
  const residenceType = useSelector( state => state.wizardStore.model.residenceType);
  const borrower = applicants && applicants[0] != null ? applicants[0].firstName : "";
  const coBorrowers = applicants && numberOfStatedApplicants > 1 ? applicants.slice(1) : null;

  const _editApplicant = (index) => {
    navigateNext("intro_applicant_details", true, index);
  };

  const _editHandler = (state) => {
    navigateNext(state, true, 0);
  };

  const _startHandler = () => {
    setSectionFlag("intro", true);
    navigateNext("contact_details", false, 0);
  };

  return (
    <div className="flex flex-col items-center max-w-xl">
      <img
        alt="Intro Lend Review Illustration"
        className="h-24 max-w-md mb-4 md:mb-10 md:h-auto md:w-auto w-64"
        src={process.env.PUBLIC_URL + "/assets/illustrations/welcome_end.svg"}
      />
      <h1 className="cp-font-r36w500g800">Everything is set!</h1>
      <h1 className="cp-font-r36w500g800 mb-6 text-center">Time to fill out your loan application!</h1>
      <CardComponent title="Summary - Getting Started">
        <div className="flex group items-center justify-between mb-3">
          <span className="cp-font-r14w300g800 group-hover:text-teal-500">
            Type of Loan: <b>{loanPurpose}</b>
          </span>
          <button className="cp-btn-review" onClick={() => _editHandler("intro_loan_purpose")}>
            Edit
          </button>
        </div>
        <div className="flex group items-center justify-between mb-3">
          <span className="cp-font-r14w300g800 group-hover:text-teal-500">
            Amount: <b>${Utils.formatMoney(parseFloat(loanAmount.toString()), true)}</b>
          </span>
          <button className="cp-btn-review" onClick={() => _editHandler("intro_borrow_amount")}>
            Edit
          </button>
        </div>
        <div className="flex group items-center justify-between mb-3">
          <span className="cp-font-r14w300g800 group-hover:text-teal-500">
            Loan Term: <b>{loanTerm}</b>
          </span>
          <button className="cp-btn-review" onClick={() => _editHandler("intro_loan_term")}>
            Edit
          </button>
        </div>
        <div className="flex group items-center justify-between mb-3">
          <span className="cp-font-r14w300g800 group-hover:text-teal-500">
            Residence Type: <b>{residenceType}</b>
          </span>
          <button className="cp-btn-review" onClick={() => _editHandler("intro_occupy_intend")}>
            Edit
          </button>
        </div>
        <div className="flex group items-center justify-between mb-3">
          <span className="cp-font-r14w300g800 group-hover:text-teal-500">
            Number of Applicants: <b>{numberOfStatedApplicants}</b>
          </span>
          <button className="cp-btn-review" onClick={() => _editHandler("intro_nr_applicants")}>
            Edit
          </button>
        </div>
        <div className="flex group items-center justify-between mb-3" key={`review-applicant-details-0`}>
          <span className="cp-font-r14w300g800 group-hover:text-teal-500">
            Borrower: <b>{borrower}</b>
          </span>
          <button className="cp-btn-review" onClick={() => _editApplicant(0)}>
            Edit
          </button>
        </div>
        {
          coBorrowers && coBorrowers.map((borrower, index) =>
            <div className="flex group items-center justify-between mb-3" key={`review-applicant-details-${index+1}`}>
              <span className="cp-font-r14w300g800 group-hover:text-teal-500">
                Co Borrower: <b>{borrower.firstName}</b>
              </span>
              <button className="cp-btn-review" onClick={() => _editApplicant(index+1)}>
                Edit
              </button>
            </div>
          )
        }
      </CardComponent>
      <button className="cp-btn-primary" onClick={() => _startHandler()}>
        Start Application
      </button>
    </div>
  )
};

export default IntroSummary;
