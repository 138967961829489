import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {PERSONAL_MAILING_ADDRESS_YEARS_AT_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const PersonalMailingAddressYearsAt = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setMailingAddressYearsAt = useCallback((value) => dispatch(PERSONAL_MAILING_ADDRESS_YEARS_AT_ACTION(value)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const yearsAtAddressOptions = useSelector( state => state.wizardStore.meta.yearsAtAddressOptions);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const currentAddress = applicant.currentAddress;
  const applicantStreet = applicant && applicant.mailingAddress ? applicant.mailingAddress.street || "" : "";

  const _clickHandler = (key, value) => {
    setMailingAddressYearsAt(value);

    // we check if we are in edit mode
    if (inEditMode) {
      navigateNext("personal_summary", true, applicantIndex);
    } else if (currentAddress && currentAddress.years && parseInt(currentAddress.years) >= 2) {
      // we are not in edit mode so next we decide if we navigate to a former address state or to military status
      // we don't need a former address information in this case
      navigateNext("personal_military_status", false, applicantIndex);
    } else {
      // we are not in edit mode and we should navigate to a former address information
      navigateNext("personal_former_address_main", false, applicantIndex);
    }
  };

  return (
    <CardComponent title={`How many years has ${applicant.firstName} lived at ${applicantStreet}?`}>
      <OptionsComponent onClick={_clickHandler} options={yearsAtAddressOptions} />
    </CardComponent>
  );
};

export default PersonalMailingAddressYearsAt;
