import AuthUtils from "../../../util/auth";
import {AUTH_LOGOUT_PENDING, AUTH_LOGOUT_FULFILLED, AUTH_LOGOUT_REJECTED} from "../../constants/auth";

export function AUTH_LOGOUT_ACTION() {
  return dispatch => {
    dispatch(AUTH_LOGOUT_PENDING_ACTION());
    AuthUtils._clearAuthRelatedLocalStorage();
    dispatch(AUTH_LOGOUT_FULFILLED_ACTION());
  };
}

export function AUTH_LOGOUT_PENDING_ACTION() {
  return {
    type: AUTH_LOGOUT_PENDING
  };
}

export function AUTH_LOGOUT_FULFILLED_ACTION() {
  return {
    type: AUTH_LOGOUT_FULFILLED
  };
}

export function AUTH_LOGOUT_REJECTED_ACTION(error) {
  return {
    type: AUTH_LOGOUT_REJECTED,
    payload: error
  };
}
