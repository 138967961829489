import {SET_SPINNER_FLAG_ACTION} from "../spinner/_spinner_actions";
import {AUTH_SET_PASSWORD_PENDING, AUTH_SET_PASSWORD_FULFILLED, AUTH_SET_PASSWORD_REJECTED} from "../../constants/auth";
import {_callApi} from "../../../util/fetch";

export function AUTH_SET_PASSWORD_PENDING_ACTION() {
  return {
    type: AUTH_SET_PASSWORD_PENDING
  };
}

export function AUTH_SET_PASSWORD_FULFILLED_ACTION() {
  return {
    type: AUTH_SET_PASSWORD_FULFILLED
  };
}

export function AUTH_SET_PASSWORD_REJECTED_ACTION(error) {
  return {
    type: AUTH_SET_PASSWORD_REJECTED,
    payload: error
  };
}

export function AUTH_SET_PASSWORD_ACTION(userGuid, email, hash, password) {
  return dispatch => {
    dispatch(AUTH_SET_PASSWORD_PENDING_ACTION());
    dispatch(SET_SPINNER_FLAG_ACTION(true));
    _callApi(
      "identity",
      "/identity/secure",
      "POST",
      {
        userGuid,
        emailAddress: email.trim(),
        hash,
        password
      }
    ).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(AUTH_SET_PASSWORD_FULFILLED_ACTION());
        dispatch(SET_SPINNER_FLAG_ACTION(false));
      } else {
        dispatch(AUTH_SET_PASSWORD_REJECTED_ACTION(`Unknown error. Response status: ${response.status}`));
      }
    }).catch((error) => {
      dispatch(SET_SPINNER_FLAG_ACTION(false));
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.messages) {
        errorMessage = error.response.data.messages.join(" ");
      }
      dispatch(AUTH_SET_PASSWORD_REJECTED_ACTION(`Failed to set user credentials. (${errorMessage})`))
    })
  };
}
