import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {DOWN_PAYMENT_CLOSING_COSTS_SAVE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const DownPaymentClosingCosts = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveCosts = useCallback((value) => dispatch(DOWN_PAYMENT_CLOSING_COSTS_SAVE_ACTION(value)), [dispatch]);

  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const downPaymentClosingCosts = useSelector( state => state.wizardStore.meta.downPaymentClosingCosts);
  const applicantIndex = parseInt(currentApplicant.toString());

  const _clickHandler = (key, value) => {
    saveCosts(value);
    navigateNext("submit_loan", false, applicantIndex);
  };

  return (
    <CardComponent title="What would be your preferred strategy pertaining to closing costs?">
      <OptionsComponent onClick={_clickHandler} options={downPaymentClosingCosts} />
    </CardComponent>
  )
};

export default DownPaymentClosingCosts;
