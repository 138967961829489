import { modelApplicantsAlloc } from "./_model_applicants_alloc";
import { modelApplicantsPop } from "./_model_applicants_pop";
import { modelApplicantsPush } from "./_model_applicants_push";
import { modelSetLoanAmount } from "./_model_set_loan_amount";
import { modelSetLoanGuid } from "./_model_set_loan_guid";
import { modelSetLoanPurpose } from "./_model_set_loan_purpose";
import { modelSetLoanTerm } from "./_model_set_loan_term";
import { modelSetResidenceType } from "./_model_set_residence_type";
import { modelSetNumberOfStatedApplicants } from "./_model_number_of_applicants";

export default {
  modelApplicantsAlloc,
  modelApplicantsPop,
  modelApplicantsPush,
  modelSetLoanAmount,
  modelSetLoanGuid,
  modelSetLoanPurpose,
  modelSetLoanTerm,
  modelSetResidenceType,
  modelSetNumberOfStatedApplicants
}
