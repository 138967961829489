import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {HMDA_INFO_SAVE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const HMDAGenderInfo = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveHMDAInfo = useCallback((value) => dispatch(HMDA_INFO_SAVE_ACTION(value)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const hmdaGender = useSelector( state => state.wizardStore.meta.hmdaGender);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];

  const _clickHandler = (key, value) => {
    saveHMDAInfo({sex: value});
    navigateNext(inEditMode ? "hmda_summary" : "hmda_race", inEditMode, applicantIndex);
  };

  return (
    <CardComponent title={`What is ${applicant.firstName}'s gender?`}>
      <p className="cp-font-r18w400g500 mb-1 md:mb-4 text-justify">
        The following questions are not required but you may choose to share the information for statistical purposes.
      </p>
      <OptionsComponent onClick={_clickHandler} options={hmdaGender} />
    </CardComponent>
  );
};

export default HMDAGenderInfo;
