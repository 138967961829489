import jwtDecode from "jwt-decode";

export const [
  BASE_TOKEN_EXPIRATION_KEY,
  BASE_TOKEN_JWT_KEY,
  BASE_TOKEN_KEY,
  CURRENT_USER_KEY,
  ENRICHED_TOKEN_EXPIRATION_KEY,
  ENRICHED_TOKEN_JWT_KEY,
  ENRICHED_TOKEN_KEY
] = [
  "BASE_TOKEN_EXPIRATION_KEY",
  "BASE_TOKEN_JWT_KEY",
  "BASE_TOKEN_KEY",
  "CURRENT_USER_KEY",
  "ENRICHED_TOKEN_EXPIRATION_KEY",
  "ENRICHED_TOKEN_JWT_KEY",
  "ENRICHED_TOKEN_KEY"
];

const { localStorage } = window;

export default class AuthUtils {

  static _authenticatedUserDetails() {
    return JSON.parse(localStorage.getItem(CURRENT_USER_KEY)) || {}
  }

  static _authToken() {
    return localStorage.getItem(ENRICHED_TOKEN_KEY) || localStorage.getItem(BASE_TOKEN_KEY) || undefined;
  }

  static _checkAuthenticatedUsersEmailAddress(email) {
    return (AuthUtils._authenticatedUserDetails().emailAddress || "").toLowerCase() === (email || "").toLowerCase()
  }

  static _clearAuthRelatedLocalStorage() {
    localStorage.removeItem(BASE_TOKEN_KEY);
    localStorage.removeItem(BASE_TOKEN_EXPIRATION_KEY);
    localStorage.removeItem(BASE_TOKEN_JWT_KEY);

    localStorage.removeItem(CURRENT_USER_KEY);

    localStorage.removeItem(ENRICHED_TOKEN_KEY);
    localStorage.removeItem(ENRICHED_TOKEN_EXPIRATION_KEY);
    localStorage.removeItem(ENRICHED_TOKEN_JWT_KEY);
  }

  static _checkTokenExpirationPair(token, expiration) {
    if (token && expiration) {
      // Check auth token and related expiration time.
      const dateTime = (new Date()).getTime();
      const oneHoursInMillis = 60 * 60 * 1000;
      const tokenExpiration = new Date(expiration).getTime();

      // True - token is valid; False - token is invalid;
      return (tokenExpiration > dateTime + oneHoursInMillis);
    }
    return false;
  }

  static _isAuthenticated() {
    const authToken = AuthUtils._authToken();
    return authToken !== undefined && authToken !== null && authToken !== "";
  }

  static _storeAuthToken(token, expiration, isEnrichedToken = false) {
    const jwt = jwtDecode(token);
    localStorage.setItem(isEnrichedToken ? ENRICHED_TOKEN_KEY : BASE_TOKEN_KEY, token);
    localStorage.setItem(isEnrichedToken ? ENRICHED_TOKEN_EXPIRATION_KEY : BASE_TOKEN_EXPIRATION_KEY, expiration);
    localStorage.setItem(isEnrichedToken ? ENRICHED_TOKEN_JWT_KEY : BASE_TOKEN_JWT_KEY, JSON.stringify(jwt));
  }

  static _validateAuthToken(token, emailAddress) {
    const jwt = jwtDecode(token);
    return jwt.email.toUpperCase() === emailAddress.toUpperCase() && jwt.iss === "com.myavenu.identity" && jwt.aud === "avenu";
  }

}
