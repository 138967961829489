import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {EMPLOYMENT_MONTHLY_EARNINGS_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, CurrencyComponent, ErrorsComponent} from "../../../components";
import {YupCurrency} from "../../../util/yup";
import * as Yup from "yup";

Yup.addMethod(Yup.object, "atLeastOneIncome", function(list) {
  return this.test({
    name: "atLeastOneOf",
    message: "You should add at least one income value.",
    exclusive: true,
    params: { keys: list.join(", ") },
    test: value => value == null || list.some(f => value[f] != null)
  })
});

const validationSchema = Yup.object().shape({
  baseIncome: YupCurrency("Base Income"),
  overtime: YupCurrency("Overtime"),
  commissions: YupCurrency("Commissions"),
  dividendsInterest: YupCurrency("Dividends Interest"),
  netRentalIncome: YupCurrency("Net Rental Income"),
  otherIncome: YupCurrency("Other Income")
}).atLeastOneIncome(["baseIncome", "overtime", "commissions", "dividendsInterest", "netRentalIncome", "otherIncome"]);

const EmploymentMonthlyEarnings = () => {
  const dispatch = useDispatch();
  const saveEarnings = useCallback((data) => dispatch(EMPLOYMENT_MONTHLY_EARNINGS_ACTION(data)), [dispatch]);
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const applicant = applicants[currentApplicant];
  const {
    baseIncome,
    overtime,
    commissions,
    dividendsInterest,
    netRentalIncome,
    otherIncome
  } = applicant.employment && applicant.employment[0] && applicant.employment[0].income ? applicant.employment[0].income : {};

  return (
    <Formik
      initialValues={{baseIncome, overtime, commissions, dividendsInterest, netRentalIncome, otherIncome}}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        saveEarnings(values);
        resetForm({});
        setSubmitting(false);
        navigateNext(inEditMode ? "employment_summary" : "employment_multiple_employments_question", false, currentApplicant);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent title={`Please complete ${applicant.firstName}'s Monthly Earnings with ${applicant.employment[0].name}`}>
          <CurrencyComponent
            label="Base Income (monthly)"
            name="baseIncome"
            onChange={handleChange}
            value={values.baseIncome}
          />
          <CurrencyComponent
            label="Overtime (monthly)"
            name="overtime"
            onChange={handleChange}
            value={values.overtime}
          />
          <CurrencyComponent
            label="Commissions (monthly)"
            name="commissions"
            onChange={handleChange}
            value={values.commissions}
          />
          <CurrencyComponent
            label="Dividends / Interest (monthly)"
            name="dividendsInterest"
            onChange={handleChange}
            value={values.dividendsInterest}
          />
          <CurrencyComponent
            label="Net Rental Income (monthly)"
            name="netRentalIncome"
            onChange={handleChange}
            value={values.netRentalIncome}
          />
          <CurrencyComponent
            label="Other Income (monthly)"
            name="otherIncome"
            onChange={handleChange}
            value={values.otherIncome}
          />
          {
            errors && (touched.baseIncome || touched.overtime || touched.commissions || touched.dividendsInterest ||
              touched.netRentalIncome || touched.otherIncome) && <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  );
};

export default EmploymentMonthlyEarnings;
