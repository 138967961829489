// Menu Constants
export const [
  CLOSE_MENU,
  OPEN_MENU,
  SET_SECTION_FLAG
] = [
  "CLOSE_MENU",
  "OPEN_MENU",
  "SET_SECTION_FLAG"
];
