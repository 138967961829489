// Employment Information related Redux Constants
export const [
  EMPLOYMENT_QUESTION,
  EMPLOYMENT_MAIN,
  EMPLOYMENT_START_DATE,
  EMPLOYMENT_PERCENT_OWNERSHIP,
  EMPLOYMENT_AGI_AND_DEPRECIATION,
  EMPLOYMENT_MONTHLY_EARNINGS,
  EMPLOYMENT_MULTIPLE_EMPLOYMENTS_QUESTION,
  EMPLOYMENT_MISC_INCOME
] = [
  "EMPLOYMENT_QUESTION",
  "EMPLOYMENT_MAIN",
  "EMPLOYMENT_START_DATE",
  "EMPLOYMENT_PERCENT_OWNERSHIP",
  "EMPLOYMENT_AGI_AND_DEPRECIATION",
  "EMPLOYMENT_MONTHLY_EARNINGS",
  "EMPLOYMENT_MULTIPLE_EMPLOYMENTS_QUESTION",
  "EMPLOYMENT_MISC_INCOME"
];
