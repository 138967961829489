import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ checked = false, label, name, onChange, type = "checkbox"}) => (
  <div className="flex items-center justify-center mb-4 mt-4">
    <div className="cp-checkbox-container">
      <label className="cp-checkbox-label">
        <input checked={checked} name={name} onChange={onChange} type={type} />
        <span className="cp-checkbox-custom" />
      </label>
    </div>
    <div className="flex-grow justify-center cp-font-r14w300g800">
      {label}
    </div>
  </div>
);

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
