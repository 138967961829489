import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {PERSONAL_DETAILS_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {BirthDateComponent, CardComponent, ErrorsComponent, TextBoxComponent} from "../../../components";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  middleName: Yup.string(),
  lastName: Yup.string().required("Last name is required."),
  dateOfBirth: Yup
    .date()
    .max(new Date(), "You can't be born in the future.")
    .min(new Date(1900, 0, 1), "I don't believe you're so old.")
    .required("Birth date is required.")
});

const IntroApplicantDetails = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveData = useCallback((applicant) => dispatch(PERSONAL_DETAILS_ACTION(applicant)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const totalApplicants = useSelector( state => state.wizardStore.meta.totalApplicants);
  const cIndex = parseInt(currentApplicant.toString());
  const total = parseInt(totalApplicants.toString());
  const applicant = applicants[cIndex];
  const {firstName, middleName, lastName, dateOfBirth} = applicant ? applicant : {};

  let title = "";
  if (cIndex === 0) {
    title = "Your name and Date of Birth";
  } else {
    const prefix = cIndex === 1 ? "Co" : cIndex === 2 ? "Third" : cIndex === 3 ? "Fourth" : "Next";
    title = `Name and Date of Birth of the ${prefix} Applicant`;
  }

  return (
    <Formik
      initialValues={{ firstName, middleName, lastName, dateOfBirth }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        saveData(values);
        resetForm({});
        setSubmitting(false);
        if (inEditMode) {
          navigateNext("intro_summary");
        } else {
          if (cIndex < total - 1) {
            navigateNext("intro_applicant_details", false, cIndex + 1);
          } else {
            navigateNext("intro_summary");
          }
        }
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent hasProgress={true} title={title}>
          <TextBoxComponent
            label="First Name"
            name="firstName"
            onChange={handleChange}
            placeholder="David"
            value={values.firstName}
          />
          <TextBoxComponent
            label="Middle Name"
            name="middleName"
            onChange={handleChange}
            placeholder="Fitzgerald"
            value={values.middleName}
          />
          <TextBoxComponent
            label="Last Name"
            name="lastName"
            onChange={handleChange}
            placeholder="Kennedy"
            value={values.lastName}
          />
          <BirthDateComponent
            name="dateOfBirth"
            onChange={handleChange}
            value={values.dateOfBirth}
          />
          {
            errors && touched.firstName && touched.lastName && touched.dateOfBirth &&
            <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  );
};

export default IntroApplicantDetails;
