// Logout Auth Constants
export const [
  AUTH_LOGOUT,
  AUTH_LOGOUT_PENDING,
  AUTH_LOGOUT_FULFILLED,
  AUTH_LOGOUT_REJECTED
] = [
  "AUTH_LOGOUT",
  "AUTH_LOGOUT_PENDING",
  "AUTH_LOGOUT_FULFILLED",
  "AUTH_LOGOUT_REJECTED"
];
