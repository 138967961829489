import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {EMPLOYMENT_MULTIPLE_EMPLOYMENTS_QUESTION_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const EmploymentMultipleEmploymentsQuestion = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setAnswer = useCallback((flag) => dispatch(EMPLOYMENT_MULTIPLE_EMPLOYMENTS_QUESTION_ACTION(flag)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const currentEmploymentIndex = useSelector( state => state.wizardStore.meta.currentEmploymentIndex);
  const yesNoAnswerOptions = useSelector( state => state.wizardStore.meta.yesNoAnswerOptions);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];

  const _clickHandler = (key, value) => {
    if (value === true) {
      setAnswer(parseInt(currentEmploymentIndex.toString()) + 1);
      navigateNext("employment_main", false, applicantIndex);
    } else {
      navigateNext("employment_misc_income", false, applicantIndex);
    }
  };

  return (
    <CardComponent title={`Does ${applicant.firstName} have any other employment to report?`}>
      <OptionsComponent onClick={_clickHandler} options={yesNoAnswerOptions} />
    </CardComponent>
  );
};

export default EmploymentMultipleEmploymentsQuestion;
