import RUF from "../../../util/redux";

export function debtsPermissionQuestion(state, action) {
  const applicantsArray = state.model.applicants;
  const currentIndex = parseInt(state.meta.currentApplicant);

  return RUF.updateObject(state, {
    model: RUF.updateObject(state.model, {
      applicants: [
        ...applicantsArray.slice(0, currentIndex),
        Object.assign({}, applicantsArray[currentIndex], {
          isDebtPermissionGranted: action.payload
        }),
        ...applicantsArray.slice(currentIndex+1)
      ]
    })
  });
}
