// Documents Constants
export const [
  GET_LOANS,
  GET_LOANS_PENDING,
  GET_LOANS_FULFILLED,
  GET_LOANS_REJECTED,
  GET_LOAN_BIDS,
  GET_LOAN_BIDS_PENDING,
  GET_LOAN_BIDS_FULFILLED,
  GET_LOAN_BIDS_REJECTED,
  SELECT_LOAN_BID,
  SELECT_LOAN_BID_PENDING,
  SELECT_LOAN_BID_FULFILLED,
  SELECT_LOAN_BID_REJECTED,
  GET_LOAN_STIPULATIONS,
  GET_LOAN_STIPULATIONS_PENDING,
  GET_LOAN_STIPULATIONS_FULFILLED,
  GET_LOAN_STIPULATIONS_REJECTED,
  GET_USER_STIPULATIONS,
  GET_USER_STIPULATIONS_PENDING,
  GET_USER_STIPULATIONS_FULFILLED,
  GET_USER_STIPULATIONS_REJECTED,
  UPLOAD_STIPULATION,
  UPLOAD_STIPULATION_PENDING,
  UPLOAD_STIPULATION_FULFILLED,
  UPLOAD_STIPULATION_REJECTED,
  DELETE_STIPULATION,
  DELETE_STIPULATION_PENDING,
  DELETE_STIPULATION_FULFILLED,
  DELETE_STIPULATION_REJECTED,
  DOWNLOAD_STIPULATION,
  DOWNLOAD_STIPULATION_PENDING,
  DOWNLOAD_STIPULATION_FULFILLED,
  DOWNLOAD_STIPULATION_REJECTED,
  GET_FULL_APPLICATION,
  GET_FULL_APPLICATION_PENDING,
  GET_FULL_APPLICATION_FULFILLED,
  GET_FULL_APPLICATION_REJECTED,
  UPDATE_USER_EXISTS_IN_DB
] = [
  "GET_LOANS",
  "GET_LOANS_PENDING",
  "GET_LOANS_FULFILLED",
  "GET_LOANS_REJECTED",
  "GET_LOAN_BIDS",
  "GET_LOAN_BIDS_PENDING",
  "GET_LOAN_BIDS_FULFILLED",
  "GET_LOAN_BIDS_REJECTED",
  "SELECT_LOAN_BID",
  "SELECT_LOAN_BID_PENDING",
  "SELECT_LOAN_BID_FULFILLED",
  "SELECT_LOAN_BID_REJECTED",
  "GET_LOAN_STIPULATIONS",
  "GET_LOAN_STIPULATIONS_PENDING",
  "GET_LOAN_STIPULATIONS_FULFILLED",
  "GET_LOAN_STIPULATIONS_REJECTED",
  "GET_USER_STIPULATIONS",
  "GET_USER_STIPULATIONS_PENDING",
  "GET_USER_STIPULATIONS_FULFILLED",
  "GET_USER_STIPULATIONS_REJECTED",
  "UPLOAD_STIPULATION",
  "UPLOAD_STIPULATION_PENDING",
  "UPLOAD_STIPULATION_FULFILLED",
  "UPLOAD_STIPULATION_REJECTED",
  "DELETE_STIPULATION",
  "DELETE_STIPULATION_PENDING",
  "DELETE_STIPULATION_FULFILLED",
  "DELETE_STIPULATION_REJECTED",
  "DOWNLOAD_STIPULATION",
  "DOWNLOAD_STIPULATION_PENDING",
  "DOWNLOAD_STIPULATION_FULFILLED",
  "DOWNLOAD_STIPULATION_REJECTED"
];
