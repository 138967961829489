import {
  CONTACT_DETAILS,
  CONTACT_SUMMARY
} from "../../constants/loan/_contact_constants";

export const CONTACT_DETAILS_ACTION = (info) => ({
  type: CONTACT_DETAILS,
  payload: info
});

export const CONTACT_SUMMARY_ACTION = (value) => ({
  type: CONTACT_SUMMARY,
  payload: value
});
