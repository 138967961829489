import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {DEBTS_SET_SSN_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, ErrorsComponent, SSNComponent} from "../../../components";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  socialSecurityNumber: Yup
    .string()
    .max(9, "Social Security Number must be 9 digits.")
    .min(9, "Social Security Number must be 9 digits.")
    .required("Social Security Number is required.")
});

const DebtsSSNInfo = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveSSN = useCallback((ssn) => dispatch(DEBTS_SET_SSN_ACTION(ssn)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const {socialSecurityNumber} = applicant && applicant.socialSecurityNumber ? applicant.socialSecurityNumber : "";

  return (
    <Formik
      initialValues={{ socialSecurityNumber }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        saveSSN(values);
        resetForm({});
        setSubmitting(false);
        navigateNext(inEditMode ? "debts_summary" : "debts_other_obligations", false, currentApplicant);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent title={`What is ${applicant.firstName}'s Social Security Number?`}>
          <SSNComponent
            label="Social Security Number"
            name="socialSecurityNumber"
            onChange={handleChange}
            value={values.socialSecurityNumber}
          />
          {
            errors && touched.socialSecurityNumber && <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  );
};

export default DebtsSSNInfo;
