import React from "react";
import PropTypes from "prop-types";

const Radio = ({ checked = false, label, value, onChange, type = "radio"}) => (
  <div className="flex items-center justify-center mb-4 mt-4">
    <div className="cp-checkbox-container">
      <label className="cp-checkbox-label">
        <input checked={checked} onChange={onChange} type={type} value={value} />
        <span className="cp-checkbox-custom circular" />
      </label>
    </div>
    <div className="flex-grow justify-center cp-font-r14w300g800">
      {label}
    </div>
  </div>
);

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired
};

export default Radio;
