import React from "react";

const Pagination = (props) => {
  let indicators = [];

  for (let i=0; i<parseInt(props.total); i++) {
    indicators.push(
      <div className="w-6 h-10 text-center py-1" key={`intro-indicator-${i}`}>
        <p className={ i === parseInt(props.active)-1 ? "text-3xl text-teal-500" : "text-3xl text-gray-200" }>
          &bull;
        </p>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center w-full">
      { indicators }
    </div>
  )
};

export default Pagination;
