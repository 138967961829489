import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {CardComponent, ErrorsComponent, PasswordComponent} from "../../components";
import {AUTH_SET_PASSWORD_ACTION} from "../../redux/actions";
import {Link} from "react-router-dom";
import WithGradientLayout from "../../layouts/_withGradientLayout";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Your password must be at least 6 characters.")
    .max(1000, "Your password is too long.")
    .required("You must set a password for your account."),
  confirmPassword: Yup.string()
    .when("password", {
      is: val => (!!(val && val.length > 0)),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Your confirm password should match."
      )
    })
    .required("You must confirm your password.")
});

const SetPasswordView = () => {
  const dispatch = useDispatch();
  const setPassword = useCallback((guid, email, hash, password) => dispatch(AUTH_SET_PASSWORD_ACTION(guid, email, hash, password)), [dispatch]);

  const hasErrors = useSelector( state => state.authStore.setPass.hasErrors);
  const inProgress = useSelector( state => state.authStore.setPass.inProgress);
  const isCompleted = useSelector( state => state.authStore.setPass.isCompleted);
  const passError = useSelector( state => state.authStore.setPass.errors);
  const linkAuthData = useSelector( state => state.authStore.linkAuth);
  const { email, hash, userID } = linkAuthData;

  const _getSetPasswordForm = () => (
    <div className="flex flex-col">
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setPassword(userID, email, hash, values.password);
          resetForm({});
          setSubmitting(false);
        }}
        render={({values, errors, touched, handleChange, handleSubmit }) => (
          <CardComponent title={`Set password for ${email}`}>
            <PasswordComponent
              label="Password:"
              name="password"
              onChange={handleChange}
              placeholder="Type your password"
              value={values.password}
            />
            <PasswordComponent
              label="Confirm password:"
              name="confirmPassword"
              onChange={handleChange}
              placeholder="Confirm your password"
              value={values.confirmPassword}
            />
            {
              errors && touched.password && touched.confirmPassword &&
              <ErrorsComponent errorsObj={errors} />
            }
            {
              hasErrors && <div className="flex flex-col cp-error">{passError}</div>
            }
            <button className={`cp-auth-btn ${inProgress ? "is-loading" : ""}`} onClick={handleSubmit} type="submit">
              Set password
            </button>
          </CardComponent>
        )}
        validationSchema={validationSchema}
      />
      <div className="flex flex-row">
        <span className="mr-2">Already have an account?</span>
        <Link className="cp-link-teal" to="/">Login</Link>
      </div>
    </div>
  );

  const isRedirectFlagOK = userID && email;
  const isCompleteFlagOK = isCompleted && !hasErrors;
  const msg1 = isRedirectFlagOK ? (isCompleteFlagOK ? "Congrats! Your password was set!" : "Please create a password for your account!") : "Something is wrong!";
  const msg2 = isRedirectFlagOK ? (isCompleteFlagOK ? "Login with your new password to start!" : "Activate your account by setting your password!") : "You should try to login.";

  return (
    <div className="container flex flex-col h-full items-start md:px-8 min-h-screen mx-auto px-4">
      <img
        alt="Intro Lend Logo"
        className="flex-none mb-16 mt-6"
        src={process.env.PUBLIC_URL + "/assets/logo/logo.svg"}
      />
      <div className="flex flex-col flex-grow md:flex-row h-full w-full">
        <div className="flex flex-col flex-grow md:w-1/2 md:pb-0 pb-16">
          <div className="cp-page-title">
            {msg1}
          </div>
          <div className="cp-page-subtitle">
            {
              isRedirectFlagOK && <>You are a few steps away from receiving multiple loan proposals, all personalized for you! <br /></>
            }
            {msg2}
          </div>
          {
            isRedirectFlagOK && !isCompleteFlagOK ? _getSetPasswordForm() : <Link className="block cp-auth-btn text-center w-1/2" to="/">Go to Login</Link>
          }
        </div>
        <div className="flex flex-col flex-grow justify-end md:mx-4 md:w-1/2">
          <img
            alt="Customer Portal Illustration"
            src={process.env.PUBLIC_URL + "/assets/illustrations/selector.svg"}
          />
        </div>
      </div>
    </div>
  );
};

export default WithGradientLayout(SetPasswordView);
