// Loan Applications Constants
export const [
  FETCH_LOAN_APPLICATIONS,
  FETCH_LOAN_APPLICATIONS_PENDING,
  FETCH_LOAN_APPLICATIONS_FULFILLED,
  FETCH_LOAN_APPLICATIONS_REJECTED
] = [
  "FETCH_LOAN_APPLICATIONS",
  "FETCH_LOAN_APPLICATIONS_PENDING",
  "FETCH_LOAN_APPLICATIONS_FULFILLED",
  "FETCH_LOAN_APPLICATIONS_REJECTED"
];
