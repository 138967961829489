import RUF from "../../../util/redux";

export function personalCurrentAddressMonthlyExpenses(state, action) {
  const applicantsArray = state.model.applicants;
  const currentIndex = parseInt(state.meta.currentApplicant);

  return RUF.updateObject(state, {
    model: RUF.updateObject(state.model, {
      applicants: [
        ...applicantsArray.slice(0, currentIndex),
        RUF.updateObject(applicantsArray[currentIndex], {
          currentAddress: RUF.updateObject(applicantsArray[currentIndex].currentAddress, {
            monthlyCost: action.payload
          })
        }),
        ...applicantsArray.slice(currentIndex+1)
      ]
    })
  });
}
