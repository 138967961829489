import RUF from "../../../util/redux";

export function employmentMultipleEmployments(state, action) {
  const applicantsArray = state.model.applicants;
  const currentApplicantIndex = parseInt(state.meta.currentApplicant);
  const currentEmploymentIndex = parseInt(state.meta.currentEmploymentIndex);
  const employmentCount = currentApplicantIndex ? applicantsArray[currentApplicantIndex].statedEmploymentCount : 0;
  const statedEmploymentCount = employmentCount ? parseInt(employmentCount) : 0;

  return RUF.updateObject(state, {
    meta: RUF.updateObject(state.meta, {
      currentEmploymentIndex: action.payload ? currentEmploymentIndex + 1 : currentEmploymentIndex
    }),
    model: RUF.updateObject(state.model, {
      applicants: [
        ...applicantsArray.slice(0, currentApplicantIndex),
        Object.assign({}, applicantsArray[currentApplicantIndex], {
          statedEmploymentCount: action.payload ? statedEmploymentCount + 1 : 0
        }),
        ...applicantsArray.slice(currentApplicantIndex+1)
      ]
    })
  });
}
