import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {MODEL_SET_LOAN_PURPOSE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const IntroLoanPurpose = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveLoanPurpose = useCallback((value) => dispatch(MODEL_SET_LOAN_PURPOSE_ACTION(value)), [dispatch]);

  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const loanPurposeOptions = useSelector( state => state.wizardStore.meta.loanPurposeOptions);

  const _submit = (key, value) => {
    saveLoanPurpose(value);
    navigateNext(inEditMode ? "intro_summary" : "intro_borrow_amount");
  };

  return (
    <CardComponent hasProgress={true} title="Are you buying a new home or refinancing your existing home?">
      <OptionsComponent onClick={_submit} options={loanPurposeOptions} />
    </CardComponent>
  )
};

export default IntroLoanPurpose;
