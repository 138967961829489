import RUF from "../../util/redux";
import {SET_LOAN_PLANS_DATA} from "../constants/_plans_constants";

const initialState = {
  evaluationSession: {
    hasApprovals: false,
    loanEvaluations: []
  }
};

export default function plansReducer(state = initialState, action) {
  switch(action.type) {
    case SET_LOAN_PLANS_DATA: {
      return RUF.updateObject(state, {
        applicationState: action.payload.applicationState || null,
        loanApplicationID: action.payload.loanApplicationID || null,
        evaluationSession: RUF.updateObject(state.evaluationSession, action.payload.evaluationSession || [])
      });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
