import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {DOWN_PAYMENT_AMOUNT_SAVE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, CurrencyComponent, ErrorsComponent} from "../../../components";
import Utils from "../../../util/format";
import * as Yup from "yup";

const DownPaymentAmount = () => {
  const dispatch = useDispatch();
  const saveDownPaymentAmount = useCallback((amount) => dispatch(DOWN_PAYMENT_AMOUNT_SAVE_ACTION(amount)), [dispatch]);
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const downPaymentModel = useSelector( state => state.wizardStore.model.downPayment);
  const downPaymentAmount = downPaymentModel && downPaymentModel.amount ? downPaymentModel.amount : null;
  const loanAmount = useSelector( state => state.wizardStore.model.loanAmount);
  const amountVal = Utils.formatMoney(parseInt(loanAmount.toString()), true);

  return (
    <Formik
      initialValues={{ amount: downPaymentAmount }}
      onSubmit={(values, { setSubmitting }) => {
        saveDownPaymentAmount(parseInt(values.amount, 10));
        navigateNext("down_payment_gifted_question", false, currentApplicant);
        setSubmitting(false);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent title="How much cash do you have for a Down Payment?">
          <CurrencyComponent
            label="Cash for Down Payment"
            name="amount"
            onChange={handleChange}
            value={values.amount}
          />
          {
            errors.amount && touched.amount && <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={() => {
        return Yup.object().shape({
          amount: Yup.number()
            .positive("Down payment must be a positive number.")
            .max(loanAmount, `Down payment should be less than loan amount: $${amountVal}.`)
            .required("Down payment is required in order to continue.")
            .typeError("Down payment must be a number, only digits, no comma or $.")
        });
      }}
    />
  );
};

export default DownPaymentAmount;
