// Personal Information related Redux Constants
export const [
  PERSONAL_DETAILS,
  PERSONAL_CURRENT_ADDRESS_LESS_THAN_TWO,
  PERSONAL_CURRENT_ADDRESS_MAIN,
  PERSONAL_CURRENT_ADDRESS_OWNERSHIP,
  PERSONAL_CURRENT_ADDRESS_MONTHLY_EXPENSES,
  PERSONAL_CURRENT_ADDRESS_YEARS_AT,
  PERSONAL_MAILING_ADDRESS_DIFFERENT,
  PERSONAL_MAILING_ADDRESS_MAIN,
  PERSONAL_MAILING_ADDRESS_YEARS_AT,
  PERSONAL_FORMER_ADDRESS_MAIN,
  PERSONAL_FORMER_ADDRESS_OWNERSHIP,
  PERSONAL_FORMER_ADDRESS_MONTHLY_EXPENSES,
  PERSONAL_FORMER_ADDRESS_YEARS_AT,
  PERSONAL_MILITARY_STATUS,
  PERSONAL_SUMMARY
] = [
  "PERSONAL_DETAILS",
  "PERSONAL_CURRENT_ADDRESS_LESS_THAN_TWO",
  "PERSONAL_CURRENT_ADDRESS_MAIN",
  "PERSONAL_CURRENT_ADDRESS_OWNERSHIP",
  "PERSONAL_CURRENT_ADDRESS_MONTHLY_EXPENSES",
  "PERSONAL_CURRENT_ADDRESS_YEARS_AT",
  "PERSONAL_MAILING_ADDRESS_DIFFERENT",
  "PERSONAL_MAILING_ADDRESS_MAIN",
  "PERSONAL_MAILING_ADDRESS_YEARS_AT",
  "PERSONAL_FORMER_ADDRESS_MAIN",
  "PERSONAL_FORMER_ADDRESS_OWNERSHIP",
  "PERSONAL_FORMER_ADDRESS_MONTHLY_EXPENSES",
  "PERSONAL_FORMER_ADDRESS_YEARS_AT",
  "PERSONAL_MILITARY_STATUS",
  "PERSONAL_SUMMARY"
];
