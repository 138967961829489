import RUF from "../../../util/redux";

export function metaSetCurrentState(state, action) {
  const previous = state.meta.currentState;

  return RUF.updateObject(state, {
    meta: RUF.updateObject(state.meta, {
      currentState: action.payload,
      previousState: previous
    })
  });
}
