import * as Yup from "yup";

// Currency - Number, Nullable, Positive, Type, Schema
export const YupCurrency = (message) => {
  return Yup
  .number()
  .nullable()
  .positive(`${message} must be a positive number.`)
  .typeError(`${message} must be a number.`);
};

// Monthly Cost validation schema
export const YupMonthlyCost = Yup.object().shape({
  monthlyCost: YupCurrency("Monthly Cost")
});
