// Model Store Redux Constants
export const [
  MODEL_APPLICANTS_ALLOC,
  MODEL_APPLICANTS_POP,
  MODEL_APPLICANTS_PUSH,
  MODEL_SET_LOAN_AMOUNT,
  MODEL_SET_LOAN_GUID,
  MODEL_SET_LOAN_PURPOSE,
  MODEL_SET_LOAN_TERM,
  MODEL_SET_RESIDENCE_TYPE,
  MODEL_SET_NUMBER_OF_STATED_APPLICANTS
] = [
  "MODEL_APPLICANTS_ALLOC",
  "MODEL_APPLICANTS_POP",
  "MODEL_APPLICANTS_PUSH",
  "MODEL_SET_LOAN_AMOUNT",
  "MODEL_SET_LOAN_GUID",
  "MODEL_SET_LOAN_PURPOSE",
  "MODEL_SET_LOAN_TERM",
  "MODEL_SET_RESIDENCE_TYPE",
  "MODEL_SET_NUMBER_OF_STATED_APPLICANTS"
];
