import React from "react";

const ErrorsComponent = ({errorsObj}) =>
  <div className="font-sans font-normal mt-4 text-red-500">
    {
      Object.keys(errorsObj).map((fieldName, i) => {
        return errorsObj[fieldName].length > 0 ?
          <p className="my-1" key={`error-${i + 1}`}> { errorsObj[fieldName] } </p>
        :
          null;
      })
    }
  </div>;

export default ErrorsComponent;
