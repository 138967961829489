import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import RadioGroup from "../../../components/radio/_group";
import {CardComponent} from "../../../components";
import {DEBTS_PERMISSION_QUESTION_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const DebtsPermissionQuestion = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveAnswer = useCallback((answer) => dispatch(DEBTS_PERMISSION_QUESTION_ACTION(answer)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];

  const _handleSubmit = (response) => {
    saveAnswer(response === "true");
    navigateNext("debts_permission_feedback");
  };

  return (
    <CardComponent title={`${applicant.firstName}, next we need to analyze your monthly debt and other obligations.`}>
      <p className="cp-font-r18w400g500 mb-1 md:mb-4 text-justify">
        To do so we need your authorization to access your credit report.
      </p>
      <p className="cp-font-r18w400g500 mb-1 md:mb-4 text-justify">
        Do you grant us permission to run a soft-credit check for the purposes of extending you credit?
      </p>
      <RadioGroup
        defaultValue="true"
        options={
          [{
            key: "debts-permission-granted-radio",
            label: "Therefore, I hereby authorize IntroLend to verify my past and present employment earnings records, bank accounts, stock holdings and any other asset balances that are needed to process my mortgage loan application. I further authorize IntroLend to order a consumer credit report and verify other credit information, including past and present mortgage and landlord references. It is understood that a copy of this form will also serve as authorization. The information IntroLend obtains is only to be used in the processing of my application for a mortgage loan.",
            value: "true"
          }, {
            key: "debts-permission-not-granted-radio",
            label: "No",
            value: "false"
          }]
        }
        submitHandler={_handleSubmit}
      />
    </CardComponent>
  );
};

export default DebtsPermissionQuestion;
