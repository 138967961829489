import {Config} from "../components";
import AuthUtils from "./auth";
import axios from "axios";
import uuid4 from "uuid4";

export function _callApi(baseUrlKey, url, method, data) {
  const baseUrl = Config.baseURL[baseUrlKey || ""];
  const baseToken = AuthUtils._authToken() || "";

  let header = {
    "Accept": "application/json",
    "Content-Type": "application/json"
  };

  header = baseToken ? Object.assign(header, {"Authorization": "Bearer " + baseToken}) : header;

  header = (!method || method === "POST" || method === "PUT") ? Object.assign(header, {
    "x-requestid": uuid4(),
  }) : header;

  return axios({
    method: method || "POST",
    url: baseUrl + url,
    data: data,
    headers: header
  });
}
