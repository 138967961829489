import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useSpring, animated} from "react-spring";
import {BackButton, NextButton} from "../button";
import {withRouter} from "react-router";

const ProgressBar = (props) => {
  const [data, setData] = useState({percentage: 10, title: ""});
  const currentState = useSelector( state => state.wizardStore.meta.currentState);
  const _handleBack = () => props.history.goBack();
  const _handleNext = () => props.history.goForward();
  const _updateData = (percentage, title) => setData({percentage, title});

  useEffect(() => {
    switch(currentState) {
      case (currentState.match(/^intro_welcome/) || {}).input: _updateData(10, "Getting Started"); break;
      case (currentState.match(/^intro_loan_purpose/) || {}).input: _updateData(25, "Getting Started"); break;
      case (currentState.match(/^intro_borrow_amount/) || {}).input: _updateData(50, "Getting Started"); break;
      case (currentState.match(/^intro_loan_term/) || {}).input: _updateData(60, "Getting Started"); break;
      case (currentState.match(/^intro_occupy_intend/) || {}).input: _updateData(70, "Getting Started"); break;
      case (currentState.match(/^intro_nr_applicants/) || {}).input: _updateData(80, "Getting Started"); break;
      case (currentState.match(/^intro_applicant_details/) || {}).input: _updateData(90, "Getting Started"); break;
      case (currentState.match(/^intro_summary/) || {}).input: _updateData(100, "Getting Started"); break;

      case (currentState.match(/^contact_details/) || {}).input: _updateData(45, "Contact Information"); break;
      case (currentState.match(/^contact_summary/) || {}).input: _updateData(100, "Contact Information"); break;

      case (currentState.match(/^personal_current_address_main/) || {}).input: _updateData(14, "Personal Information"); break;
      case (currentState.match(/^personal_current_address_ownership/) || {}).input: _updateData(14, "Personal Information"); break;
      case (currentState.match(/^personal_current_address_monthly_expenses/) || {}).input: _updateData(28, "Personal Information"); break;
      case (currentState.match(/^personal_current_address_years_at/) || {}).input: _updateData(35, "Personal Information"); break;
      case (currentState.match(/^personal_mailing_address_question/) || {}).input: _updateData(42, "Personal Information"); break;
      case (currentState.match(/^personal_mailing_address_main/) || {}).input: _updateData(49, "Personal Information"); break;
      case (currentState.match(/^personal_mailing_address_years_at/) || {}).input: _updateData(56, "Personal Information"); break;
      case (currentState.match(/^personal_former_address_main/) || {}).input: _updateData(63, "Personal Information"); break;
      case (currentState.match(/^personal_former_address_ownership/) || {}).input: _updateData(70, "Personal Information"); break;
      case (currentState.match(/^personal_former_address_monthly_expenses/) || {}).input: _updateData(77, "Personal Information"); break;
      case (currentState.match(/^personal_former_address_years_at/) || {}).input: _updateData(84, "Personal Information"); break;
      case (currentState.match(/^personal_military_status/) || {}).input: _updateData(91, "Personal Information"); break;
      case (currentState.match(/^personal_summary/) || {}).input: _updateData(100, "Personal Information"); break;

      case (currentState.match(/^employment_question/) || {}).input: _updateData(17, "Employment / Income Information"); break;
      case (currentState.match(/^employment_main/) || {}).input: _updateData(22, "Employment / Income Information"); break;
      case (currentState.match(/^employment_start_date/) || {}).input: _updateData(33, "Employment / Income Information"); break;
      case (currentState.match(/^employment_percent_ownership/) || {}).input: _updateData(44, "Employment / Income Information"); break;
      case (currentState.match(/^employment_agi_and_depreciation/) || {}).input: _updateData(55, "Employment / Income Information"); break;
      case (currentState.match(/^employment_monthly_earnings/) || {}).input: _updateData(66, "Employment / Income Information"); break;
      case (currentState.match(/^employment_multiple_employments_question/) || {}).input: _updateData(77, "Employment / Income Information"); break;
      case (currentState.match(/^employment_misc_income/) || {}).input: _updateData(88, "Employment / Income Information"); break;
      case (currentState.match(/^employment_summary/) || {}).input: _updateData(100, "Employment / Income Information"); break;

      case (currentState.match(/^debts_permission_question/) || {}).input: _updateData(30, "Debts Information"); break;
      case (currentState.match(/^debts_permission_feedback/) || {}).input: _updateData(40, "Debts Information"); break;
      case (currentState.match(/^debts_ssn_info/) || {}).input: _updateData(50, "Debts Information"); break;
      case (currentState.match(/^debts_other_obligations/) || {}).input: _updateData(75, "Debts Information"); break;
      case (currentState.match(/^debts_summary/) || {}).input: _updateData(100, "Debts Information"); break;

      case (currentState.match(/^declarations_statements_first/) || {}).input: _updateData(45, "Declarations"); break;
      case (currentState.match(/^declarations_statements_second/) || {}).input: _updateData(66, "Declarations"); break;
      case (currentState.match(/^declarations_summary/) || {}).input: _updateData(100, "Declarations"); break;

      case (currentState.match(/^hmda_ethnicity/) || {}).input: _updateData(45, "HMDA"); break;
      case (currentState.match(/^hmda_gender/) || {}).input: _updateData(66, "HMDA"); break;
      case (currentState.match(/^hmda_race/) || {}).input: _updateData(80, "HMDA"); break;
      case (currentState.match(/^hmda_summary/) || {}).input: _updateData(100, "HMDA"); break;

      case (currentState.match(/^down_payment_selection/) || {}).input: _updateData(35, "Down Payment"); break;
      case (currentState.match(/^down_payment_amount/) || {}).input: _updateData(50, "Down Payment"); break;
      case (currentState.match(/^down_payment_gifted_question/) || {}).input: _updateData(60, "Down Payment"); break;
      case (currentState.match(/^down_payment_assisted_question/) || {}).input: _updateData(70, "Down Payment"); break;
      case (currentState.match(/^down_payment_stated_bank_count/) || {}).input: _updateData(80, "Down Payment"); break;
      case (currentState.match(/^down_payment_bank_accounts/) || {}).input: _updateData(90, "Down Payment"); break;
      case (currentState.match(/^down_payment_closing_costs/) || {}).input: _updateData(100, "Down Payment"); break;

      case (currentState.match(/^submit_loan/) || {}).input: _updateData(100, "Submit"); break;

      default: _updateData(0, "");
    }
  }, [currentState]);

  const percentage = useSpring({ width: `${data.percentage}%`, from: { width: "0%" } });

  return (
    <div className="flex flex-col items-center justify-center max-w-md mb-4 md:max-w-lg mt-8 w-full">
      <div className="flex items-center justify-between mb-2 overflow-hidden w-full">
        <BackButton onClick={_handleBack} />
        <label className="cp-font-r24w500g900snug text-center">{data.title}</label>
        <NextButton onClick={_handleNext} />
      </div>
      <div className="mb-2 overflow-hidden w-full">
        <div className="cp-progress-bar">
          <animated.div style={percentage} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProgressBar);
