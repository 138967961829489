import AuthUtils, {CURRENT_USER_KEY} from "../../../util/auth";
import {AUTH_LOGIN_PENDING, AUTH_LOGIN_FULFILLED, AUTH_LOGIN_REJECTED,} from "../../constants/auth";
import {GET_LOANS_ACTION} from "../docs/_docs_actions";
import {MODEL_SET_LOAN_GUID_ACTION} from "..";
import {_callApi} from "../../../util/fetch";

export function AUTH_LOGIN_PENDING_ACTION() {
  return {
    type: AUTH_LOGIN_PENDING
  };
}

export function AUTH_LOGIN_FULFILLED_ACTION() {
  return {
    type: AUTH_LOGIN_FULFILLED
  };
}

export function AUTH_LOGIN_REJECTED_ACTION(errors) {
  AuthUtils._clearAuthRelatedLocalStorage();
  return {
    type: AUTH_LOGIN_REJECTED,
    payload: errors
  };
}

export function AUTH_LOGIN_ACTION(emailAddress, password) {
  return dispatch => {
    if (!AuthUtils._isAuthenticated()) {
      dispatch(AUTH_LOGIN_PENDING_ACTION());

      _callApi(
        "identity",
        "/identity/token",
        "POST",
        {
          emailAddress: emailAddress.trim(),
          password
        }
      ).then(response => {
        if (response.status === 200 && response.data) {
          if (AuthUtils._validateAuthToken(response.data.token, emailAddress, response.data.expiration)) {
            // Store the valid token
            AuthUtils._storeAuthToken(response.data.token, response.data.expiration, false);
            // Get current user information
            dispatch(GET_CURRENT_USER_ACTION(emailAddress));
          } else {
            dispatch(AUTH_LOGIN_REJECTED_ACTION("Token not recognized."));
          }
        } else {
          dispatch(AUTH_LOGIN_REJECTED_ACTION(`Unknown error. Response status: ${response.status}`));
        }
      }).catch((error) => {
        let errorMessage = error.message;
        if (error.response && error.response.data && error.response.data.messages) {
          errorMessage = error.response.data.messages.join(" ");
        }
        dispatch(AUTH_LOGIN_REJECTED_ACTION(`Login failed! Please check your credentials and try again. (${errorMessage})`));
      });
    } else {
      dispatch(AUTH_LOGIN_REJECTED_ACTION("User already authenticated. Please log out before logging in again."));
    }
  };
}

export function GET_CURRENT_USER_ACTION(email) {
  return dispatch => {
    _callApi(
      "organisations",
      "/users/me",
      "GET",
      null
    ).then(res => {
      if (res.status === 200 && res.data && res.data.associations && res.data.associations.length > 0) {
        // Enrich token only for consumers. Otherwise the user is not valid and cannot login.
        const validAssociations = res.data.associations.filter(a => a.role && a.role.name === "Consumer");

        if (validAssociations && validAssociations.length > 0) {
          // Store current user information.
          localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(res.data));

          // Enrich token for first available valid association (usually it"s only one).
          dispatch(TOKEN_ENRICH_ACTION(email, validAssociations[0].guid));
        } else {
          dispatch(AUTH_LOGIN_REJECTED_ACTION("Current user is not a consumer."));
        }
      } else {
        dispatch(AUTH_LOGIN_REJECTED_ACTION("Current user has no association."));
      }
    }).catch((error) => {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.messages) {
        errorMessage = error.response.data.messages.join(" ");
      }
      dispatch(AUTH_LOGIN_REJECTED_ACTION(`Current user not retrieved. (${errorMessage})`));
    });
  };
}

export function TOKEN_ENRICH_ACTION(email, associationID) {
  return dispatch => {
    _callApi(
      "organisations",
      "/admin/jwt/enrich",
      "PUT",
      {
        encodedTokenString: AuthUtils._authToken(),
        associationID,
      }
    ).then(response => {
      if (response.status === 200 && response.data) {
        if (AuthUtils._validateAuthToken(response.data.token, email, response.data.expiration)) {
          // Store the valid token
          AuthUtils._storeAuthToken(response.data.token, response.data.expiration, true);
          // User successfully logged in
          dispatch(AUTH_LOGIN_FULFILLED_ACTION());

          // Fetch user loans and decide where to redirect
          dispatch(AUTH_DECIDE_REDIRECT_ACTION());
        } else {
          dispatch(AUTH_LOGIN_REJECTED_ACTION("Enriched token not recognized."));
        }
      } else {
        dispatch(AUTH_LOGIN_REJECTED_ACTION(`Unknown error. Response status: ${response.status}`));
      }
    }).catch((error) => {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.messages) {
        errorMessage = error.response.data.messages.join(" ");
      }
      dispatch(AUTH_LOGIN_REJECTED_ACTION(`Token enrich failed! (${errorMessage})`));
    });
  };
}

export function AUTH_DECIDE_REDIRECT_ACTION() {
  return dispatch => {
    const promise = dispatch(GET_LOANS_ACTION());

    promise.then(result => {
      const response = result.action && result.action.payload ? result.action.payload : null;
      if (response && response.data) {
        const loans = response.data;
        const firstApp = loans && loans.length > 0 && loans[0] ? loans[0] : null;
        if (firstApp && firstApp.guid) {
          dispatch(MODEL_SET_LOAN_GUID_ACTION(firstApp.guid));
          const status = firstApp.applicationStateName;

          switch (status) {
            case "Initialized":
            case "Started":
            // case "Started": window.appHistory.push("/intro_welcome"); break;
            case "ReferredToFinance": window.appHistory.push("/referred-to-finance"); break;
            case "PreApproved": window.appHistory.push("/loan-plans");break;
            default: window.appHistory.push("/docs"); break;
          }
        } else {
          dispatch(AUTH_LOGIN_REJECTED_ACTION("Could not load your loan application."));
        }
      } else {
        dispatch(AUTH_LOGIN_REJECTED_ACTION("Could not load your loan application."));
      }
    });
  };
}
