import RUF from "../../../util/redux";

export function modelApplicantsPush(state) {
  const numberOfStatedApplicants = parseInt(state.model.numberOfStatedApplicants);
  const count = numberOfStatedApplicants - state.model.applicants.length;
  const incrementNumber = count > 0 ? count : 0;

  return RUF.updateObject(state, {
    model: RUF.updateObject(state.model, {
      applicants: state.model.applicants.push(...new Array(incrementNumber).fill({}))
    })
  });
}
