import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router";
import {withToastManager} from "react-toast-notifications";
import {CLOSE_MENU_ACTION} from "../../redux/actions";

const MenuComponent = ({ children, hasChild, history, location, menuLink, path, title, toastManager }) => {
  const dispatch = useDispatch();
  const _getNavLinkClass = (path) => location.pathname.startsWith(path);
  const [isOpen, setOpenFlag] = useState( path ? _getNavLinkClass(path) : false);
  const _toggleStatus = () => setOpenFlag( hasChild ? !isOpen : false);
  const statusIcon = isOpen ? "M6 9l6 6 6-6" : "M9 18l6-6-6-6";

  const hmdaDone = useSelector( state => state.menuStore.sections.hmda);

  const isMenuOpen = useSelector( state => state.menuStore.isMenuOpen);
  const closeMenu = useCallback(() => dispatch(CLOSE_MENU_ACTION()), [dispatch]);

  const _handleMenuClick = (route) => {
    if (hmdaDone && menuLink === "down_payment_selection") {
      if (isMenuOpen) {
        closeMenu();
      }
      if (history) {
        history.push(route)
      }
    } else {
      toastManager.add(`You should complete HMDA section first.`, {
        appearance: "warning",
        autoDismiss: true,
        pauseOnHover: true
      });
    }
  };

  return (
    <React.Fragment>
      {
        hasChild ?
          <div className="cp-menu-item">
            <div className={`cp-menu-header ${isOpen ? "pb-2" : ""}`} onClick={_toggleStatus}>
              <span className={`cp-menu-header-title ${isOpen ? "text-teal-500" : ""}`}>
                {title}
              </span>
              <svg
                className="cp-menu-header-status"
                fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d={statusIcon} />
              </svg>
            </div>
            {
              isOpen && children
            }
          </div>
          :
          <div className="cp-menu-item group">
            <div className="cp-menu-header" onClick={() => _handleMenuClick(`/${menuLink}/false/0`)}>
              <span className={`cp-menu-header-title ${isOpen ? "text-teal-500" : ""}`}>{title}</span>
            </div>
          </div>
      }
    </React.Fragment>
  );
};

export default withToastManager(withRouter(MenuComponent));
