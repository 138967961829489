import { employmentMain } from "./_employment_main";
import { employmentMiscIncome } from "./_employment_misc_income";
import { employmentMonthlyEarnings } from "./_employment_monthly_earnings";
import { employmentMultipleEmployments } from "./_employment_multiple_employments_question";
import { employmentPercentOwnership } from "./_employment_percent_ownership";
import { employmentQuestion } from "./_employment_question";
import { employmentStartDate } from "./_employment_start_date";

export default {
  employmentMain,
  employmentMiscIncome,
  employmentMonthlyEarnings,
  employmentMultipleEmployments,
  employmentPercentOwnership,
  employmentQuestion,
  employmentStartDate
}
