import React from "react";
import CurrencyInput from "../currency";

const CurrencyComponent = ({ keyId, label, name, onChange, placeholder, precision, value }) => {

  const _handleChange = (event, maskedValue, floatValue) => {
    onChange({
      target: {
        name: event.target.name,
        value: floatValue
      }
    });
  };

  return (
    <div className="flex flex-col border-b border-teal-500 py-2 mb-2 md:mb-4">
      <label className="block cp-font-r14w300g800 mb-1" htmlFor={keyId || `key-${label}`}>
        {label}
      </label>
      <CurrencyInput
        className="cp-input"
        id={keyId || `key-${label}`}
        key={keyId || `key-${label}`}
        name={name}
        onChangeEvent={_handleChange}
        placeholder={placeholder || ""}
        precision={precision || 0}
        prefix="$"
        value={value}
      />
    </div>
  );
};

export default CurrencyComponent;
