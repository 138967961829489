import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {EMPLOYMENT_MAIN_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, ErrorsComponent, PhoneComponent, TextBoxComponent} from "../../../components";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup
    .string()
    .required("Employer name is required."),
  phoneNumber: Yup
    .string()
    .min(10, "Employer phone number is invalid."),
  position: Yup
    .string()
    .min(3, "Position title it too short.")
    .required("Your position title is required.")
});

const EmploymentMain = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveData = useCallback((data) => dispatch(EMPLOYMENT_MAIN_ACTION(data)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const currentEmploymentIndex = useSelector( state => state.wizardStore.meta.currentEmploymentIndex);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const {
    name,
    phoneNumber,
    position
  } = applicant && applicant.employment && applicant.employment[currentEmploymentIndex] ?
    applicant.employment[currentEmploymentIndex] : {};

  return (
    <Formik
      initialValues={{ name, phoneNumber, position }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        saveData(values);
        resetForm({});
        setSubmitting(false);
        navigateNext(inEditMode ? "employment_summary" : "employment_start_date", false, applicantIndex);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent title={`Tell us a little about ${applicant.firstName}'s Employer`}>
          <TextBoxComponent
            label="Name of Employer / Company"
            name="name"
            onChange={handleChange}
            placeholder="Name"
            value={values.name}
          />
          <PhoneComponent
            label="Phone Number of Employer / Company"
            name="phoneNumber"
            onChange={handleChange}
            placeholder="Phone"
            value={values.phoneNumber}
          />
          <TextBoxComponent
            label={`${applicant.firstName}'s Job Title`}
            name="position"
            onChange={handleChange}
            placeholder="Title"
            value={values.position}
          />
          {
            errors && touched.name && touched.phoneNumber && touched.position &&
            <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  );
};

export default EmploymentMain;
