import React from "react";

const OptionsComponent = ({ onClick, options }) => {
  return (
    <div className="flex flex-col">
      {
        typeof options !== "undefined" && options !== null && Object.keys(options).map(key => (
          <button
            className="cp-btn-options"
            key={`button${key}`}
            onClick={() => onClick(key, options[key])}
            type="button"
          >
            { key }
          </button>
        ))
      }
    </div>
  );
};

export default OptionsComponent;
