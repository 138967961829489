import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {PERSONAL_FORMER_ADDRESS_OWNERSHIP_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const PersonalFormerAddressOwnership = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setFormerAddressOwnership = useCallback((value) => dispatch(PERSONAL_FORMER_ADDRESS_OWNERSHIP_ACTION(value)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const addressOwnershipOptions = useSelector( state => state.wizardStore.meta.addressOwnershipOptions);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const applicantStreet = applicant && applicant.formerAddress ? applicant.formerAddress.street || "" : "";

  const _clickHandler = (key, value) => {
    setFormerAddressOwnership(value);
    navigateNext("personal_former_address_monthly_expenses", false, applicantIndex);
  };

  return (
    <CardComponent title={`Does ${applicant.firstName} Own or Rent the property at ${applicantStreet}?`}>
      <OptionsComponent  onClick={_clickHandler} options={addressOwnershipOptions} />
    </CardComponent>
  );
};

export default PersonalFormerAddressOwnership;
