import {
  DOWN_PAYMENT_AMOUNT_SAVE,
  DOWN_PAYMENT_ASSISTED_QUESTION,
  DOWN_PAYMENT_BANK_ACCOUNTS,
  DOWN_PAYMENT_CLOSING_COSTS,
  DOWN_PAYMENT_GIFTED_QUESTION,
  DOWN_PAYMENT_SELECTION_SAVE,
  DOWN_PAYMENT_STATED_BANK_COUNT
} from "../../constants/loan/_downpayment_constants";

export const DOWN_PAYMENT_AMOUNT_SAVE_ACTION = (value) => ({
  type: DOWN_PAYMENT_AMOUNT_SAVE,
  payload: value
});

export const DOWN_PAYMENT_ASSISTED_QUESTION_ACTION = (value) => ({
  type: DOWN_PAYMENT_ASSISTED_QUESTION,
  payload: value
});

export const DOWN_PAYMENT_BANK_ACCOUNTS_ACTION = (array) => ({
  type: DOWN_PAYMENT_BANK_ACCOUNTS,
  payload: array
});

export const DOWN_PAYMENT_CLOSING_COSTS_SAVE_ACTION = (value) => ({
  type: DOWN_PAYMENT_CLOSING_COSTS,
  payload: value
});

export const DOWN_PAYMENT_GIFTED_QUESTION_ACTION = (value) => ({
  type: DOWN_PAYMENT_GIFTED_QUESTION,
  payload: value
});


export const DOWN_PAYMENT_SELECTION_SAVE_ACTION = (value) => ({
  type: DOWN_PAYMENT_SELECTION_SAVE,
  payload: value
});

export const DOWN_PAYMENT_STATED_BANK_COUNT_SAVE_ACTION = (value) => ({
  type: DOWN_PAYMENT_STATED_BANK_COUNT,
  payload: value
});

