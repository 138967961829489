import React from "react";

const ButtonComponent = ({onClick, text}) => {
  return (
    <button className="cp-btn-teal" onClick={onClick} type="button">
      { text }
    </button>
  );
};

export default ButtonComponent;
