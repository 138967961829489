// Down Payment Information related Redux Constants
export const [
  DOWN_PAYMENT_AMOUNT_SAVE,
  DOWN_PAYMENT_ASSISTED_QUESTION,
  DOWN_PAYMENT_BANK_ACCOUNTS,
  DOWN_PAYMENT_CLOSING_COSTS,
  DOWN_PAYMENT_GIFTED_QUESTION,
  DOWN_PAYMENT_SELECTION_SAVE,
  DOWN_PAYMENT_STATED_BANK_COUNT
] = [
  "DOWN_PAYMENT_AMOUNT_SAVE",
  "DOWN_PAYMENT_ASSISTED_QUESTION",
  "DOWN_PAYMENT_BANK_ACCOUNTS",
  "DOWN_PAYMENT_CLOSING_COSTS",
  "DOWN_PAYMENT_GIFTED_QUESTION",
  "DOWN_PAYMENT_SELECTION_SAVE",
  "DOWN_PAYMENT_STATED_BANK_COUNT"
];
