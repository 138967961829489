// Set Password Constants
export const [
  AUTH_SET_PASSWORD,
  AUTH_SET_PASSWORD_PENDING,
  AUTH_SET_PASSWORD_FULFILLED,
  AUTH_SET_PASSWORD_REJECTED
] = [
  "AUTH_SET_PASSWORD",
  "AUTH_SET_PASSWORD_PENDING",
  "AUTH_SET_PASSWORD_FULFILLED",
  "AUTH_SET_PASSWORD_REJECTED"
];
