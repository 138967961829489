import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {withToastManager} from "react-toast-notifications";
import {CLOSE_MENU_ACTION} from "../../redux/actions";

const ApplicantsMenuOptions = ({ location, history, menuLink, toastManager }) => {
  const dispatch = useDispatch();
  const path = menuLink.split("_")[0];
  const isActive = location.pathname.startsWith("/" + path);
  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const aIndex = parseInt(currentApplicant.toString());

  const introDone = useSelector( state => state.menuStore.sections.intro);
  const contactDone = useSelector( state => state.menuStore.sections.contact);
  const personalDone = useSelector( state => state.menuStore.sections.personal);
  const employmentDone = useSelector( state => state.menuStore.sections.employment);
  const debtsDone = useSelector( state => state.menuStore.sections.debts);
  const declarationsDone = useSelector( state => state.menuStore.sections.declarations);
  const hmdaDone = useSelector( state => state.menuStore.sections.hmda);
  const downPaymentDone = useSelector( state => state.menuStore.sections.down_payment);

  const isMenuOpen = useSelector( state => state.menuStore.isMenuOpen);
  const closeMenu = useCallback(() => dispatch(CLOSE_MENU_ACTION()), [dispatch]);

  const _showToast = (section) => {
    toastManager.add(`You should complete ${section} section first.`, {
      appearance: "warning",
      autoDismiss: true,
      pauseOnHover: true
    });
  };

  const _navToRoute = (route) => {
    if (isMenuOpen) {
      closeMenu();
    }
    if (history) {
      history.push(route)
    }
  };

  const _handleMenuClick = (route) => {
    switch (path) {
      case "contact": introDone ? _navToRoute(route) : _showToast("intro"); break;
      case "personal": contactDone ? _navToRoute(route) : _showToast("contact"); break;
      case "employment": personalDone ? _navToRoute(route) : _showToast("personal"); break;
      case "debts": employmentDone ? _navToRoute(route) : _showToast("employment"); break;
      case "declarations": debtsDone ? _navToRoute(route) : _showToast("debts"); break;
      case "hmda": declarationsDone ? _navToRoute(route) : _showToast("declarations"); break;
      case "down_payment": hmdaDone ? _navToRoute(route) : _showToast("hmda"); break;
      default: break;
    }
  };

  return (
    <React.Fragment>
      {
        applicants && applicants.map((item, index) =>
          <div
            className="cp-menu-applicant-item group"
            key={`applicant-${index}`}
            onClick={() => _handleMenuClick(`/${menuLink}/false/${index}`)}
          >
            <span className={`cp-menu-applicant-item-status group-hover:text-teal-500 ${isActive && aIndex === index ? "text-teal-500" : ""}`}>
              &bull;
            </span>
            <span className={`cp-menu-applicant-item-title group-hover:text-teal-500 ${isActive && aIndex === index ? "text-teal-500" : ""}`}>
              {item.firstName}&nbsp;{item.lastName}
            </span>
          </div>
        )
      }
    </React.Fragment>
  )
};

export default withToastManager(withRouter(ApplicantsMenuOptions));
