import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {PERSONAL_FORMER_ADDRESS_YEARS_AT_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const PersonalFormerAddressYearsAt = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setFormerAddressYearsAt = useCallback((value) => dispatch(PERSONAL_FORMER_ADDRESS_YEARS_AT_ACTION(value)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const yearsAtAddressOptions = useSelector( state => state.wizardStore.meta.yearsAtAddressOptions);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const applicantStreet = applicant && applicant.formerAddress ? applicant.formerAddress.street || "" : "";

  const _clickHandler = (key, value) => {
    setFormerAddressYearsAt(value);
    navigateNext(inEditMode ? "personal_summary" : "personal_military_status", inEditMode, applicantIndex);
  };

  return (
    <CardComponent title={`How many years has ${applicant.firstName} lived at ${applicantStreet}?`}>
      <OptionsComponent onClick={_clickHandler} options={yearsAtAddressOptions} />
    </CardComponent>
  );
};

export default PersonalFormerAddressYearsAt;
