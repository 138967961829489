import {
  DEBTS_OTHER_OBLIGATIONS,
  DEBTS_PERMISSION_QUESTION,
  DEBTS_SET_SSN
} from "../../constants/loan/_debts_constants";

export const DEBTS_OTHER_OBLIGATIONS_ACTION = (value) => ({
  type: DEBTS_OTHER_OBLIGATIONS,
  payload: value
});

export const DEBTS_PERMISSION_QUESTION_ACTION = (flag) => ({
  type: DEBTS_PERMISSION_QUESTION,
  payload: flag
});

export const DEBTS_SET_SSN_ACTION = (value) => ({
  type: DEBTS_SET_SSN,
  payload: value
});
