import React from "react";
import {Redirect, Route} from "react-router";
import {ENRICHED_TOKEN_KEY} from "../util/auth";

const PrivateRoute = ({ render: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={
        () => localStorage.getItem(ENRICHED_TOKEN_KEY) ? <Component /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
