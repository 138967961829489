import RUF from "../../../util/redux";

export function employmentQuestion(state, action) {
  const currentApplicantIndex = parseInt(state.meta.currentApplicant);
  const currentEmploymentIndex = parseInt(state.meta.currentEmploymentIndex);

  return RUF.updateObject(state, {
    meta: RUF.updateObject(state.meta, {
      currentEmploymentIndex: action.payload ? currentEmploymentIndex + 1 : -1
    }),
    model: RUF.updateObject(state.model, {
      applicants: [
        ...state.model.applicants.slice(0, currentApplicantIndex),
        Object.assign({}, state.model.applicants[currentApplicantIndex], {
          statedEmploymentCount: action.payload ? 1 : 0,
          employment: [{
            isCurrent: true
          }]
        }),
        ...state.model.applicants.slice(currentApplicantIndex+1)
      ]
    })
  });
}
