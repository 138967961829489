import RUF from "../../../util/redux";

export function modelSetNumberOfStatedApplicants(state, action) {
  return RUF.updateObject(state, {
    meta: RUF.updateObject(state.meta, {
      totalApplicants: parseInt(action.payload)
    }),
    model: RUF.updateObject(state.model, {
      numberOfStatedApplicants: parseInt(action.payload)
    })
  });
}
