import React, {useCallback, useEffect, useState} from "react";
import AuthUtils from "../../util/auth";
import {useDispatch} from "react-redux";
import {AUTH_CHECK_USER_EXISTENCE_PROMISE, AUTH_SET_LINK_AUTH_OBJECT_ACTION, AUTH_LOGOUT_ACTION} from "../../redux/actions";
import {Redirect} from "react-router-dom";
import {Spinner} from "../../components";
import queryString from "query-string";

const LinkAuth = () => {
  const dispatch = useDispatch();
  const checkUserExistence = useCallback((email) => dispatch(AUTH_CHECK_USER_EXISTENCE_PROMISE(encodeURIComponent(email))), [dispatch]);
  const logout = useCallback(() => dispatch(AUTH_LOGOUT_ACTION()), [dispatch]);
  const setLinkAuthData = useCallback((obj) => dispatch(AUTH_SET_LINK_AUTH_OBJECT_ACTION(obj)), [dispatch]);
  const [loading, setLoading] = useState( true);
  const [redirectPath, setRedirectPath] = useState( "/");

  const _updateState = (path, status) => {
    setRedirectPath(path);
    setLoading(status);
  };

  useEffect(() => {
    const {applicationID, destination, email, hash, userID} = queryString.parse(window.location.search);
    if (applicationID && destination && email && hash && userID ) {
      setLinkAuthData({hasPerformed: true, applicationID, destination, email, hash, userID});
      switch (destination.toLowerCase()) {
        case "welcome":
        case "bids":
        default:
          checkUserExistence(email).then((response) => {
            if (response.value.status === 200 && response.value.data) {
              const userExists = response.value.data.exists;
              if (userExists === false) {
                // force any other user (if logged in) to logout and complete setting up
                // credentials for the user specified in the email link
                logout();
                _updateState("/set-password", false);
              } else {
                // make sure that we are dealing with the same user if a user is already logged in
                if (AuthUtils._checkAuthenticatedUsersEmailAddress(email) === false) {
                  // this link is for a different user than the one that is logged in
                  logout();
                  _updateState("/", false); // force the user to login
                } else {
                  _updateState("/", false);
                }
              }
            }
          })
          .catch((error) => {
            _updateState("/", false);
            console.log(error);
          });
          break;
      }
    } else {
      // invalid link, just redirect to login
      _updateState("/", false);
    }
  }, [checkUserExistence, logout, setLinkAuthData]);

  return (
    <React.Fragment>
      {
        // handle the actual redirection once we are done
        loading ? <Spinner /> : <Redirect to={{pathname: redirectPath}} />
      }
    </React.Fragment>
  )
};

export default LinkAuth;
