import RUF from "../../../util/redux";

export function downPaymentAssistedQuestionSave(state, action) {
  return RUF.updateObject(state, {
    model: RUF.updateObject(state.model, {
      downPayment: RUF.updateObject(state.model.downPayment, {
        isAssisted: action.payload
      })
    })
  });
}
