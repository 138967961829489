import React, {Component} from "react";

class LoansItemComponent extends Component {
  render() {
    const { bidID, hasSelectedWinningBid, company, email, img, name, phone, loanName, loanDetails, apr, discountPoints, totalFees, cashForClosing, loanAmount } = this.props;
    const isCardVisible = typeof this.props.isCardVisible !== "undefined" ? this.props.isCardVisible : true;
    const cardStyle = isCardVisible ? "bg-white shadow-lg" : "bg-gray-100 shadow-md";
    const cardPadding = isCardVisible ? "p-4" : "py-4 px-2 md:px-4";
    const selectButtonStyle = hasSelectedWinningBid ?
    "bg-teal-300 flex-none h-10 text-white text-sm px-4 rounded-full focus:outline-none cursor-default" :
    "bg-teal-500 flex-none h-10 hover:bg-teal-600 text-white text-sm px-4 rounded-full focus:outline-none";
    return (
      <div className={`flex flex-col md:flex-row mb-4 rounded-lg overflow-hidden w-full ${cardStyle}`}>
        <div className={`border-b-2 border-gray-200 flex flex-none flex-row md:border-b-0 md:border-r-2 md:max-w-sm w-full ${cardPadding}`}>
          <img
            alt=""
            className="h-16 hidden md:block w-16 md:h-24 md:w-24 mr-6 rounded-full"
            src={img} />
          <div className="flex-grow text-left">
            <h2 className="font-normal text-gray-800 text-lg">{name}</h2>
            <div className="inline-block mb-4 relative text-sm text-teal-500">{company}</div>
            <div className="text-gray-600 text-xs">{email}</div>
            <div className="text-gray-600 text-xs">{phone}</div>
          </div>
          <div className="flex flex-col flex-none ml-8 items-center">
            <img
              alt=""
              className="block h-16 mt-3 md:hidden md:h-24 md:w-24 rounded-full w-16"
              src={img} />
          </div>
        </div>
        {isCardVisible ? (
          <div className={`flex flex-grow flex-col w-full ${cardPadding}`}>
            <div className="flex flex-row">
              <div className="flex-grow">
                <h2 className="font-normal text-gray-800 text-lg">{loanName}</h2>
                <div className="inline-block mb-4 relative text-sm text-teal-500">{loanDetails}</div>
              </div>
              <button
                disabled={hasSelectedWinningBid}
                className={selectButtonStyle}
                onClick={() => {
                  if (!hasSelectedWinningBid) {
                    this.props.selectBid(bidID)
                  }
                }}
              >{hasSelectedWinningBid ? "Selected" : "Select"}
              </button>
            </div>
            <div className="flex flex-row">
              <div className="flex-1">
                <div className="text-gray-500 text-xs">Interest Rate:</div>
                <div className="text-gray-800 text-xs">{apr}%</div>
              </div>
              <div className="flex-1">
                <div className="text-gray-500 text-xs">Total fees:</div>
                <div className="text-gray-800 text-xs">{totalFees}</div>
              </div>
              <div className="flex-1">
                <div className="text-gray-500 text-xs">Cash for closing:</div>
                <div className="text-gray-800 text-xs">{cashForClosing}</div>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex-1">
                <div className="text-gray-500 text-xs">Discount Points:</div>
                <div className="text-gray-800 text-xs">{discountPoints}%</div>
              </div>
              <div className="flex-1">
                <div className="text-gray-500 text-xs">Loan Amount:</div>
                <div className="text-gray-800 text-xs">Up to {loanAmount}</div>
              </div>
              <div className="flex-1">
                <div className="text-gray-500 text-xs">&nbsp;</div>
                <div className="text-gray-800 text-xs">&nbsp;</div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`flex flex-grow flex-col w-full items-center justify-center ${cardPadding}`}>
          <div className="flex flex-row">
            <div className="flex-grow">
              <h1 className="font-normal text-gray-800 text-lg text-gray-400">Waiting on Lender's proposal</h1>
            </div>
          </div>
        </div>
        )}
      </div>
    );
  }
}

export default LoansItemComponent;
