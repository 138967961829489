import RUF from "../../util/redux";
import {
  FETCH_LOAN_APPLICATIONS_PENDING,
  FETCH_LOAN_APPLICATIONS_FULFILLED,
  FETCH_LOAN_APPLICATIONS_REJECTED
} from "../constants/_loan_applications_constants";

const initialState = {
  loanApplications: {
    data: null,
    isCompleted: false,
    inProgress: false,
    hasErrors: false,
    errors: null
  }
};

const _makeFlowObj = (data, isCompleted, inProgress, hasErrors, errors) => {
  return {data, isCompleted, inProgress, hasErrors, errors}
};

export default function loanApplicationsReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_LOAN_APPLICATIONS_PENDING: {
      return RUF.updateObject(state, {
        loanApplications: RUF.updateObject(state.loanApplications,
          _makeFlowObj(null,false, true, false, null)
        )
      });
    }
    case FETCH_LOAN_APPLICATIONS_FULFILLED: {
      return RUF.updateObject(state, {
        loanApplications: RUF.updateObject(state.loanApplications,
          _makeFlowObj(action.payload.data,false, true, false, null)
        )
      });
    }
    case FETCH_LOAN_APPLICATIONS_REJECTED: {
      return RUF.updateObject(state, {
        loanApplications: RUF.updateObject(state.loanApplications,
          _makeFlowObj(null, true, false, true, action.payload)
        )
      });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
