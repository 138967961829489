import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {EMPLOYMENT_MISC_INCOME_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, CurrencyComponent, ErrorsComponent} from "../../../components";
import {YupCurrency} from "../../../util/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  militaryAllowances: YupCurrency("Military Allowances"),
  publicAssistancePayments: YupCurrency("Public Assistance Payments"),
  alimony: YupCurrency("Alimony / Child Support"),
  childSupport: YupCurrency("Alimony / Child Support"),
  disabilityWorkersComp: YupCurrency("Disability / Workers Comp"),
  permanentDisability: YupCurrency("Permanent Disability"),
  trustIncome: YupCurrency("Trust Income"),
  pensionIncome: YupCurrency("Pension Income"),
  socialSecurityIncome: YupCurrency("Social Security Income"),
  interestDividendIncome: YupCurrency("Interest / Dividend Income"),
  noteIncome: YupCurrency("Note Income")
});

const EmploymentMiscIncome = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveIncome = useCallback((data) => dispatch(EMPLOYMENT_MISC_INCOME_ACTION(data)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const applicant = applicants[currentApplicant];
  const {
    militaryAllowances,
    publicAssistancePayments,
    alimony,
    childSupport,
    disabilityWorkersComp,
    permanentDisability,
    trustIncome,
    pensionIncome,
    socialSecurityIncome,
    interestDividendIncome,
    noteIncome
  } = applicant.employment && applicant.employment.otherIncome ? applicant.employment.otherIncome : {};

  return (
    <Formik
      initialValues={{
        militaryAllowances,
        publicAssistancePayments,
        alimony,
        childSupport,
        disabilityWorkersComp,
        permanentDisability,
        trustIncome,
        pensionIncome,
        socialSecurityIncome,
        interestDividendIncome,
        noteIncome
      }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        saveIncome(values);
        resetForm({});
        setSubmitting(false);
        navigateNext("employment_summary", false, currentApplicant);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent title={`Does ${applicant.firstName} have any other misc income to include?`}>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col md:mr-6">
              <CurrencyComponent
                label="Military Allowances"
                name="militaryAllowances"
                onChange={handleChange}
                value={values.militaryAllowances}
              />
              <CurrencyComponent
                label="Public Assistance Payments"
                name="publicAssistancePayments"
                onChange={handleChange}
                value={values.publicAssistancePayments}
              />
              <CurrencyComponent
                label="Alimony / Child Support"
                name="alimony"
                onChange={handleChange}
                value={values.alimony}
              />
              <CurrencyComponent
                label="Disability / Workers Comp"
                name="disabilityWorkersComp"
                onChange={handleChange}
                value={values.disabilityWorkersComp}
              />
              <CurrencyComponent
                label="Permanent Disability"
                name="permanentDisability"
                onChange={handleChange}
                value={values.permanentDisability}
              />
            </div>
            <div className="flex flex-col">
              <CurrencyComponent
                label="Trust Income"
                name="trustIncome"
                onChange={handleChange}
                value={values.trustIncome}
              />
              <CurrencyComponent
                label="Social Security Income"
                name="socialSecurityIncome"
                onChange={handleChange}
                value={values.socialSecurityIncome}
              />
              <CurrencyComponent
                label="Interest / Dividend Income"
                name="interestDividendIncome"
                onChange={handleChange}
                value={values.interestDividendIncome}
              />
              <CurrencyComponent
                label="Note Income"
                name="noteIncome"
                onChange={handleChange}
                value={values.noteIncome}
              />
            </div>
          </div>
          {
            errors && (touched.militaryAllowances || touched.publicAssistancePayments || touched.alimony ||
              touched.disabilityWorkersComp || touched.permanentDisability || touched.trustIncome ||
              touched.socialSecurityIncome || touched.interestDividendIncome || touched.noteIncome)
            && <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  );
};

export default EmploymentMiscIncome;
