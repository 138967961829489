// Meta Store Redux Constants
export const [
  META_SET_CURRENT_APPLICANT,
  META_SET_CURRENT_EMPLOYMENT_INDEX,
  META_SET_CURRENT_STATE,
  META_SET_EDIT_MODE,
  META_SET_PREVIOUS_STATE,
  META_SET_TOTAL_APPLICANTS
] = [
  "META_SET_CURRENT_APPLICANT",
  "META_SET_CURRENT_EMPLOYMENT_INDEX",
  "META_SET_CURRENT_STATE",
  "META_SET_EDIT_MODE",
  "META_SET_PREVIOUS_STATE",
  "META_SET_TOTAL_APPLICANTS"
];
