import AuthUtils from "../../../util/auth";
import {AUTH_LOGIN_REJECTED_ACTION, GET_CURRENT_USER_ACTION} from "./_login_actions";
import {_callApi} from "../../../util/fetch";
import {
  CONSUMER_REGISTER_PENDING,
  CONSUMER_REGISTER_FULFILLED,
  CONSUMER_REGISTER_REJECTED
} from "../../constants/auth";

export function CONSUMER_REGISTER_PENDING_ACTION() {
  return {
    type: CONSUMER_REGISTER_PENDING
  };
}

export function CONSUMER_REGISTER_FULFILLED_ACTION() {
  return {
    type: CONSUMER_REGISTER_FULFILLED
  };
}

export function CONSUMER_REGISTER_REJECTED_ACTION(errors) {
  return {
    type: CONSUMER_REGISTER_REJECTED,
    payload: errors
  };
}

export function CONSUMER_REGISTER_ACTION(firstName, lastName, emailAddress, phoneNumber = undefined) {
  emailAddress = emailAddress.trim();
  phoneNumber = phoneNumber ? phoneNumber.trim().replace(/\D/g, "") : "";
  return dispatch => {
    dispatch(CONSUMER_REGISTER_PENDING_ACTION());

    _callApi(
      "organisations",
      "/Users/consumer",
      "POST",
      { firstName, lastName, emailAddress, phoneNumber }
    ).then((response) => {
      if (response.status === 200 && response.data) {
        if (AuthUtils._validateAuthToken(response.data.token, emailAddress, response.data.expiration)) {
          // Store the valid token
          AuthUtils._storeAuthToken(response.data.token, response.data.expiration, false);
          dispatch(CONSUMER_REGISTER_FULFILLED_ACTION(response));
          // Get current user information
          dispatch(GET_CURRENT_USER_ACTION(emailAddress));
        } else {
          dispatch(AUTH_LOGIN_REJECTED_ACTION("Token not recognized."));
        }
      } else {
        dispatch(CONSUMER_REGISTER_REJECTED_ACTION(`Unknown error. Response status: ${response.status}`));
      }
    }).catch((error) => {
      let errorMessage = error.message;
      if (error.response && error.response.data && error.response.data.messages) {
        errorMessage = error.response.data.messages.join(" ");
      }
      dispatch(CONSUMER_REGISTER_REJECTED_ACTION(`Failed to register consumer. (${errorMessage})`))
    })
  };
}
