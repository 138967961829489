import React from "react";
import MenuView from "../views/menu/index";
import {HeaderComponent, ProgressBar} from "../components";
import {useSelector} from "react-redux";

const WithWizardLayout = (WrappedComponent) => {
  const isMenuOpen = useSelector( state => state.menuStore.isMenuOpen);

  return (
    <div className="bg-teal-100 flex flex-col h-full min-h-screen">
      <HeaderComponent />
      {
        isMenuOpen ?
          <MenuView />
          :
          <section className="flex flex-grow flex-row h-full justify-center max-h-full w-full">
            <div className="hidden md:block">
              <MenuView />
            </div>
            <div className="flex flex-col items-center justify-start w-11/12 md:w-full">
              <ProgressBar />
              <WrappedComponent />
            </div>
          </section>
      }
    </div>
  );
};

export default WithWizardLayout;
