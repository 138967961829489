import RUF from "../../util/redux";

import {
  CLOSE_MENU,
  OPEN_MENU,
  SET_SECTION_FLAG
} from "../constants/_menu_constants";

const initialState = {
  isMenuOpen: false,
  sections: {
    intro: false,
    contact: false,
    personal: false,
    employment: false,
    debts: false,
    declarations: false,
    hmda: false,
    down_payment: false,
  }
};

export default function menuReducer(state = initialState, action) {
  switch(action.type) {
    case CLOSE_MENU: {
      return RUF.updateObject(state, { isMenuOpen: action.payload });
    }
    case OPEN_MENU: {
      return RUF.updateObject(state, { isMenuOpen: action.payload });
    }
    case SET_SECTION_FLAG: {
      switch (action.payload.section) {
        case "intro":
          return RUF.updateObject(state, {
            sections: RUF.updateObject(state.sections, { intro: action.payload.flag})
          });
        case "contact":
          return RUF.updateObject(state, {
            sections: RUF.updateObject(state.sections, { contact: action.payload.flag})
          });
        case "personal":
          return RUF.updateObject(state, {
            sections: RUF.updateObject(state.sections, { personal: action.payload.flag})
          });
        case "employment":
          return RUF.updateObject(state, {
            sections: RUF.updateObject(state.sections, { employment: action.payload.flag})
          });
        case "debts":
          return RUF.updateObject(state, {
            sections: RUF.updateObject(state.sections, { debts: action.payload.flag})
          });
        case "declarations":
          return RUF.updateObject(state, {
            sections: RUF.updateObject(state.sections, { declarations: action.payload.flag})
          });
        case "hmda":
          return RUF.updateObject(state, {
            sections: RUF.updateObject(state.sections, { hmda: action.payload.flag})
          });
        case "down_payment":
          return RUF.updateObject(state, {
            sections: RUF.updateObject(state.sections, { down_payment: action.payload.flag})
          });
        default:
          return state;
      }
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
