import React from "react";

const iContent = {
  check: "M0 11l2-2 5 5L18 3l2 2L7 18z",
  close: "M10 8.58578644L2.92893219 1.51471863 1.51471863 2.92893219 8.58578644 10l-7.07106781 7.0710678 1.41421356 1.4142136L10 11.4142136l7.0710678 7.0710678 1.4142136-1.4142136L11.4142136 10l7.0710678-7.07106781-1.4142136-1.41421356L10 8.58578644z",
  clip: "M12.98 7.27l-6.132 6.132c-1.178 1.178-3.087 1.178-4.266 0s-1.178-3.087 0-4.266L9.247 2.47c.736-.736 1.93-.736 2.666 0s.736 1.93 0 2.666l-5.6 5.6c-.293.293-.77.296-1.066 0s-.293-.773 0-1.066l5.066-5.066-.8-.8L4.448 8.87c-.736.736-.736 1.93 0 2.666s1.93.736 2.666 0l5.6-5.6c1.178-1.178 1.178-3.087 0-4.266s-3.087-1.178-4.266 0L1.782 8.336a4.15 4.15 0 0 0 0 5.866 4.15 4.15 0 0 0 5.866 0L13.78 8.07l-.8-.8z",
  menu: "M16.4 9H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1zm0 4H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1zM3.6 7h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1z"
};

const iSize = {
  check: { height: "11px", width: "14px"},
  close: { height: "16px", width: "16px"},
  clip: { height: "16px", width: "16px"},
  menu: { height: "28px", width: "30px"}
};

const iStyle = {
  check: "mr-1 ml-1 text-teal-500",
  close: "mx-2 my-1 text-teal-500",
  clip: "mr-1 -mt-1 ml-1 text-teal-500",
  menu: "text-white"
};

const iViewBox = {
  check: "0 0 20 20",
  close: "0 0 20 20",
  clip: "0 0 14 16",
  menu: "0 0 20 20"
};

const Icon = props => {
  switch (props.icon) {
    case "jpg":
      return (
        <svg
          className="inline-block h-16 mr-4 w-16"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56 56">
          <path fill="#e9e9e0" d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257C37.442.093 37.218 0 36.985 0z"/>
          <path fill="#d9d7ca" d="M37.5.151V12h11.849z"/>
          <circle cx="18.931" cy="14.431" r="4.569" fill="#f3d55b"/>
          <path fill="#26b99a" d="M6.5 39h43V28l-10-9.5L29 30l-5.483-5.483z"/>
          <path fill="#14a085" d="M48.037 56H7.963c-.808 0-1.463-.655-1.463-1.463V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"/>
          <g fill="#fff">
            <path d="M21.426 42.65v7.848c0 .474-.087.873-.26 1.196-.173.323-.406.583-.697.779-.292.196-.627.333-1.005.41-.379.078-.768.117-1.169.117-.201 0-.436-.021-.704-.062-.269-.041-.547-.104-.834-.191s-.563-.185-.827-.294c-.265-.109-.488-.232-.67-.369l.697-1.107c.091.063.221.13.39.198.168.068.353.132.554.191.2.06.41.111.629.157s.424.068.615.068c.483 0 .868-.094 1.155-.28s.439-.504.458-.95V42.65h1.668zM25.514 52.932h-1.641V42.855h2.898c.428 0 .852.068 1.271.205.419.137.795.342 1.128.615.333.273.602.604.807.991s.308.822.308 1.306c0 .511-.087.973-.26 1.388-.173.415-.415.764-.725 1.046-.31.282-.684.501-1.121.656s-.921.232-1.449.232h-1.217v3.638zm0-8.832v3.992h1.504c.2 0 .398-.034.595-.103.196-.068.376-.18.54-.335s.296-.371.396-.649c.1-.278.15-.622.15-1.032 0-.164-.023-.354-.068-.567-.046-.214-.139-.419-.28-.615-.142-.196-.34-.36-.595-.492-.256-.133-.593-.199-1.012-.199h-1.23zM39.5 47.736v3.896c-.21.265-.444.48-.704.649s-.533.308-.82.417-.583.187-.889.232c-.306.048-.608.07-.909.07-.602 0-1.155-.109-1.661-.328s-.948-.542-1.326-.971c-.378-.429-.675-.966-.889-1.613-.214-.647-.321-1.395-.321-2.242s.107-1.593.321-2.235c.214-.643.51-1.178.889-1.606.378-.429.822-.754 1.333-.978.51-.224 1.062-.335 1.654-.335.547 0 1.057.091 1.531.273.474.183.897.456 1.271.82l-1.135 1.012c-.219-.265-.47-.456-.752-.574-.283-.118-.574-.178-.875-.178-.337 0-.659.063-.964.191-.306.128-.579.344-.82.649-.242.306-.431.699-.567 1.183s-.21 1.075-.219 1.777c.009.684.08 1.276.212 1.777.132.501.314.911.547 1.23s.497.556.793.711c.296.155.608.232.937.232.1 0 .234-.007.403-.021.168-.014.337-.036.506-.068.168-.032.33-.075.485-.13.155-.055.269-.132.342-.232v-2.488h-1.709v-1.121H39.5z"/>
          </g>
        </svg>
      );
    case "pdf":
      return (
        <svg
          className="inline-block h-16 mr-4 w-16"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56 56">
          <path fill="#e9e9e0" d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257C37.442.093 37.218 0 36.985 0z"/>
          <path fill="#d9d7ca" d="M37.5.151V12h11.849z"/>
          <path fill="#cc4b4c" d="M19.514 33.324c-.348 0-.682-.113-.967-.326-1.041-.781-1.181-1.65-1.115-2.242.182-1.628 2.195-3.332 5.985-5.068 1.504-3.296 2.935-7.357 3.788-10.75-.998-2.172-1.968-4.99-1.261-6.643.248-.579.557-1.023 1.134-1.215.228-.076.804-.172 1.016-.172.504 0 .947.649 1.261 1.049.295.376.964 1.173-.373 6.802 1.348 2.784 3.258 5.62 5.088 7.562 1.311-.237 2.439-.358 3.358-.358 1.566 0 2.515.365 2.902 1.117.32.622.189 1.349-.39 2.16-.557.779-1.325 1.191-2.22 1.191-1.216 0-2.632-.768-4.211-2.285-2.837.593-6.15 1.651-8.828 2.822-.836 1.774-1.637 3.203-2.383 4.251-1.025 1.435-1.909 2.105-2.784 2.105zm2.662-5.126c-2.137 1.201-3.008 2.188-3.071 2.744-.01.092-.037.334.431.692.149-.047 1.019-.444 2.64-3.436zm13.637-4.442c.815.627 1.014.944 1.547.944.234 0 .901-.01 1.21-.441.149-.209.207-.343.23-.415-.123-.065-.286-.197-1.175-.197-.505.001-1.14.023-1.812.109zm-7.47-6.582c-.715 2.474-1.659 5.145-2.674 7.564 2.09-.811 4.362-1.519 6.496-2.02-1.35-1.568-2.699-3.526-3.822-5.544zm-.607-8.462c-.098.033-1.33 1.757.096 3.216.949-2.115-.053-3.23-.096-3.216zM48.037 56H7.963c-.808 0-1.463-.655-1.463-1.463V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"/>
          <g fill="#fff">
            <path d="M17.385 53h-1.641V42.924h2.898c.428 0 .852.068 1.271.205.419.137.795.342 1.128.615.333.273.602.604.807.991s.308.822.308 1.306c0 .511-.087.973-.26 1.388-.173.415-.415.764-.725 1.046-.31.282-.684.501-1.121.656s-.921.232-1.449.232h-1.217V53zm0-8.832v3.992h1.504c.2 0 .398-.034.595-.103.196-.068.376-.18.54-.335.164-.155.296-.371.396-.649.1-.278.15-.622.15-1.032 0-.164-.023-.354-.068-.567-.046-.214-.139-.419-.28-.615-.142-.196-.34-.36-.595-.492-.255-.132-.593-.198-1.012-.198h-1.23zM32.219 47.682c0 .829-.089 1.538-.267 2.126s-.403 1.08-.677 1.477-.581.709-.923.937-.672.398-.991.513c-.319.114-.611.187-.875.219-.264.03-.46.046-.588.046h-3.814V42.924h3.035c.848 0 1.593.135 2.235.403s1.176.627 1.6 1.073.74.955.95 1.524c.21.57.315 1.156.315 1.758zm-4.867 4.115c1.112 0 1.914-.355 2.406-1.066s.738-1.741.738-3.09c0-.419-.05-.834-.15-1.244-.101-.41-.294-.781-.581-1.114s-.677-.602-1.169-.807-1.13-.308-1.914-.308h-.957v7.629h1.627zM36.266 44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244h-4.634z"/>
          </g>
        </svg>
      );
    case "png":
      return (
        <svg
          className="inline-block h-16 mr-4 w-16"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56 56">
          <path fill="#e9e9e0" d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257C37.442.093 37.218 0 36.985 0z"/>
          <path fill="#659c35" d="M48.037 56H7.963c-.808 0-1.463-.655-1.463-1.463V39h43v15.537c0 .808-.655 1.463-1.463 1.463z"/>
          <path fill="#d9d7ca" d="M37.5.151V12h11.849z"/>
          <g fill="#fff">
            <path d="M17.385 53h-1.641V42.924h2.898c.428 0 .852.068 1.271.205.419.137.795.342 1.128.615.333.273.602.604.807.991s.308.822.308 1.306c0 .511-.087.973-.26 1.388-.173.415-.415.764-.725 1.046-.31.282-.684.501-1.121.656s-.921.232-1.449.232h-1.217V53zm0-8.832v3.992h1.504c.2 0 .398-.034.595-.103.196-.068.376-.18.54-.335.164-.155.296-.371.396-.649.1-.278.15-.622.15-1.032 0-.164-.023-.354-.068-.567-.046-.214-.139-.419-.28-.615-.142-.196-.34-.36-.595-.492-.255-.132-.593-.198-1.012-.198h-1.23zM31.316 42.924V53h-1.668l-3.951-6.945V53h-1.668V42.924h1.668l3.951 6.945v-6.945h1.668zM41.16 47.805v3.896c-.21.265-.444.48-.704.649s-.533.308-.82.417-.584.187-.889.233c-.306.046-.608.068-.909.068-.602 0-1.155-.109-1.661-.328s-.948-.542-1.326-.971c-.378-.429-.675-.966-.889-1.613-.214-.647-.321-1.395-.321-2.242s.107-1.593.321-2.235c.214-.643.51-1.178.889-1.606.378-.429.822-.754 1.333-.978.51-.224 1.062-.335 1.654-.335.547 0 1.057.091 1.531.273.474.183.897.456 1.271.82l-1.135 1.012c-.219-.265-.47-.456-.752-.574-.283-.118-.574-.178-.875-.178-.337 0-.659.063-.964.191-.306.128-.579.344-.82.649-.242.306-.431.699-.567 1.183s-.21 1.075-.219 1.777c.009.684.08 1.276.212 1.777.132.501.314.911.547 1.23s.497.556.793.711c.296.155.608.232.937.232.1 0 .234-.007.403-.021.168-.014.337-.036.506-.068.168-.032.33-.075.485-.13.155-.055.269-.132.342-.232v-2.488h-1.709v-1.121h3.336z"/>
          </g>
          <circle cx="18.931" cy="14.431" r="4.569" fill="#f3d55b"/>
          <path fill="#88c057" d="M6.5 39h43V28l-10-9.5L29 30l-5.483-5.483z"/>
        </svg>
      );
    default:
      return (
        <svg
          className={`fill-current inline-block ${props.istyle || iStyle[props.icon]} ${props.className}`}
          style={{height: iSize[props.icon].height || "16px", width: iSize[props.icon].width || "16px"}}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`${iViewBox[props.icon]}`}>
          <path d={iContent[props.icon]}/>
        </svg>
      )
  }
};

export default Icon;
