import React from "react";

const TextBoxComponent = ({ className, keyId, label, name, onChange, placeholder, value, type }) => {
  return (
    <div className={`flex flex-col border-b border-teal-500 py-2 mb-2 md:mb-4 ${className || ''}`}>
      <label className="block cp-font-r14w300g800 mb-1" htmlFor={keyId}>
        {label}
      </label>
      <input
        className="cp-input"
        id={keyId}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value || ""}
        type={type || "text"}
      />
    </div>
  );
};

export default TextBoxComponent;
