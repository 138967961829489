import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {PERSONAL_MAILING_ADDRESS_MAIN_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, ErrorsComponent, TextBoxComponent, ZipCodeComponent} from "../../../components";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  street: Yup
    .string()
    .max(150, "Street address value is too long.")
    .min(2, "Street address value is invalid.")
    .required("Street address is required."),
  unitNumber: Yup.string(),
  city: Yup
    .string()
    .max(150, "City value is too long.")
    .min(2, "City value is invalid.")
    .required("City is required."),
  state: Yup
    .string()
    .matches(/^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/, "Please enter a 2-letter State Code e.g.: NY")
    .required("State is required."),
  zipCode: Yup
    .string()
    .matches(/^\d{5}$/, "ZipCode value should be five digits.")
    .required("ZipCode value is required.")
});

const PersonalMailingAddressMain = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setMailingAddress = useCallback((data) => dispatch(PERSONAL_MAILING_ADDRESS_MAIN_ACTION(data)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const {street, unitNumber, city, state, zipCode} = applicant && applicant.mailingAddress ? applicant.mailingAddress : {};

  return (
    <Formik
      initialValues={{ street, unitNumber, city, state, zipCode }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        values.state = values.state.toUpperCase();
        setMailingAddress(values);
        resetForm({});
        setSubmitting(false);
        navigateNext("personal_mailing_address_years_at", false, applicantIndex);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent title={`${applicant.firstName}'s Mailing Address`}>
          <TextBoxComponent
            label="Address"
            name="street"
            onChange={handleChange}
            placeholder="Street address"
            value={values.street}
          />
          <TextBoxComponent
            label="Unit Number"
            name="unitNumber"
            onChange={handleChange}
            placeholder="Number"
            value={values.unitNumber}
          />
          <TextBoxComponent
            label="City"
            name="city"
            onChange={handleChange}
            placeholder="City"
            value={values.city}
          />
          <div className="flex flex-col md:justify-between md:flex-row">
            <TextBoxComponent
              className="md:mr-4 md:w-1/2"
              label="State"
              name="state"
              onChange={handleChange}
              placeholder="XX"
              value={values.state}
            />
            <ZipCodeComponent
              className="md:ml-4 md:w-1/2"
              label="Zip Code"
              name="zipCode"
              onChange={handleChange}
              placeholder="NNNNN"
              value={values.zipCode}
            />
          </div>
          {
            errors && touched.street && touched.city && touched.state && touched.zipCode &&
            <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  )
};

export default PersonalMailingAddressMain;
