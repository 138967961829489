import React from "react";
import Utils from "../../util/format";
/*
const CorePlan = ({loanAmount, loanName, monthlyPayment, onChange}) => {
  return (
    <React.Fragment>
      <div className="border-b border-gray-300 flex flex-col items-center py-4 mb-2 w-full">
        <label className="font-sans font-normal mb-8 text-center text-gray-900 text-lg">{loanName}</label>
      </div>
      <label className="font-sans font-light text-gray-500 text-sm leading-tight mb-2 mt-2">Loan Amount:</label>
      <label className="font-sans font-normal text-gray-900 text-base leading-tight mb-4">{loanAmount}</label>
      <label className="font-sans font-light text-gray-500 text-sm leading-tight mb-1">Estimated</label>
      <label className="font-sans font-light text-gray-500 text-sm leading-tight mb-2">Monthly Payment:</label>
      <label className="font-sans font-normal text-gray-900 text-base leading-tight mb-4">{monthlyPayment}</label>
      <button
        className="bg-teal-500 my-4 hover:bg-teal-600 text-white py-3 px-4 rounded-full w-full focus:outline-none"
        onClick={onChange}
      >
        Choose Loan
      </button>
    </React.Fragment>
  );
};

const BestLoanPlan = ({onChange, ...props}) => {
  return (
    <div className="bg-teal-500 flex flex-col items-center max-w-md mb-4 mr-4 p-1 rounded-lg overflow-hidden shadow-xl">
      <label className="font-sans font-normal py-2 text-center text-white text-lg">BEST OPTION</label>
      <div className="bg-white flex flex-col items-center h-full px-4 rounded-lg w-250px">
        <CorePlan {...props} onChange={onChange} />
      </div>
    </div>
  );
};

const NormalLoanPlan = ({onChange, ...props}) => {
  return (
    <div className="bg-white border border-gray-200 flex flex-col hover:border-teal-500 items-center max-w-md mb-4 md:mr-4 px-8 py-2 rounded-lg overflow-hidden shadow-xl">
      <CorePlan {...props} onChange={onChange} />
    </div>
  );
};
*/
const LoanPlan = ({loanAmount, loanName, monthlyPayment, onChange}) => {
  const lAmount = loanAmount ? Utils.formatMoney(loanAmount) : null;
  const mPayment = monthlyPayment ? Utils.formatMoney(monthlyPayment) : null;
  return (
    <div className="bg-white border border-gray-200 flex flex-col hover:border-teal-500 items-center max-w-md mb-4 md:mr-4 px-8 py-2 rounded-lg overflow-hidden shadow-xl">
      <div className="border-b border-gray-300 flex flex-col items-center py-4 mb-2 w-full">
        <label className="font-sans font-normal mb-8 text-center text-gray-900 text-lg">{loanName}</label>
      </div>
      <label className="font-sans font-light text-gray-500 text-sm leading-tight mb-2 mt-2">Loan Amount:</label>
      <label className="font-sans font-normal text-gray-900 text-base leading-tight mb-4">{lAmount}</label>
      <label className="font-sans font-light text-gray-500 text-sm leading-tight mb-1">Estimated</label>
      <label className="font-sans font-light text-gray-500 text-sm leading-tight mb-2">Monthly Payment:</label>
      <label className="font-sans font-normal text-gray-900 text-base leading-tight mb-4">{mPayment}</label>
      <button
        className="bg-teal-500 my-4 hover:bg-teal-600 text-white py-3 px-4 rounded-full w-full focus:outline-none"
        onClick={onChange}
      >
        Choose Loan
      </button>
    </div>
  )
};

export default LoanPlan;
