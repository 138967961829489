import {
  CLOSE_MENU,
  OPEN_MENU,
  SET_SECTION_FLAG
} from "../../constants/_menu_constants";

export const CLOSE_MENU_ACTION = () => ({
  type: CLOSE_MENU,
  payload: false
});

export const OPEN_MENU_ACTION = () => ({
  type: OPEN_MENU,
  payload: true
});

export const SET_SECTION_FLAG_ACTION = (section, flag) => ({
  type: SET_SECTION_FLAG,
  payload: {section, flag}
});
