import React, {useState} from "react";
import Checkbox from "./_checkbox";
import {ButtonComponent} from "../index";

const CheckboxContainer = ({checkboxes, submitHandler}) => {
  const [options, setOptions] = useState(checkboxes);

  const _handleChange = (event) => {
    const index = options.findIndex(x => x.name === event.target.name);
    if (index > -1) {
      setOptions([
        ...options.slice(0, index),
        Object.assign({}, options[index], {
          value: event.target.checked
        }),
        ...options.slice(index+1)
      ])
    }
  };

  const _handleSubmit = () => {
    let response = {};
    for(let {name, value} of options){
      response[name] = value;
    }
    submitHandler(response);
  };

  return (
    <div className="mt-4">
      {
        options.map(item => (
          <Checkbox
            checked={item.value}
            key={item.key}
            label={item.label}
            name={item.name}
            onChange={_handleChange}
          />
        ))
      }
      <ButtonComponent onClick={_handleSubmit} text="Continue" />
    </div>
  );
};

export default CheckboxContainer;
