import {
  CONTACT_DETAILS,
  CONTACT_SUMMARY,
  DEBTS_OTHER_OBLIGATIONS,
  DEBTS_PERMISSION_QUESTION,
  DEBTS_SET_SSN,
  DECLARATIONS_STATEMENTS_SAVE,
  DOWN_PAYMENT_AMOUNT_SAVE,
  DOWN_PAYMENT_ASSISTED_QUESTION,
  DOWN_PAYMENT_BANK_ACCOUNTS,
  DOWN_PAYMENT_CLOSING_COSTS,
  DOWN_PAYMENT_GIFTED_QUESTION,
  DOWN_PAYMENT_SELECTION_SAVE,
  DOWN_PAYMENT_STATED_BANK_COUNT,
  EMPLOYMENT_QUESTION,
  EMPLOYMENT_MAIN,
  EMPLOYMENT_START_DATE,
  EMPLOYMENT_PERCENT_OWNERSHIP,
  EMPLOYMENT_AGI_AND_DEPRECIATION,
  EMPLOYMENT_MONTHLY_EARNINGS,
  EMPLOYMENT_MULTIPLE_EMPLOYMENTS_QUESTION,
  EMPLOYMENT_MISC_INCOME,
  HMDA_INFO_SAVE,
  META_SET_CURRENT_APPLICANT,
  META_SET_CURRENT_EMPLOYMENT_INDEX,
  META_SET_CURRENT_STATE,
  META_SET_EDIT_MODE,
  META_SET_PREVIOUS_STATE,
  META_SET_TOTAL_APPLICANTS,
  MODEL_APPLICANTS_ALLOC,
  MODEL_APPLICANTS_POP,
  MODEL_APPLICANTS_PUSH,
  MODEL_SET_LOAN_AMOUNT,
  MODEL_SET_LOAN_GUID,
  MODEL_SET_LOAN_PURPOSE,
  MODEL_SET_LOAN_TERM,
  MODEL_SET_RESIDENCE_TYPE,
  MODEL_SET_NUMBER_OF_STATED_APPLICANTS,
  PERSONAL_DETAILS,
  PERSONAL_CURRENT_ADDRESS_LESS_THAN_TWO,
  PERSONAL_CURRENT_ADDRESS_MAIN,
  PERSONAL_CURRENT_ADDRESS_OWNERSHIP,
  PERSONAL_CURRENT_ADDRESS_MONTHLY_EXPENSES,
  PERSONAL_CURRENT_ADDRESS_YEARS_AT,
  PERSONAL_MAILING_ADDRESS_DIFFERENT,
  PERSONAL_MAILING_ADDRESS_MAIN,
  PERSONAL_MAILING_ADDRESS_YEARS_AT,
  PERSONAL_FORMER_ADDRESS_MAIN,
  PERSONAL_FORMER_ADDRESS_OWNERSHIP,
  PERSONAL_FORMER_ADDRESS_MONTHLY_EXPENSES,
  PERSONAL_FORMER_ADDRESS_YEARS_AT,
  PERSONAL_MILITARY_STATUS,
  PERSONAL_SUMMARY
} from "../constants/loan";

import contactFunc from "../functions/contact_info/index";
import debtsFunc from "../functions/other_debts/index";
import declarationsFunc from "../functions/declarations/index";
import downPaymentFunc from "../functions/down_payment/index";
import employmentFunc from "../functions/employment/index";
import hmdaFunc from "../functions/hmda/index";
import metaFunc from "../functions/meta/index";
import modelFunc from "../functions/model/index";
import personalFunc from "../functions/personal/index";

const initialState = {
  meta: {
    currentApplicant: 0,
    currentEmploymentIndex: -1,
    currentState: "intro_welcome",
    previousState: "",
    inEditMode: false,
    totalApplicants: 0,

    employmentPercentOwnership: {
      "Yes": 0.25,
      "No": 0
    },
    loanPurposeOptions: {
      "Buying a New Home": "Purchase",
      "Refinancing Existing Home": "Refinance",
      "Other": "Other"
    },
    loanTermYears: {
      "15 Years": 15,
      "20 Years": 20,
      "30 Years": 30
    },
    occupyIntendAnswers: {
      "Primary Residence": "Primary",
      "Second Home": "Secondary",
      "Investment": "Investment"
    },
    militaryStatusOptions: {
      "Currently Serving Active Duty": "CurrentActiveDuty",
      "Currently retired, discharged, or separated from service": "Retired",
      "Only period of service was as a non-activated member of the Reserve or National Guard": "SurvivingSpouse",
      "I am a surviving spouse of a current of formerly Active military veteran": "Unmarried",
      "None of the Above": "None"
    },
    numberOfStatedApplicantsOptions: {
      "Just Me": 1,
      "Me and One Other": 2
    },
    numberOfStatedApplicantsOptions1: {
      "Just Me": 1,
      "Me and One Other": 2,
      "Me and Two Others": 3,
      "Me and Three Others": 4
    },
    addressOwnershipOptions: {
      "Own": "Own",
      "Rent": "Rent"
    },
    yearsAtAddressOptions: {
      "0": 0,
      "1": 1,
      "2": 2,
      "3+": 3
    },
    yesNoAnswerOptions: {
      "Yes": true,
      "No": false
    },
    hmdaEthnicity: {
      "Hispanic or Latino": "Hispanic or Latino",
      "Not Hispanic or Latino": "Not Hispanic or Latino",
      "I prefer not to supply this information": "NA"
    },
    hmdaGender: {
      "Male": "Male",
      "Female": "Female",
      "I prefer not to supply this information": "NA"
    },
    hmdaRace: {
      "American Indian or Alaska Native": "American Indian or Alaska Native",
      "Native Hawaiian or Pacific Islander": "Native Hawaiian or Pacific Islander",
      "Asian": "Asian",
      "White": "White",
      "Black or African American": "Black or African American",
      "I prefer not to supply this information": "NA"
    },
    downPaymentOptions: {
      "3.5% Down (FHA Loans)": 3.5,
      "5% Down (Conventional Loans)": 5,
      "20% Down (No Mortgage Insurance)": 20
    },
    downPaymentOptionsWithVA: {
      "Nothing Down (VA Loans)": 0,
      "3.5% Down (FHA Loans)": 3.5,
      "5% Down (Conventional Loans)": 5,
      "20% Down (No Mortgage Insurance)": 20
    },
    downPaymentStatedBankCount: {
      "One": 1,
      "Two": 2,
      "Three": 3,
      "Four": 4
    },
    downPaymentClosingCosts: {
      "High Out of Pocket / Lowest Rate": "HighLow",
      "Mid Out of Pocket / Mid Rate": "MidMid",
      "Low Out of Pocket / Higher Rate": "LowHigh"
    }
  },
  model: {
    applicants: [],
    guid: "",
    loanPurpose: "",
    numberOfStatedApplicants: 0
  }
};

export default function introReducer(state = initialState, action) {
  switch(action.type) {
    case CONTACT_DETAILS:
      return contactFunc.contactDetails(state, action);
    case CONTACT_SUMMARY:
      return contactFunc.contactSummary(state, action);

    case PERSONAL_DETAILS:
      return personalFunc.personalDetails(state, action);
    case PERSONAL_CURRENT_ADDRESS_LESS_THAN_TWO:
      return personalFunc.personalCurrentAddressLessThanTwo(state, action);
    case PERSONAL_CURRENT_ADDRESS_MAIN:
      return personalFunc.personalCurrentAddressMain(state, action);
    case PERSONAL_CURRENT_ADDRESS_OWNERSHIP:
      return personalFunc.personalCurrentAddressOwnership(state, action);
    case PERSONAL_CURRENT_ADDRESS_MONTHLY_EXPENSES:
      return personalFunc.personalCurrentAddressMonthlyExpenses(state, action);
    case PERSONAL_CURRENT_ADDRESS_YEARS_AT:
      return personalFunc.personalCurrentAddressYearsAt(state, action);
    case PERSONAL_MAILING_ADDRESS_DIFFERENT:
      return personalFunc.personalMailingAddressDifferent(state, action);
    case PERSONAL_MAILING_ADDRESS_MAIN:
      return personalFunc.personalMailingAddressMain(state, action);
    case PERSONAL_MAILING_ADDRESS_YEARS_AT:
      return personalFunc.personalMailingAddressYearsAt(state, action);
    case PERSONAL_FORMER_ADDRESS_MAIN:
      return personalFunc.personalFormerAddressMain(state, action);
    case PERSONAL_FORMER_ADDRESS_OWNERSHIP:
      return personalFunc.personalFormerAddressOwnership(state, action);
    case PERSONAL_FORMER_ADDRESS_MONTHLY_EXPENSES:
      return personalFunc.personalFormerAddressMonthlyExpenses(state, action);
    case PERSONAL_FORMER_ADDRESS_YEARS_AT:
      return personalFunc.personalFormerAddressYearsAt(state, action);
    case PERSONAL_MILITARY_STATUS:
      return personalFunc.personalMilitaryStatus(state, action);
    case PERSONAL_SUMMARY:
      return personalFunc.personalSummary(state, action);

    case EMPLOYMENT_QUESTION:
      return employmentFunc.employmentQuestion(state, action);
    case EMPLOYMENT_MAIN:
      return employmentFunc.employmentMain(state, action);
    case EMPLOYMENT_START_DATE:
      return employmentFunc.employmentStartDate(state, action);
    case EMPLOYMENT_PERCENT_OWNERSHIP:
      return employmentFunc.employmentPercentOwnership(state, action);
    case EMPLOYMENT_MONTHLY_EARNINGS:
      return employmentFunc.employmentMonthlyEarnings(state, action);
    case EMPLOYMENT_MULTIPLE_EMPLOYMENTS_QUESTION:
      return employmentFunc.employmentMultipleEmployments(state, action);
    case EMPLOYMENT_MISC_INCOME:
      return employmentFunc.employmentMiscIncome(state, action);

    /*
    case EMPLOYMENT_AGI_AND_DEPRECIATION:
    */

    case DEBTS_PERMISSION_QUESTION:
      return debtsFunc.debtsPermissionQuestion(state, action);
    case DEBTS_SET_SSN:
      return debtsFunc.debtsSetSSN(state, action);
    case DEBTS_OTHER_OBLIGATIONS:
      return debtsFunc.debtsOtherObligations(state, action);

    case DECLARATIONS_STATEMENTS_SAVE:
      return declarationsFunc.declarationsStatementsSave(state, action);

    case DOWN_PAYMENT_AMOUNT_SAVE:
      return downPaymentFunc.downPaymentAmountSave(state, action);
    case DOWN_PAYMENT_SELECTION_SAVE:
      return downPaymentFunc.downPaymentSelectionsSave(state, action);
    case DOWN_PAYMENT_GIFTED_QUESTION:
      return downPaymentFunc.downPaymentGiftedQuestionSave(state, action);
    case DOWN_PAYMENT_ASSISTED_QUESTION:
      return downPaymentFunc.downPaymentAssistedQuestionSave(state, action);
    case DOWN_PAYMENT_STATED_BANK_COUNT:
      return downPaymentFunc.downPaymentStatedBankCountSave(state, action);
    case DOWN_PAYMENT_BANK_ACCOUNTS:
      return downPaymentFunc.downPaymentBankAccountsSave(state, action);
    case DOWN_PAYMENT_CLOSING_COSTS:
      return downPaymentFunc.downPaymentClosingCostsSave(state, action);

    case HMDA_INFO_SAVE:
      return hmdaFunc.hmdaInfoSave(state, action);

    case META_SET_CURRENT_APPLICANT:
      return metaFunc.metaSetCurrentApplicant(state, action);
    case META_SET_CURRENT_EMPLOYMENT_INDEX:
      return metaFunc.metaSetCurrentEmploymentIndex(state, action);
    case META_SET_EDIT_MODE:
      return metaFunc.metaSetEditMode(state, action);
    case META_SET_PREVIOUS_STATE:
      return metaFunc.metaSetPreviousState(state, action);
    case META_SET_TOTAL_APPLICANTS:
      return metaFunc.metaSetTotalApplicants(state, action);
    case META_SET_CURRENT_STATE:
      return metaFunc.metaSetCurrentState(state, action);

    case MODEL_APPLICANTS_ALLOC:
      return modelFunc.modelApplicantsAlloc(state);
    case MODEL_APPLICANTS_POP:
      return modelFunc.modelApplicantsPop(state);
    case MODEL_APPLICANTS_PUSH:
      return modelFunc.modelApplicantsPush(state);
    case MODEL_SET_LOAN_AMOUNT:
      return modelFunc.modelSetLoanAmount(state, action);
    case MODEL_SET_LOAN_GUID:
      return modelFunc.modelSetLoanGuid(state, action);
    case MODEL_SET_LOAN_PURPOSE:
      return modelFunc.modelSetLoanPurpose(state, action);
    case MODEL_SET_LOAN_TERM:
      return modelFunc.modelSetLoanTerm(state, action);
    case MODEL_SET_RESIDENCE_TYPE:
      return modelFunc.modelSetResidenceType(state, action);
    case MODEL_SET_NUMBER_OF_STATED_APPLICANTS:
      return modelFunc.modelSetNumberOfStatedApplicants(state, action);

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
