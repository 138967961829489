import React from "react";
import {ApplicantsMenuOptions, MenuComponent} from "../../components";
import {withToastManager} from "react-toast-notifications";

const MenuView = ({toastManager}) => {

  const _submitRestriction = () => {
    toastManager.add(`You should complete all the sections first.`, {
      appearance: "warning",
      autoDismiss: true,
      pauseOnHover: true
    });
  };

  return (
    <nav className="cp-menu">
      <MenuComponent hasChild={true} isOpen={true} path="/contact" title="Contact Information">
        <ApplicantsMenuOptions menuLink="contact_details" />
      </MenuComponent>
      <MenuComponent hasChild={true} isOpen={true} path="/personal" title="Personal Information">
        <ApplicantsMenuOptions menuLink="personal_current_address_main" />
      </MenuComponent>
      <MenuComponent hasChild={true} isOpen={false} path="/employment" title="Employment / Income">
        <ApplicantsMenuOptions menuLink="employment_question" />
      </MenuComponent>
      <MenuComponent hasChild={true} isOpen={false} path="/debts" title="Debts / Expenses">
        <ApplicantsMenuOptions menuLink="debts_permission_question" />
      </MenuComponent>
      <MenuComponent hasChild={true} isOpen={false} path="/declarations" title="Declarations">
        <ApplicantsMenuOptions menuLink="declarations_statements_first" />
      </MenuComponent>
      <MenuComponent hasChild={true} isOpen={false} path="/hmda" title="HMDA">
        <ApplicantsMenuOptions menuLink="hmda_ethnicity" />
      </MenuComponent>
      <MenuComponent hasChild={false} menuLink="down_payment_selection" path="/down_payment" title="Down Payment" />
      <button className="cp-btn-menu-submit w-10/12" onClick={() => _submitRestriction()}>
        Submit
      </button>
    </nav>
  );
};

export default withToastManager(MenuView);
