import React, {Component} from "react";

const initialBirthDateState = {
  day: "",
  month: "",
  year: "",
  value: "",
  dayError: false,
  monthError: false,
  yearError: false,
  initDone: false
};

class BirthDateComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ...initialBirthDateState
    };
    this._initState = this._initState.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleValidation = this._handleValidation.bind(this);
  }

  _initState() {
    const date = new Date(this.props.value);
    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {  // d.valueOf() could also work
        // date is not valid
        this.setState(initialBirthDateState);
      } else {
        // date is valid
        this.setState({
          day: date.getDate() || "",
          month: this.props.value ? date.toLocaleString("en-us", { month: "long" }) : "",
          year: date.getFullYear() || "",
          value: this.props.value
        });
      }
    } else {
      // not a date
      this.setState(initialBirthDateState);
    }
  }

  componentDidMount() {
    setTimeout(() => this._initState(), 100);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      this._initState();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.value !== prevState.value){
      return {value : nextProps.value};
    } else return null;
  }


  _getMonthFromString = (month) => {
    const d = Date.parse(month + "1, 2019");
    if(!isNaN(d)){
      return new Date(d).getMonth();
    }
    return -1;
  };

  _handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    }, this._handleValidation);
  };

  _handleValidation = () => {
    const day = this.state.day ? parseInt(this.state.day) : "";
    const month = this.state.month ? this._getMonthFromString(this.state.month) : "";
    const year = this.state.year ? parseInt(this.state.year): "";

    if (day !== "" && month !== "" && year !== "") {
      this.setState({
        dayError: !(day && typeof day === "number" && day > 0 && day < 32),
        monthError: !(month && typeof month === "number" && month > -1 && month <12),
        yearError: !(year && typeof year === "number" && year > 1900 && year < 2100)
      },() => {
        const { dayError, monthError, yearError } = this.state;
        if (!dayError && !monthError && !yearError) {
          this._handleSubmit(day, month, year);
        }
      });
    }
  };

  _handleSubmit = (day, month, year) => {
    const date = new Date(year, month, day);
    const formattedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    this.props.onChange({
      target: {
        name: "dateOfBirth",
        value: formattedDate.toISOString()
      }
    });
  };

  render() {
    const { keyId } = this.props;
    const { day, month, year, dayError, monthError, yearError } = this.state;

    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col border-b border-teal-500 py-2 w-1/4">
            <label className="block cp-font-r14w300g800 mb-2" htmlFor={`bday-month-label-${keyId}`}>
              Month
            </label>
            <input
              className="cp-input"
              id={`bday-month-label-${keyId}`}
              name="month"
              onChange={this._handleChange}
              placeholder="June"
              value={month}
              type="text"
            />
          </div>
          <div className="flex flex-col border-b border-teal-500 py-2 w-1/4">
            <label className="block cp-font-r14w300g800 mb-2" htmlFor={`bday-day-label-${keyId}`}>
              Day
            </label>
            <input
              className="cp-input"
              id={`bday-day-label-${keyId}`}
              name="day"
              onChange={this._handleChange}
              placeholder="5"
              value={day}
              type="text"
            />
          </div>
          <div className="flex flex-col border-b border-teal-500 py-2 w-1/4">
            <label className="block cp-font-r14w300g800 mb-2" htmlFor={`bday-year-label-${keyId}`}>
              Year
            </label>
            <input
              className="cp-input"
              id={`bday-year-label-${keyId}`}
              name="year"
              onChange={this._handleChange}
              placeholder="2019"
              value={year}
              type="text"
            />
          </div>
        </div>
        <div className="font-sans font-normal mt-4 text-red-500">
          {
            dayError && <span>Day values should be between: 1 - 31.</span>
          }
          {
            monthError && <span>Value for month is incorrect. E.g: "Nov", "november"</span>
          }
          {
            yearError && <span>Please make sure your year value is correct.</span>
          }
        </div>
      </div>
    );
  }
}

export default BirthDateComponent;
