import { metaSetCurrentApplicant } from "./_meta_set_current_applicant";
import { metaSetCurrentEmploymentIndex } from "./_meta_set_current_employment";
import { metaSetCurrentState } from "./_meta_set_current_state";
import { metaSetEditMode } from "./_meta_set_edit_mode";
import { metaSetPreviousState } from "./_meta_set_previous_state";
import { metaSetTotalApplicants } from "./_meta_set_total_applicants";

export default {
  metaSetCurrentApplicant,
  metaSetCurrentEmploymentIndex,
  metaSetCurrentState,
  metaSetEditMode,
  metaSetPreviousState,
  metaSetTotalApplicants
}
