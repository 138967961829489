import React from "react";
import MaskedInput from "react-text-mask";

const SSNComponent = ({ keyId, label, name, onChange, placeholder, value }) => {

  const maskArray = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  const _handleChange = (event) => {
    onChange({
      target: {
        name: event.target.name,
        value: event.target.value.replace(/\D+/g, "")
      }
    });
  };

  return (
    <div className="flex flex-col border-b border-teal-500 py-2 mb-2 md:mb-4">
      <label className="block cp-font-r14w300g800 mb-1" htmlFor={keyId}>
        {label}
      </label>
      <MaskedInput
        autoFocus={false}
        className="cp-input"
        guide={false}
        id={keyId}
        name={name}
        onChange={_handleChange}
        placeholder={placeholder}
        type="text"
        value={value}
        mask={maskArray}
      />
    </div>
  );
};

export default SSNComponent;
