import {
  MODEL_APPLICANTS_ALLOC,
  MODEL_APPLICANTS_POP,
  MODEL_APPLICANTS_PUSH,
  MODEL_SET_LOAN_AMOUNT,
  MODEL_SET_LOAN_GUID,
  MODEL_SET_LOAN_PURPOSE,
  MODEL_SET_LOAN_TERM,
  MODEL_SET_RESIDENCE_TYPE,
  MODEL_SET_NUMBER_OF_STATED_APPLICANTS
} from "../../constants/loan/_model_constants";

export const MODEL_APPLICANTS_ALLOC_ACTION = () => ({
  type: MODEL_APPLICANTS_ALLOC,
  payload: null
});

export const MODEL_APPLICANTS_POP_ACTION = () => ({
  type: MODEL_APPLICANTS_POP,
  payload: null
});

export const MODEL_APPLICANTS_PUSH_ACTION = () => ({
  type: MODEL_APPLICANTS_PUSH,
  payload: null
});


export const MODEL_SET_LOAN_AMOUNT_ACTION = (value) => ({
  type: MODEL_SET_LOAN_AMOUNT,
  payload: value
});

export const MODEL_SET_LOAN_GUID_ACTION = (value) => ({
  type: MODEL_SET_LOAN_GUID,
  payload: value
});

export const MODEL_SET_LOAN_PURPOSE_ACTION = (value) => ({
  type: MODEL_SET_LOAN_PURPOSE,
  payload: value
});

export const MODEL_SET_LOAN_TERM_ACTION = (value) => ({
  type: MODEL_SET_LOAN_TERM,
  payload: value
});

export const MODEL_SET_RESIDENCE_TYPE_ACTION = (value) => ({
  type: MODEL_SET_RESIDENCE_TYPE,
  payload: value
});

export const MODEL_SET_NUMBER_OF_STATED_APPLICANTS_ACTION = (number) => ({
  type: MODEL_SET_NUMBER_OF_STATED_APPLICANTS,
  payload: number
});
