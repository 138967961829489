import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {MODEL_SET_RESIDENCE_TYPE_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const IntroResidenceType = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveResidenceType = useCallback((type) => dispatch(MODEL_SET_RESIDENCE_TYPE_ACTION(type)), [dispatch]);

  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const occupyIntendAnswers = useSelector( state => state.wizardStore.meta.occupyIntendAnswers);

  const _submit = (key, value) => {
    saveResidenceType(value);
    navigateNext(inEditMode ? "intro_summary" : "intro_nr_applicants");
  };

  return (
    <CardComponent hasProgress={true} title="How do you intend to occupy this home?">
      <OptionsComponent onClick={_submit} options={occupyIntendAnswers} />
    </CardComponent>
  )
};

export default IntroResidenceType;
