import RUF from "../../../util/redux";

export function modelApplicantsAlloc(state) {
  const array = state.model.applicants;
  const numberOfStatedApplicants = parseInt(state.model.numberOfStatedApplicants);

  return RUF.updateObject(state, {
    model: RUF.updateObject(state.model, {
      applicants: array.concat(new Array(numberOfStatedApplicants-array.length).fill({}))
    })
  });
}
