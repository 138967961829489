import { personalCurrentAddressLessThanTwo } from "./_personal_current_address_less_than_two";
import { personalCurrentAddressMain } from "./_personal_current_address_main";
import { personalCurrentAddressMonthlyExpenses } from "./_personal_current_address_monthly_expenses";
import { personalCurrentAddressOwnership } from "./_personal_current_address_ownership";
import { personalCurrentAddressYearsAt } from "./_personal_current_address_years_at";
import { personalDetails } from "./_personal_details";
import { personalFormerAddressMain } from "./_personal_former_address_main";
import { personalFormerAddressMonthlyExpenses } from "./_personal_former_address_monthly_expenses";
import { personalFormerAddressOwnership } from "./_personal_former_address_ownership";
import { personalFormerAddressYearsAt } from "./_personal_former_address_years_at";
import { personalMailingAddressDifferent } from "./_personal_mailing_address_different";
import { personalMailingAddressMain } from "./_personal_mailing_address_main";
import { personalMailingAddressYearsAt } from "./_personal_mailing_address_years_at";
import { personalMilitaryStatus } from "./_personal_military_status";
import { personalSummary } from "./_personal_summary";

export default {
  personalCurrentAddressLessThanTwo,
  personalCurrentAddressMain,
  personalCurrentAddressMonthlyExpenses,
  personalCurrentAddressOwnership,
  personalCurrentAddressYearsAt,
  personalDetails,
  personalFormerAddressMain,
  personalFormerAddressMonthlyExpenses,
  personalFormerAddressOwnership,
  personalFormerAddressYearsAt,
  personalMailingAddressDifferent,
  personalMailingAddressMain,
  personalMailingAddressYearsAt,
  personalMilitaryStatus,
  personalSummary
}
