import store from "../../store";
import {SET_SPINNER_FLAG_ACTION} from "../spinner/_spinner_actions";
import {SET_LOAN_PLANS_DATA_ACTION} from "../plans/_plans_actions";
import {_callApi} from "../../../util/fetch";

export function SAVE_LOAN_ACTION() {
  return (dispatch) => {
    const { model } = store.getState().wizardStore;
    dispatch(SET_SPINNER_FLAG_ACTION(true));

    _callApi(
      "mortgage",
      "/Mortgage",
      "PUT",
      {
        "loanApplicationID": model.guid,
        "mortgageApplication": model
      }
    ).then((response) => {
      dispatch(SET_SPINNER_FLAG_ACTION(false));
      if (response.status === 200 && response.data) {
        console.log(response.data);
      }
    }).catch((error) => {
      dispatch(SET_SPINNER_FLAG_ACTION(false));
      console.log(error);
    })
  };
}

export function SUBMIT_LOAN_ACTION() {
  return (dispatch) => {
    const { model } = store.getState().wizardStore;

    dispatch(SET_SPINNER_FLAG_ACTION(true));

    _callApi(
      "mortgage",
      "/Mortgage/submit",
      "POST",
      {
        "mortgageApplication": model
      }
    ).then((response) => {
      dispatch(SET_SPINNER_FLAG_ACTION(false));
      if (response.status === 200 && response.data) {
        dispatch(SET_LOAN_PLANS_DATA_ACTION(response.data));
        window.appHistory.push("/loan-plans");
      } else {
        window.appHistory.push("/referred-to-finance");
      }
    }).catch((error) => {
      dispatch(SET_SPINNER_FLAG_ACTION(false));
      console.log(error);
    })
  };
}
