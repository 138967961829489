import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {EMPLOYMENT_QUESTION_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const EmploymentQuestion = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setStatus = useCallback((flag) => dispatch(EMPLOYMENT_QUESTION_ACTION(flag)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const yesNoAnswerOptions = useSelector( state => state.wizardStore.meta.yesNoAnswerOptions);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];

  const _clickHandler = (key, value) => {
    setStatus(value);
    navigateNext(value ? "employment_main" : "employment_summary", false, applicantIndex);
  };

  return (
    <CardComponent title={`Is ${applicant.firstName} currently working?`}>
      <OptionsComponent onClick={_clickHandler} options={yesNoAnswerOptions} />
    </CardComponent>
  );
};

export default EmploymentQuestion;
