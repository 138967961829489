import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {PERSONAL_MAILING_ADDRESS_DIFFERENT_ACTION, PERSONAL_MAILING_ADDRESS_MAIN_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const PersonalMailingAddressQuestion = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setMailingAddress = useCallback((address) => dispatch(PERSONAL_MAILING_ADDRESS_MAIN_ACTION(address)), [dispatch]);
  const setMailingAddressDifferent = useCallback((flag) => dispatch(PERSONAL_MAILING_ADDRESS_DIFFERENT_ACTION(flag)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const yesNoAnswerOptions = useSelector( state => state.wizardStore.meta.yesNoAnswerOptions);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const currentAddress = applicant.currentAddress;
  const applicantStreet = applicant && currentAddress ?currentAddress.street || "" : "";

  const _clickHandler = (key, value) => {
    // the question asks if the mailing is the same and prop name is `mailingAddressDifferent`, so we negate
    setMailingAddressDifferent(!value);

    if (value === true && applicant && currentAddress) {
      // using Object Destructuring and Property Shorthand we will pick just the props that we want
      const pickyObject = (
        ({ years, street, unitNumber, city, state, zipCode }) => ({ years, street, unitNumber, city, state, zipCode })
      )(currentAddress);
      setMailingAddress(pickyObject);

      // we check if we are in edit mode
      if (inEditMode) {
        // we are in edit mode and we updated mailing address to match current address
        navigateNext("personal_summary", false, applicantIndex);
      } else {
        // we are not in edit mode so next we need to decide if we navigate to a former address state or further
        if (currentAddress.years && parseInt(currentAddress.years) >= 2) {
          // we don"t need a former address information in this case
          navigateNext("personal_military_status", false, applicantIndex);
        } else {
          // we want a former address information
          navigateNext("personal_former_address_main", false, applicantIndex);
        }
      }
    } else {
      // Mailing Address is Different, we need to provide it
      navigateNext("personal_mailing_address_main", false, applicantIndex);
    }
  };

  return (
    <CardComponent title={`Is ${applicant.firstName}'s Mailing Address the same: ${applicantStreet}?`}>
      <OptionsComponent onClick={_clickHandler} options={yesNoAnswerOptions} />
    </CardComponent>
  );
};

export default PersonalMailingAddressQuestion;
