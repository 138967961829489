import { downPaymentAmountSave } from "./_down_payment_amount";
import { downPaymentAssistedQuestionSave } from "./_down_payment_assisted_question";
import { downPaymentBankAccountsSave } from "./_down_payment_bank_accounts";
import { downPaymentClosingCostsSave } from "./_down_payment_closing_costs";
import { downPaymentGiftedQuestionSave } from "./_down_payment_gifted_question";
import { downPaymentSelectionsSave } from "./_down_payment_selection";
import { downPaymentStatedBankCountSave } from "./_down_payment_stated_bank_count";

export default {
  downPaymentAmountSave,
  downPaymentAssistedQuestionSave,
  downPaymentBankAccountsSave,
  downPaymentClosingCostsSave,
  downPaymentGiftedQuestionSave,
  downPaymentSelectionsSave,
  downPaymentStatedBankCountSave
}
