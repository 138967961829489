import React, {useCallback, useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import FileSaver from "file-saver";
import Icon from "../../icons";
import Utils from "../../util/format";
import {useDispatch, useSelector} from "react-redux";
import {LoansItemComponent} from "../../components";
import {
  GET_LOANS_ACTION,
  GET_LOAN_BIDS_ACTION,
  SELECT_LOAN_BID_ACTION,
  GET_LOAN_STIPULATIONS_ACTION,
  GET_USER_STIPULATIONS_ACTION,
  UPLOAD_STIPULATION_ACTION,
  DELETE_STIPULATION_ACTION,
  DOWNLOAD_STIPULATION_ACTION,
} from "../../redux/actions";

const DocumentsView = () => {
  const dispatch = useDispatch();
  const _getLoans = useCallback(() => dispatch(GET_LOANS_ACTION()), [dispatch]);
  const _getLoanBids = useCallback((loanID) => dispatch(GET_LOAN_BIDS_ACTION(loanID)), [dispatch]);
  const _selectLoanBid = useCallback((loanID, bidID) => dispatch(SELECT_LOAN_BID_ACTION(loanID, bidID)), [dispatch]);
  const _getLoanStipulations = useCallback((loanID) => dispatch(GET_LOAN_STIPULATIONS_ACTION(loanID)), [dispatch]);
  const _getUserStipulations = useCallback((loanID) => dispatch(GET_USER_STIPULATIONS_ACTION(loanID)), [dispatch]);
  const _uploadStipulation = useCallback((loanID, stipulation, fileName, mimeType, content) =>
    dispatch(UPLOAD_STIPULATION_ACTION(fileName, mimeType, loanID, stipulation.applicantID, stipulation.guid, stipulation.stipulationDefinitionID, content)), [dispatch]);
  const _removeStipulation = useCallback((loanID, stipulationID) => dispatch(DELETE_STIPULATION_ACTION(loanID, stipulationID)), [dispatch]);
  const _downloadStipulation = useCallback((loanID, stipulationID) => dispatch(DOWNLOAD_STIPULATION_ACTION(loanID, stipulationID)), [dispatch]);

  const loan = useSelector( state => state.docsStore.loans.selectedLoan);
  const loanBids = useSelector( state => state.docsStore.loanBids);
  const loanStipulations = useSelector( state => state.docsStore.loanStipulations);
  const userStipulations = useSelector( state => state.docsStore.userStipulations);
  const isUploading = useSelector( state => state.docsStore.uploadingStipulations);
  const isSelectingBid = useSelector( state => state.docsStore.selectingBid);
  const [uploadList, setUploadList] = useState( []);

  useEffect(() => {
    if (loan) {
      _getLoanBids(loan.guid);
      _getLoanStipulations(loan.guid);
      _getUserStipulations(loan.guid);
    } else {
      _getLoans();
    }
  }, [loan, _getLoans, _getLoanBids, _getLoanStipulations, _getUserStipulations]);

  const _filesDropped = (stipulation, files) => {
    files.forEach(function (file, index) {
      // just take the first file
      if (index < 1) {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const base64file = reader.result.split("base64,")[1];
          setUploadList([...uploadList, stipulation.guid]);

          _uploadStipulation(loan.guid, stipulation, file.name, file.type, base64file).then(() => {
            setUploadList(uploadList.filter(item => item !== stipulation.guid));
            _getLoanStipulations(loan.guid);
            _getUserStipulations(loan.guid);
          })
        };
        reader.readAsDataURL(file)
      }
    });
  };

  const _downloadFile = (stipulationID) => {
    return _downloadStipulation(loan.guid, stipulationID).then(response => {
      const data = response.action.payload.data;
      let file = new File([Utils.convertDataURIToBinaryFF("data:application/pdf;base64," + data.content)], `${data.name}`, {type: data.mimeType});
      FileSaver.saveAs(file);
      return data;
    }).catch(err => {
      console.log(err)
    })
  };

  const _removeFile = (stipulationID) => {
    _removeStipulation(loan.guid, stipulationID).then(() => {
      _getLoanStipulations(loan.guid);
      _getUserStipulations(loan.guid);
    }).catch(err => {
      console.log(err);
    })
  };

  const _selectBid = (bidID) => {
    _selectLoanBid(loan.guid, bidID).then(() => {
      _getLoanBids(loan.guid);
    }).catch(err => {
      console.log(err);
    })
  };

  const loadingCompleted = loanBids.isCompleted && loanStipulations.isCompleted;
  const hasBids = loanBids.isCompleted && loanBids.list.length > 0;
  const hasStips = loanStipulations.isCompleted && loanStipulations.list.length > 0;
  const hasSelectedWinningBid = (loanBids.list || []).some(bid => bid.isWinningBid === true);
  const filteredBids = hasSelectedWinningBid ? (loanBids.list || []).filter(bid => bid.isWinningBid === true) : (loanBids.list || []);
  let headerMessage = "";
  let welcomeMessage1 = "";
  let welcomeMessage2 = "";
  if (loadingCompleted === false) {
    welcomeMessage1 = "";
    welcomeMessage2 = "";
  } else {
    if (hasBids === false && hasStips === false) {
      headerMessage = "Our finance manager will contact you right now to complete the loan application.";
      welcomeMessage1 = "After the loan application is complete, you will return here to select your preferred lender and upload your documents.";
      welcomeMessage2 = "We will notify you by email when your lending proposals have arrived.";
    } else if (hasBids === true && hasStips === false) {
      headerMessage = "Please see below for your next step:";
      welcomeMessage1 = hasSelectedWinningBid ? "You have selected your preferred lender." : "You can now select your preferred lender.";
      welcomeMessage2 = "You will need to return here to upload any documents when required.";
    } else if (hasBids === false && hasStips === true) {
      headerMessage = "Please see below for your next step:";
      welcomeMessage1 = "Please upload any required documents below.";
      welcomeMessage2 = "We will notify you by email when your lending proposals have arrived.";
    } else if (hasBids === true && hasStips === true) {
      headerMessage = "Please see below for your next step:";
      welcomeMessage1 = hasSelectedWinningBid ? "You have selected your preferred lender, now please upload any required documents below." : "You can now select your preferred lender and upload any required documents below.";
      welcomeMessage2 = "";
    }
  }

  return (
    <div className="bg-teal-100 h-full min-h-screen w-full">
      <div className="container flex flex-col h-full items-start mx-auto pt-16 pl-4 pr-4">
        <h1 className="font-sans font-bold text-gray-800 tracking-wide text-3xl mb-6">
          Welcome! <br />
          {headerMessage}
        </h1>
        {
          loanBids.isCompleted && <>
            <p className="font-sans font-normal text-gray-400 text-l">
              {welcomeMessage1}
            </p>
            <p className="font-sans font-normal text-gray-400 text-l">
              {welcomeMessage2}
            </p>
          </>
        }
        <div className="mt-8 w-full">
          {isSelectingBid === false ? (
            loanBids.isCompleted && filteredBids.map((bid, index) => {
              return (
                <LoansItemComponent
                  key={index}
                  bidID={bid.bidID}
                  isWinningBid={bid.isWinningBid}
                  hasSelectedWinningBid={hasSelectedWinningBid}
                  isCardVisible={bid.dateReceived !== undefined}
                  name={bid.lenderName}
                  img={process.env.PUBLIC_URL + "/assets/illustrations/lender_placeholder.svg"}
                  rating={0}
                  company={bid.lenderCompany}
                  email={bid.lenderEmail}
                  phone={bid.lenderPhone}
                  loanName={bid.loanClass + " " + bid.loanType + " " + (bid.loanTerm / 12) + " Years"}
                  loanDetails="Detailed Lending Estimate"
                  apr={(bid.interestRate * 100.0).toFixed(3)}
                  discountPoints={(bid.discountPoints * 100.0).toFixed(3)}
                  totalFees={Utils.formatMoney(bid.loanFee + bid.originationFee + bid.lenderFee + bid.brokerFee + bid.otherFees)}
                  cashForClosing={bid.closingCash ? Utils.formatMoney(bid.closingCash) : null}
                  loanAmount={bid.loanAmount ? Utils.formatMoney(bid.loanAmount) : null}
                  selectBid={_selectBid}
                />
              )
            })
          ) : (
            <div className="flex flex-col items-center p-8 w-full">
              <img alt="Please wait..."
                   className="inline-block h-6 w-6 mr-2"
                   src={process.env.PUBLIC_URL + "/assets/images/spinner.gif"}
              />
              <p className="inline-block font-sans font-normal text-gray-400 text-xl">
                Please wait...
              </p>
            </div>
          )}
        </div>
        {
          loanStipulations.isCompleted && loanStipulations.list.length > 0 && <>
            <h2 className="font-sans font-bold text-gray-800 tracking-wide text-xl pt-12">
              Upload your documents
            </h2>
            <div className="bg-white mb-16 mt-8 overflow-hidden pb-8 pt-4 rounded-lg shadow-lg w-full">
              <div className="px-8 py-4">
                {
                  loanStipulations.isCompleted && loanStipulations.list.map((stip, index) => {
                    return (
                      <React.Fragment key={index}>
                        {
                          index > 0 ? (
                            <div className="border-t-2 border-gray-400 pt-6 mt-6">
                              <div className="font-sans font-medium leading-relaxed text-gray-800 text-base">
                                {stip.name}
                              </div>
                              <div className="font-sans leading-relaxed text-gray-400 text-base">
                                {stip.description}
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="font-sans font-medium leading-relaxed text-gray-800 text-base">
                                {stip.name}
                              </div>
                              <div className="font-sans leading-relaxed text-gray-400 text-base">
                                {stip.description}
                              </div>
                            </>
                          )}
                        {
                          userStipulations.isCompleted && userStipulations.list.filter(s => s.stipulationDefinitionID === stip.stipulationDefinitionID).map((userStip) => {
                            return (
                              <div key={userStip.userStipulationID} className="flex flex-row items-center justify-between mt-4 w-full">
                                <div>
                                  <Icon icon={userStip.mimeType === "image/jpeg" ? "jpg" : (userStip.mimeType === "image/png" ? "png" : "pdf")} />
                                  <p className="inline-block font-sans font-normal text-teal-500 text-base">
                                    {userStip.name}
                                  </p>
                                  <Icon className="inline-block ml-2 h-16 w-16" icon="check" />
                                </div>
                                <div>
                                  <button className="inline-block font-sans font-normal mr-6 text-gray-600 text-xs cursor-pointer" onClick={() => _downloadFile(userStip.userStipulationID)}>
                                    Preview
                                  </button>
                                  <button className="inline-block font-sans font-normal text-gray-600 text-xs cursor-pointer" onClick={() => _removeFile(userStip.userStipulationID)}>
                                    Remove
                                  </button>
                                </div>
                              </div>
                            )
                          })}
                        {
                          uploadList && !uploadList.includes(stip.guid) ? (
                            <Dropzone accept={"image/jpeg, image/png, application/pdf"} onDrop={acceptedFiles => _filesDropped(stip, acceptedFiles)}>
                              {({getRootProps, getInputProps}) => (
                                <div className="cursor-pointer bg-teal-100 mt-6 overflow-hidden rounded-lg w-full" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <div className="flex flex-col items-center p-8 w-full">
                                    <p className="font-sans font-normal text-gray-600 text-base">
                                      <Icon icon="clip" />
                                      <span className="text-teal-500">Select a file&nbsp;</span>
                                      <span>or drag and drop here</span>
                                    </p>
                                    <p className="font-sans font-normal text-gray-600 text-xs">
                                      jpg, png or pdf format accepted
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          ) : (
                            <div className="bg-teal-100 mt-6 overflow-hidden rounded-lg w-full">
                              <div className="flex flex-col items-center p-8 w-full">
                                <p className="font-sans font-normal text-gray-600 text-base"/>
                                <img alt="Uploading, please wait"
                                     className="inline-block h-6 w-6 mr-2"
                                     src={process.env.PUBLIC_URL + "/assets/images/spinner.gif"}
                                />
                                <p className="inline-block font-sans font-normal text-teal-500 text-base">
                                  Uploading, please wait...
                                </p>
                              </div>
                            </div>
                          )}
                      </React.Fragment>
                    )
                  })}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
};

export default DocumentsView;
