import React from "react";
import axios from "axios";
import AuthUtils from "./util/auth";
import PrivateRoute from "./gatekeepers/_auth";
import {Route, Router, Switch} from "react-router";
import {Spinner} from "./components";
import {ToastProvider} from "react-toast-notifications";
import {WithIntroLayout, WithWizardLayout} from "./layouts";
import {createBrowserHistory} from "history";
import {useSelector} from "react-redux";
import {version} from "../package.json";
import * as _views from "./views";

export const history = createBrowserHistory();
window.appHistory = history;

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401 && error.response.config.url.indexOf("/token") === -1) {
    // TODO: handle a 401 error
    AuthUtils._clearAuthRelatedLocalStorage();
    window.location = "/"
  }
  return Promise.reject(error);
});

const App = () => {
  const isSpinnerOn = useSelector( state => state.spinnerStore.isSpinnerOn);

  return (
    <ToastProvider>
      <main className="h-full leading-none m-0 min-h-screen w-screen">
        {
          isSpinnerOn ? <Spinner /> : <React.Fragment>
            <Router history={history}>
              <Switch>
                <Route exact path="/" render={() => _views.LoginView} />
                <Route exact path="/login" render={() => _views.LoginView} />
                <Route exact path="/link" component={_views.LinkAuth} />
                <Route exact path="/docs" component={_views.DocumentsView} />

                {
                  /*
                  <Route exact path="/loans" component={withWizardLayout(LoansView)} />
                  */
                }

                <Route exact path="/logout" component={_views.LogoutView} />
                <Route exact path="/set-password" render={() => _views.SetPasswordView} />

                {
                  /*
                  <Route exact path="/register" component={RegisterView} />
                  */
                }

                <PrivateRoute path="/intro_welcome"
                              render={() => WithIntroLayout(_views.IntroWelcome)} />
                <PrivateRoute path="/intro_loan_purpose/:edit?"
                              render={() => WithIntroLayout(_views.IntroLoanPurpose)} />
                <PrivateRoute path="/intro_borrow_amount/:edit?"
                              render={() => WithIntroLayout(_views.IntroLoanAmount)} />
                <PrivateRoute path="/intro_loan_term/:edit?"
                              render={() => WithIntroLayout(_views.IntroLoanTerm)} />
                <PrivateRoute path="/intro_occupy_intend/:edit?"
                              render={() => WithIntroLayout(_views.IntroResidenceType)} />
                <PrivateRoute path="/intro_nr_applicants/:edit?"
                              render={() => WithIntroLayout(_views.IntroNumberOfApplicants)} />
                <PrivateRoute path="/intro_applicant_details/:edit?/:applicant?"
                              render={() => WithIntroLayout(_views.IntroApplicantDetails)} />
                <PrivateRoute path="/intro_summary"
                              render={() => WithIntroLayout(_views.IntroSummary)} />

                <PrivateRoute path="/contact_details/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.ContactDetails)} />
                <PrivateRoute path="/contact_summary/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.ContactSummary)} />

                <PrivateRoute path="/personal_current_address_main/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalCurrentAddressMain)} />
                <PrivateRoute path="/personal_current_address_monthly_expenses/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalCurrentAddressMonthlyExpenses)} />
                <PrivateRoute path="/personal_current_address_ownership/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalCurrentAddressOwnership)} />
                <PrivateRoute path="/personal_current_address_years_at/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalCurrentAddressYearsAt)} />
                <PrivateRoute path="/personal_former_address_main/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalFormerAddressMain)} />
                <PrivateRoute path="/personal_former_address_monthly_expenses/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalFormerAddressMonthlyExpenses)} />
                <PrivateRoute path="/personal_former_address_ownership/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalFormerAddressOwnership)} />
                <PrivateRoute path="/personal_former_address_years_at/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalFormerAddressYearsAt)} />
                <PrivateRoute path="/personal_mailing_address_main/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalMailingAddressMain)} />
                <PrivateRoute path="/personal_mailing_address_question/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalMailingAddressQuestion)} />
                <PrivateRoute path="/personal_mailing_address_years_at/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalMailingAddressYearsAt)} />
                <PrivateRoute path="/personal_military_status/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PersonalMilitaryStatus)} />
                <PrivateRoute path="/personal_summary/:edit/:applicant"
                              render={() => WithWizardLayout(_views.PersonalSummary)} />

                <PrivateRoute path="/employment_question/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.EmploymentQuestion)} />
                <PrivateRoute path="/employment_main/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.EmploymentMain)} />
                <PrivateRoute path="/employment_start_date/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.EmploymentStartDate)} />
                <PrivateRoute path="/employment_percent_ownership/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.PercentOwnershipQuestion)} />
                <PrivateRoute path="/employment_monthly_earnings/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.EmploymentMonthlyEarnings)} />
                <PrivateRoute path="/employment_multiple_employments_question/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.EmploymentMultipleEmploymentsQuestion)} />
                <PrivateRoute path="/employment_misc_income/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.EmploymentMiscIncome)} />
                <PrivateRoute path="/employment_summary/:edit/:applicant"
                              render={() => WithWizardLayout(_views.EmploymentSummary)} />

                <PrivateRoute path="/debts_permission_question/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DebtsPermissionQuestion)} />
                <PrivateRoute path="/debts_permission_feedback/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DebtsPermissionFeedback)} />
                <PrivateRoute path="/debts_ssn_info/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DebtsSSNInfo)} />
                <PrivateRoute path="/debts_other_obligations/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DebtsOtherObligations)} />
                <PrivateRoute path="/debts_summary/:edit/:applicant"
                              render={() => WithWizardLayout(_views.DebtsSummary)} />

                <PrivateRoute path="/declarations_statements_first/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DeclarationsStatementsFirst)} />
                <PrivateRoute path="/declarations_statements_second/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DeclarationsStatementsSecond)} />
                <PrivateRoute path="/declarations_summary/:edit/:applicant"
                              render={() => WithWizardLayout(_views.DeclarationsSummary)} />

                <PrivateRoute path="/hmda_ethnicity/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.HMDAEthnicityInfo)} />
                <PrivateRoute path="/hmda_gender/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.HMDAGenderInfo)} />
                <PrivateRoute path="/hmda_race/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.HMDARaceInfo)} />
                <PrivateRoute path="/hmda_summary/:edit/:applicant"
                              render={() => WithWizardLayout(_views.HMDASummary)} />

                <PrivateRoute path="/down_payment_selection/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DownPaymentSelection)} />
                <PrivateRoute path="/down_payment_amount/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DownPaymentAmount)} />
                <PrivateRoute path="/down_payment_gifted_question/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DownPaymentGiftedQuestion)} />
                <PrivateRoute path="/down_payment_assisted_question/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DownPaymentAssistedQuestion)} />
                <PrivateRoute path="/down_payment_stated_bank_count/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DownPaymentStatedBankCount)} />
                <PrivateRoute path="/down_payment_bank_accounts/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DownPaymentBankAccounts)} />
                <PrivateRoute path="/down_payment_closing_costs/:edit?/:applicant?"
                              render={() => WithWizardLayout(_views.DownPaymentClosingCosts)} />

                <PrivateRoute path="/submit_loan"
                              render={() => WithWizardLayout(_views.SubmitLoanView)} />

                <Route exact path="/loan-plans" component={_views.LoanPlansView} />

                <Route exact path="/referred-to-finance" component={_views.ReferredToFinanceView} />

                <Route component={_views.RouteNotMatched} />
              </Switch>
            </Router>
            <span className="fixed right-0 bottom-0 pb-2 pr-2 font-normal text-sm text-gray-400">
              v{version}
            </span>
          </React.Fragment>
        }
      </main>
    </ToastProvider>
  );
};

export default App;
