import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {MODEL_SET_LOAN_TERM_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const IntroLoanTerm = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveLoanTerm = useCallback((value) => dispatch(MODEL_SET_LOAN_TERM_ACTION(value)), [dispatch]);

  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const loanTermYears = useSelector( state => state.wizardStore.meta.loanTermYears);

  const _submit = (key, value) => {
    saveLoanTerm(value);
    navigateNext(inEditMode ? "intro_summary" : "intro_occupy_intend");
  };

  return (
    <CardComponent hasProgress={true} title="What is the term (in years) of loan you would like?">
      <OptionsComponent onClick={_submit} options={loanTermYears} />
    </CardComponent>
  )
};

export default IntroLoanTerm;
