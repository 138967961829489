// Main Auth Constants
export const [
  AUTH_CHECK_USER_EXISTENCE,
  AUTH_CHECK_USER_EXISTENCE_PENDING,
  AUTH_CHECK_USER_EXISTENCE_FULFILLED,
  AUTH_CHECK_USER_EXISTENCE_REJECTED,
  AUTH_SET_IS_AUTHENTICATED
] = [
  "AUTH_CHECK_USER_EXISTENCE",
  "AUTH_CHECK_USER_EXISTENCE_PENDING",
  "AUTH_CHECK_USER_EXISTENCE_FULFILLED",
  "AUTH_CHECK_USER_EXISTENCE_REJECTED",
  "AUTH_SET_IS_AUTHENTICATED"
];
