import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {SUBMIT_LOAN_ACTION} from "../../../redux/actions";
import {ButtonComponent, CardComponent} from "../../../components";

const SubmitLoanView = () => {
  const dispatch = useDispatch();
  const submitLoan = useCallback(() => dispatch(SUBMIT_LOAN_ACTION()), [dispatch]);

  return (
    <CardComponent title="Ready to submit the loan application?">
      <p className="cp-font-r18w400g500 mb-1 md:mb-4 text-justify">
        The next step will be to submit the loan application, then review and select which type of Loan you would like to proceed with.
      </p>
      <ButtonComponent onClick={submitLoan} text="Continue" />
    </CardComponent>
  );
};

export default SubmitLoanView;
