import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {CONTACT_DETAILS_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, ErrorsComponent, PhoneComponent, TextBoxComponent} from "../../../components";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  homePhone: Yup.string()
    .min(10, "Home phone is invalid."),
  cellPhone: Yup.string()
    .min(10, "Cell phone is invalid.")
    .required("Cell phone is required."),
  workPhone: Yup.string()
    .min(10, "Work phone is invalid."),
  workPhoneExtension: Yup.string(),
  emailAddress: Yup.string()
    .email("Invalid email address.")
    .required("Email is required.")
});

const ContactDetails = () => {
  const dispatch = useDispatch();
  const saveDetails = useCallback((data) => dispatch(CONTACT_DETAILS_ACTION(data)), [dispatch]);
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const totalApplicants = useSelector( state => state.wizardStore.meta.totalApplicants);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  let { cellPhone, homePhone, workPhone, workPhoneExtension, emailAddress } = applicant.contactInfo ? applicant.contactInfo : {};

  const isLinkAuthRedirected = useSelector( state => state.authStore.linkAuth.hasPerformed);
  const linkAuthEmail = useSelector( state => state.authStore.linkAuth.email);
  emailAddress = isLinkAuthRedirected && linkAuthEmail && applicantIndex === 0 ? linkAuthEmail : emailAddress;

  return (
    <Formik
      initialValues={{ cellPhone, homePhone, workPhone, workPhoneExtension, emailAddress }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        saveDetails(values);
        resetForm({});
        setSubmitting(true);
        if (inEditMode) {
          navigateNext("contact_summary", false, applicantIndex);
        } else {
          if (applicantIndex < parseInt(totalApplicants.toString()) - 1) {
            navigateNext("contact_details", false, applicantIndex + 1);
          } else {
            navigateNext("contact_summary", false, applicantIndex);
          }
        }
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent hasProgress={true} title={`${applicant.firstName}'s Contact Information`}>
          <PhoneComponent
            label="Cell Phone"
            name="cellPhone"
            onChange={handleChange}
            value={values.cellPhone}
          />
          <PhoneComponent
            label="Home Phone"
            name="homePhone"
            onChange={handleChange}
            value={values.homePhone}
          />
          <PhoneComponent
            label="Work Phone"
            name="workPhone"
            onChange={handleChange}
            value={values.workPhone}
          />
          <TextBoxComponent
            label="Work Phone Extension"
            name="workPhoneExtension"
            onChange={handleChange}
            value={values.workPhoneExtension}
          />
          <TextBoxComponent
            label="Email Address"
            name="emailAddress"
            onChange={handleChange}
            value={values.emailAddress}
          />
          {
            errors && (touched.cellPhone || touched.homePhone || touched.workPhone ||
              touched.workPhoneExtension || touched.emailAddress) && <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  );
};

export default ContactDetails;
