import React from "react";
import {useSelector} from "react-redux";
import {Spinner} from "../../components";

const ReferredToFinanceView = () => {
  const isSpinnerOn = useSelector( state => state.spinnerStore.isSpinnerOn);

  return (
    isSpinnerOn ? <Spinner /> : <div className="flex flex-grow h-screen items-center justify-center">
      <h1 className="font-sans font-medium text-teal-500 tracking-wide text-4xl mb-2">
        Excellent work! <br />
        Next Steps are for the finance manager to contact you.
      </h1>
    </div>
  );
};

export default ReferredToFinanceView;
