import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {DOWN_PAYMENT_ASSISTED_QUESTION_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const DownPaymentAssistedQuestion = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveAnswer = useCallback((answer) => dispatch(DOWN_PAYMENT_ASSISTED_QUESTION_ACTION(answer)), [dispatch]);

  const downPayment = useSelector( state => state.wizardStore.model.downPayment);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const yesNoAnswerOptions = useSelector( state => state.wizardStore.meta.yesNoAnswerOptions);
  const applicantIndex = parseInt(currentApplicant.toString());

  const _clickHandler = (key, value) => {
    saveAnswer(value);
    if (downPayment && (downPayment.isGifted || downPayment.isAssisted)) {
      navigateNext("down_payment_stated_bank_count", false, applicantIndex);
    } else {
      navigateNext("down_payment_closing_costs", false, applicantIndex);
    }
  };

  return (
    <CardComponent title="Is any part of the down payment provided through government assistance programs?">
      <OptionsComponent onClick={_clickHandler} options={yesNoAnswerOptions} />
    </CardComponent>
  )
};

export default DownPaymentAssistedQuestion;
