import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {CardComponent, ErrorsComponent, PhoneComponent, TextBoxComponent} from "../../components";
import {AUTH_CHECK_AUTH_ACTION, CONSUMER_REGISTER_ACTION} from "../../redux/actions";
import {Link} from "react-router-dom";
import WithGradientLayout from "../../layouts/_withGradientLayout";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  phone: Yup.string()
});

const RegisterView = () => {
  const dispatch = useDispatch();
  const checkAuthStatus = useCallback(() => dispatch(AUTH_CHECK_AUTH_ACTION()), [dispatch]);
  const registerCustomer = useCallback((fName, lName, email, phone) => dispatch(CONSUMER_REGISTER_ACTION(fName, lName, email, phone)), [dispatch]);

  const inProgress = useSelector( state => state.authStore.consumerRegister.inProgress);
  const hasErrors = useSelector( state => state.authStore.consumerRegister.hasErrors);
  const registerErrors = useSelector( state => state.authStore.consumerRegister.errors);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  return (
    <div className="container flex flex-col h-full items-start md:px-8 min-h-screen mx-auto px-4">
      <img
        alt="Intro Lend Logo"
        className="flex-none mb-16 mt-6"
        src={process.env.PUBLIC_URL + "/assets/logo/logo.svg"}
      />
      <div className="flex flex-col flex-grow md:flex-row h-full w-full">
        <div className="flex flex-col flex-grow md:w-1/2 md:pb-0 pb-16">
          <div className="cp-page-title">
            Get personalized loan proposals in no time!
          </div>
          <div className="cp-page-subtitle">
            You are a few steps away from receiving multiple loan proposals, all personalized for you! <br />
            Get started by logging in!
          </div>

          <Formik
            initialValues={{ firstName: "", lastName: "", email: "", phone: "" }}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              registerCustomer(values.firstName, values.lastName, values.email, values.phone);
              resetForm({});
              setSubmitting(false);
            }}
            render={({values, errors, touched, handleChange, handleSubmit }) => (
              <CardComponent title="Create account">
                <TextBoxComponent
                  label="First name:"
                  name="firstName"
                  onChange={handleChange}
                  placeholder="Type your first name"
                  value={values.firstName}
                />
                <TextBoxComponent
                  label="Last name:"
                  name="lastName"
                  onChange={handleChange}
                  placeholder="Type your last name"
                  value={values.lastName}
                />
                <TextBoxComponent
                  label="Email address:"
                  name="email"
                  onChange={handleChange}
                  placeholder="Type your email"
                  value={values.email}
                />
                <PhoneComponent
                  label="Phone number (optional):"
                  name="phone"
                  onChange={handleChange}
                  placeholder=""
                  value={values.phone}
                />
                {
                  errors && touched.firstName && touched.lastName && touched.email &&
                  <ErrorsComponent errorsObj={errors} />
                }
                {
                  hasErrors && <div className="flex flex-col cp-error">{registerErrors}</div>
                }
                <button className={`cp-auth-btn ${inProgress ? "is-loading" : ""}`} onClick={handleSubmit} type="submit">
                  Register
                </button>
              </CardComponent>
            )}
            validationSchema={validationSchema}
          />
          <div className="cp-paragraph">
            Already have an account?&nbsp;
            <Link className="cp-link-teal" to="/">Login</Link>
          </div>
        </div>
        <div className="flex flex-col flex-grow justify-end md:mx-4 md:w-1/2">
          <img
            alt="Customer Portal Illustration"
            src={process.env.PUBLIC_URL + "/assets/illustrations/selector.svg"}
          />
        </div>
      </div>
    </div>
  );
};

export default WithGradientLayout(RegisterView);
