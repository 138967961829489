import moment from "moment";

const BASE64_MARKER = ";base64,";

export default class Utils {

  static formatBool(bool) {
    return bool ? "Yes" : "No";
  }

  static formatMoney(cb, noOpt) {
    const def = noOpt ? {minimumFractionDigits: 0} : {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    return cb.toLocaleString("en-EN", def);
  }

  static formatDate(timestamp, format = "YYYY-MM-DD") {
    return moment.utc(timestamp).local().format(format)
  }

  static convertDataURIToBinaryFF(dataURI) {
    let base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    let raw = window.atob(dataURI.substring(base64Index));
    return Uint8Array.from(Array.prototype.map.call(raw, function(x) {
      return x.charCodeAt(0);
    }));
  }
}
