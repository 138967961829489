import React, {useCallback, useEffect} from "react";
import Selector from "@haiku/cosmin-selector/react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {CardComponent, ErrorsComponent, PasswordComponent, TextBoxComponent} from "../../components";
import {
  AUTH_DECIDE_REDIRECT_ACTION,
  AUTH_CHECK_AUTH_ACTION,
  AUTH_LOGIN_ACTION,
  AUTH_SET_LINK_AUTH_OBJECT_ACTION
} from "../../redux/actions";
import WithGradientLayout from "../../layouts/_withGradientLayout";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().required("Password is required")
});

const LoginView = () => {
  const dispatch = useDispatch();
  const checkAuthStatus = useCallback(() => dispatch(AUTH_CHECK_AUTH_ACTION()), [dispatch]);
  const login = useCallback((email, pass) => dispatch(AUTH_LOGIN_ACTION(email, pass)), [dispatch]);
  const setLinkAuthData = useCallback((obj) => dispatch(AUTH_SET_LINK_AUTH_OBJECT_ACTION(obj)), [dispatch]);
  const decideRedirect = useCallback(() => dispatch(AUTH_DECIDE_REDIRECT_ACTION()), [dispatch]);

  const isLinkAuthRedirected = useSelector( state => state.authStore.linkAuth.hasPerformed);
  const linkAuthEmail = useSelector( state => state.authStore.linkAuth.email);
  const isAuthenticated = useSelector( state => state.authStore.main.isAuthenticated);
  const inProgress = useSelector( state => state.authStore.loginAuth.inProgress);
  const authErrors =  useSelector( state => state.authStore.loginAuth.errors);
  const emailValue = isLinkAuthRedirected && linkAuthEmail ? linkAuthEmail : "";

  useEffect(() => {
    checkAuthStatus();
    if (isAuthenticated) {
      decideRedirect();
    }
  }, [checkAuthStatus, decideRedirect, isAuthenticated]);

  return (
    <div className="container flex flex-col h-full items-start md:px-8 min-h-screen mx-auto px-4">
      <img
        alt="Intro Lend Logo"
        className="flex-none mb-16 mt-6"
        src={process.env.PUBLIC_URL + "/assets/logo/logo.svg"}
      />
      <div className="flex flex-col flex-grow md:flex-row h-full w-full">
        <div className="flex flex-col flex-grow md:w-1/2 md:pb-0 pb-16">
          <div className="cp-page-title">
            Get personalized loan proposals in no time!
          </div>
          <div className="cp-page-subtitle">
            You are a few steps away from receiving multiple loan proposals, all personalized for you! <br />
            Get started by logging in!
          </div>

          <Formik
            initialValues={{ email: emailValue, password: "" }}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              if (isLinkAuthRedirected && linkAuthEmail !== values.email) {
                setLinkAuthData({isNotTheSameAsLoggedOne: true});
              }
              login(values.email, values.password);
              resetForm({});
              setSubmitting(false);
            }}
            render={({values, errors, touched, handleChange, handleSubmit }) => (
              <CardComponent title="Sign in">
                <TextBoxComponent
                  keyId="auth-email"
                  label="Email address:"
                  name="email"
                  onChange={handleChange}
                  placeholder="Type your email"
                  value={values.email}
                />
                <PasswordComponent
                  keyId="auth-password"
                  label="Password:"
                  name="password"
                  onChange={handleChange}
                  placeholder="Type your password"
                  value={values.password}
                />
                {
                  errors && touched.email && touched.password && <ErrorsComponent errorsObj={errors} />
                }
                {
                  authErrors && <div className="flex flex-col cp-error">{authErrors}</div>
                }
                <button
                  className={`cp-auth-btn ${inProgress ? "is-loading" : ""}`}
                  onClick={handleSubmit}
                  type="submit"
                >
                  Sign In
                </button>
              </CardComponent>
            )}
            validationSchema={validationSchema}
          />
          {
            /*
            <div className="cp-paragraph">
              Don't have an account?&nbsp;
              <Link className="cp-link-teal" to="/register">Sign up</Link>
            </div>
            */
          }
        </div>

        <div className="flex flex-col flex-grow justify-end md:mx-4 md:w-1/2">
          <div className="h-auto w-auto">
            <Selector loop={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithGradientLayout(LoginView);
