import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {DEBTS_OTHER_OBLIGATIONS_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, CurrencyComponent, ErrorsComponent} from "../../../components";
import {YupCurrency} from "../../../util/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  alimony: YupCurrency("Alimony"),
  childSupport: YupCurrency("Child Support"),
  judgements: YupCurrency("Judgements"),
  privateLoans: YupCurrency("Private Loans")
});

const DebtsOtherObligations = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveObligations = useCallback((data) => dispatch(DEBTS_OTHER_OBLIGATIONS_ACTION(data)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const {alimony, childSupport, judgements, privateLoans} = applicant && applicant.otherDebts ? applicant.otherDebts : {};

  return (
    <Formik
      initialValues={{ alimony, childSupport, judgements, privateLoans }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        saveObligations(values);
        resetForm({});
        setSubmitting(false);
        navigateNext("debts_summary", false, currentApplicant);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent title={`Please enter any of the following monthly obligations that ${applicant.firstName}'s has to pay.`}>
          <CurrencyComponent
            label="Alimony"
            name="alimony"
            onChange={handleChange}
            value={values.alimony}
          />
          <CurrencyComponent
            label="Child Support"
            name="childSupport"
            onChange={handleChange}
            value={values.childSupport}
          />
          <CurrencyComponent
            label="Judgements"
            name="judgements"
            onChange={handleChange}
            value={values.judgements}
          />
          <CurrencyComponent
            label="Private Loans"
            name="privateLoans"
            onChange={handleChange}
            value={values.privateLoans}
          />
          {
            errors && (touched.alimony || touched.childSupport || touched.judgements || touched.privateLoans) &&
            <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  );
};

export default DebtsOtherObligations;
