// Login Auth Constants
export const [
  AUTH_LOGIN,
  AUTH_LOGIN_PENDING,
  AUTH_LOGIN_FULFILLED,
  AUTH_LOGIN_REJECTED,
  UPDATE_AUTH_STATE,
  UPDATE_USER_EXISTS_IN_DB
] = [
  "AUTH_LOGIN",
  "AUTH_LOGIN_PENDING",
  "AUTH_LOGIN_FULFILLED",
  "AUTH_LOGIN_REJECTED",
  "UPDATE_AUTH_STATE",
  "UPDATE_USER_EXISTS_IN_DB"
];
