import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {MODEL_SET_LOAN_AMOUNT_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, CurrencyComponent, ErrorsComponent} from "../../../components";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  loanAmount: Yup.number()
    .positive("Amount value must be a positive number.")
    .min(50000,"Amount must be greater than or equal to $50,000.")
    .required("Amount value is required in order to continue.")
    .typeError("Amount value must be a number, just digits, no comma or $.")
});

const IntroLoanAmount = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const saveAmount = useCallback((amount) => dispatch(MODEL_SET_LOAN_AMOUNT_ACTION(amount)), [dispatch]);

  const loanAmount = useSelector( state => state.wizardStore.model.loanAmount);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);

  return (
    <Formik
      initialValues={{ loanAmount: loanAmount}}
      onSubmit={(values, { setSubmitting }) => {
        saveAmount(parseInt(values.loanAmount, 10));
        navigateNext(inEditMode ? "intro_summary" : "intro_loan_term");
        setSubmitting(false);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent hasProgress={true} title="How much do you want to borrow?">
          <CurrencyComponent
            label="Amount"
            name="loanAmount"
            onChange={handleChange}
            value={values.loanAmount}
          />
          {
            errors.loanAmount && touched.loanAmount && <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={validationSchema}
    />
  );
};

export default IntroLoanAmount;
