import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import {PERSONAL_CURRENT_ADDRESS_MONTHLY_EXPENSES_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";
import {CardComponent, CurrencyComponent, ErrorsComponent} from "../../../components";
import {YupMonthlyCost} from "../../../util/yup";

const PersonalCurrentAddressMonthlyExpenses = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setMonthlyExpense = useCallback((value) => dispatch(PERSONAL_CURRENT_ADDRESS_MONTHLY_EXPENSES_ACTION(value)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const applicantStreet = applicant && applicant.currentAddress ? applicant.currentAddress.street || "" : "";
  const { monthlyCost } = applicant && applicant.currentAddress && applicant.currentAddress.monthlyCost ?
    applicant.currentAddress.monthlyCost : 0;

  return (
    <Formik
      initialValues={{ monthlyCost }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        setMonthlyExpense(parseInt(values.monthlyCost, 10));
        resetForm({});
        setSubmitting(false);
        navigateNext("personal_current_address_years_at", false, applicantIndex);
      }}
      render={({values, errors, touched, handleChange, handleSubmit }) => (
        <CardComponent title={`What is the monthly expense for ${applicant.firstName}'s mortgage/rent at ${applicantStreet}?`}>
          <CurrencyComponent
            label="Monthly Cost"
            name="monthlyCost"
            onChange={handleChange}
            value={values.monthlyCost}
          />
          {
            errors && touched.monthlyCost && <ErrorsComponent errorsObj={errors} />
          }
          <button className="cp-btn-teal" onClick={handleSubmit} type="submit">
            Continue
          </button>
        </CardComponent>
      )}
      validationSchema={YupMonthlyCost}
    />
  )
};

export default PersonalCurrentAddressMonthlyExpenses;
