import RUF from "../../../util/redux";

export function personalMailingAddressMain(state, action) {
  const currentIndex = parseInt(state.meta.currentApplicant);

  return RUF.updateObject(state, {
    model: RUF.updateObject(state.model, {
      applicants: [
        ...state.model.applicants.slice(0, currentIndex),
        Object.assign({}, state.model.applicants[currentIndex], {
          mailingAddress: action.payload
        }),
        ...state.model.applicants.slice(currentIndex+1)
      ]
    })
  });
}
