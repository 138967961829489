import {
  META_SET_CURRENT_APPLICANT,
  META_SET_CURRENT_EMPLOYMENT_INDEX,
  META_SET_CURRENT_STATE,
  META_SET_EDIT_MODE,
  META_SET_PREVIOUS_STATE,
  META_SET_TOTAL_APPLICANTS
} from "../../constants/loan/_meta_constants";
import {SAVE_LOAN_ACTION} from "./_submit_actions";

export const META_SET_CURRENT_APPLICANT_ACTION = (applicant) => ({
  type: META_SET_CURRENT_APPLICANT,
  payload: applicant
});

export const META_SET_CURRENT_EMPLOYMENT_INDEX_ACTION = (index) => ({
  type: META_SET_CURRENT_EMPLOYMENT_INDEX,
  payload: index
});

export const META_SET_CURRENT_STATE_ACTION = (state) => ({
  type: META_SET_CURRENT_STATE,
  payload: state
});

export const META_SET_EDIT_MODE_ACTION = (flag) => ({
  type: META_SET_EDIT_MODE,
  payload: flag
});

export const META_SET_PREVIOUS_STATE_ACTION = (state) => ({
  type: META_SET_PREVIOUS_STATE,
  payload: state
});

export const META_SET_TOTAL_APPLICANTS_ACTION = (number) => ({
  type: META_SET_TOTAL_APPLICANTS,
  payload: number
});

export function SET_NEXT_PAGE(state, edit, applicant) {
  return (dispatch) => {
    //dispatch(SAVE_LOAN_ACTION());
    const applicantPath = applicant != null ? `/${applicant}` : "";
    const editPath = edit != null ? `/${edit}${applicantPath}` : applicantPath;
    const nextPath = `/${state}${editPath}`;
    window.appHistory.push(nextPath);
    if (state != null) {
      dispatch(META_SET_CURRENT_STATE_ACTION(state));
    }
    if (applicant != null) {
      dispatch(META_SET_CURRENT_APPLICANT_ACTION(parseInt(applicant)));
    }
    if (edit != null) {
      dispatch(META_SET_EDIT_MODE_ACTION((edit === "true" || edit) ? true : false));
    }
  };
}
