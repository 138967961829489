import React, {useEffect, useState} from "react";
import Pagination from "../pagination";
import {BackButton, NextButton} from "../button";
import {Form} from "formik";
import {useSpring, animated} from "react-spring";
import {withRouter} from "react-router";

const CardComponent = ({children, history, location, title}) => {
  const [activeIndicator, setActiveIndicator] = useState( 1);
  const [showProgress, setShowProgress] = useState( false);

  useEffect(() => {
    // we check to see if route says we are in intro section and need to show progress indicators
    if (location && location.pathname && location.pathname.startsWith("/intro")) {
      const path = location.pathname;
      let index;

      switch (path) {
        case (path.match(/^\/intro_loan_purpose/) || {}).input:       index = 1; break;
        case (path.match(/^\/intro_borrow_amount/) || {}).input:      index = 2; break;
        case (path.match(/^\/intro_loan_term/) || {}).input:          index = 3; break;
        case (path.match(/^\/intro_occupy_intend/) || {}).input:      index = 4; break;
        case (path.match(/^\/intro_nr_applicants/) || {}).input:      index = 5; break;
        case (path.match(/^\/intro_applicant_details/) || {}).input:  index = 6; break;
        case (path.match(/^\/intro_summary/) || {}).input:            index = 7; break;
        default: index = 1;
      }
      setActiveIndicator(index);
      setShowProgress(path.startsWith("/intro"));
    }
  }, [location]);

  const _handleBack = () => history.goBack();
  const _handleNext = () => history.goForward();

  const animation = useSpring({
    from: {opacity: 0, marginTop: -50},
    to: {opacity: 1, marginTop: 0},
    config: {duration: 500}
  });

  return (
    <animated.div className="cp-card mb-2 md:max-w-lg md:mb-4" style={animation}>
      <Form className="cp-form">
        {
          showProgress &&
          <div className="flex items-center justify-between md:px-10 px-4 py-1 w-full">
            <BackButton onClick={_handleBack} />
            <Pagination active={activeIndicator} total="7" />
            <NextButton onClick={_handleNext} />
          </div>
        }
        <div className="border-t border-gray-200 px-4 py-6 md:px-10">
          <div className="cp-font-r24w500g900snug mb-4 md:mb-6 break-words">
            {title}
          </div>
          {
            children
          }
        </div>
      </Form>
    </animated.div>
  );
};

export default withRouter(CardComponent);
