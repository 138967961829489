import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {EMPLOYMENT_PERCENT_OWNERSHIP_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const PercentOwnershipQuestion = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setOwnership = useCallback((flag) => dispatch(EMPLOYMENT_PERCENT_OWNERSHIP_ACTION(flag)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const employmentPercentOwnership = useSelector( state => state.wizardStore.meta.employmentPercentOwnership);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];

  let employerName = "employer";
  if (applicant && applicant.employment && applicant.employment[0] && applicant.employment[0].name) {
    employerName = applicant.employment[0].name;
  }

  const _clickHandler = (key, value) => {
    setOwnership(value);
    navigateNext("employment_monthly_earnings", false, applicantIndex);
  };

  return (
    <CardComponent title={`Does ${applicant.firstName} own 25% or more of ${employerName}'s company?`}>
      <OptionsComponent onClick={_clickHandler} options={employmentPercentOwnership} />
    </CardComponent>
  );
};

export default PercentOwnershipQuestion;
