import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ButtonComponent, CardComponent} from "../../../components";
import {SET_NEXT_PAGE} from "../../../redux/actions";

const DebtsPermissionFeedback = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const inEditMode = useSelector( state => state.wizardStore.meta.inEditMode);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];
  const isGranted = applicant.isDebtPermissionGranted;

  const _handleNext = (state) => {
    navigateNext(state, inEditMode, applicantIndex);
  };

  return (
    <CardComponent title="Thank You!">
      {
        isGranted &&
        <React.Fragment>
          <p className="cp-font-r18w400g500 mb-1 md:mb-4 text-justify">
            We will be able to analyze your currently monthly obligations by reviewing your credit report. Next, we need to know if there are any other monthly obligations.
          </p>
          <ButtonComponent onClick={() => _handleNext("debts_ssn_info")} text="Continue" />
        </React.Fragment>
      }
      {
        !isGranted &&
        <React.Fragment>
          <p className="cp-font-r18w400r500 mb-1 md:mb-4 text-justify">
            Unfortunately we cannot accurately evaluate your lending eligibility without accessing your credit report.
          </p>
          <p className="cp-font-r18w400g500 mb-1 md:mb-4 text-justify">
            If you would like to discuss your lending eligibility in person with a licensed mortgage representative, please call IntroLend at <b>(801)-111-1111</b>
          </p>
          <ButtonComponent onClick={() => _handleNext("debts_permission_question")} text="Back" />
        </React.Fragment>
      }
    </CardComponent>
  );
};

export default DebtsPermissionFeedback;
