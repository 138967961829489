import RUF from "../../../util/redux";

export function employmentMonthlyEarnings(state, action) {
  const applicantsArray = state.model.applicants;
  const currentApplicantIndex = parseInt(state.meta.currentApplicant);
  const currentEmploymentIndex = parseInt(state.meta.currentEmploymentIndex);

  return RUF.updateObject(state, {
    model: RUF.updateObject(state.model, {
      applicants: [
        ...applicantsArray.slice(0, currentApplicantIndex),
        RUF.updateObject(applicantsArray[currentApplicantIndex], {
          employment: [
            ...applicantsArray[currentApplicantIndex].employment.slice(0, currentEmploymentIndex),
            Object.assign({}, applicantsArray[currentApplicantIndex].employment[currentEmploymentIndex], {
              income: action.payload
            }),
            ...applicantsArray[currentApplicantIndex].employment.slice(currentEmploymentIndex+1)
          ]
        }),
        ...applicantsArray.slice(currentApplicantIndex+1)
      ]
    })
  });
}
