import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CardComponent, OptionsComponent} from "../../../components";
import {PERSONAL_MILITARY_STATUS_ACTION, SET_NEXT_PAGE} from "../../../redux/actions";

const PersonalMilitaryStatus = () => {
  const dispatch = useDispatch();
  const navigateNext = useCallback((state, edit, applicant) => dispatch(SET_NEXT_PAGE(state, edit, applicant)), [dispatch]);
  const setMilitaryStatus = useCallback((value) => dispatch(PERSONAL_MILITARY_STATUS_ACTION(value)), [dispatch]);

  const applicants = useSelector( state => state.wizardStore.model.applicants);
  const currentApplicant = useSelector( state => state.wizardStore.meta.currentApplicant);
  const militaryStatusOptions = useSelector( state => state.wizardStore.meta.militaryStatusOptions);
  const applicantIndex = parseInt(currentApplicant.toString());
  const applicant = applicants[applicantIndex];

  const _clickHandler = (key, value) => {
    setMilitaryStatus(value);
    navigateNext("personal_summary", false, applicantIndex);
  };

  return (
    <CardComponent title="Military Affiliation">
      <p className="cp-font-r18w400g500 mb-1 md:mb-4 text-justify">
        Please choose the option that describes the best {applicant.firstName}'s association (if any) with the United States Military.
      </p>
      <OptionsComponent onClick={_clickHandler} options={militaryStatusOptions} />
    </CardComponent>
  );
};

export default PersonalMilitaryStatus;
