import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LogoComponent} from "../logo";
import {CLOSE_MENU_ACTION, OPEN_MENU_ACTION} from "../../redux/actions";

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const closeMenu = useCallback(() => dispatch(CLOSE_MENU_ACTION()), [dispatch]);
  const openMenu = useCallback(() => dispatch(OPEN_MENU_ACTION()), [dispatch]);
  const isMenuOpen = useSelector( state => state.menuStore.isMenuOpen);

  return (
    <header className="border-b border-gray-200 flex flex-col items-stretch md:border-b-0 md:flex-row w-full">
      <div className="cp-header flex flex-grow flex-row items-center md:flex-none md:justify-between md:p-4 p-2">
        <div className="block flex-none md:hidden pl-2 w-40px">
          {
            !isMenuOpen &&
            <button className="focus:outline-none md:hidden" onClick={openMenu}>
              <svg className="cp-header-menu" height="20" width="28" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M0,3 L20,3 L20,5 L0,5 L0,3 Z M0,9 L20,9 L20,11 L0,11 L0,9 Z M0,15 L20,15 L20,17 L0,17 L0,15 Z"/>
              </svg>
            </button>
          }
        </div>
        <div className="flex flex-grow flex-row justify-center md:justify-start">
          <LogoComponent />
        </div>
        <div className="block flex-none pr-2 w-40px">
          {
            isMenuOpen &&
            <button className="focus:outline-none" onClick={closeMenu}>
              <svg className="cp-header-menu" height="20" width="20" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
                <g strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10">
                  <path d="M10 10l45 45M10 55l45-45"/>
                </g>
              </svg>
            </button>
          }
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
