import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import LoanPlan from "./_plans";
import {Redirect} from "react-router";
import {SELECT_LOAN_PLAN_ACTION} from "../../redux/actions";
import {Spinner} from "../../components";

const LoanPlansView = () => {
  const dispatch = useDispatch();
  const selectPlan = useCallback((appID, defID) => dispatch(SELECT_LOAN_PLAN_ACTION(appID, defID)), [dispatch]);

  const hasApprovals = useSelector( state => state.plansStore.evaluationSession.hasApprovals);
  const loanPlans = useSelector( state => state.plansStore.evaluationSession.loanEvaluations);
  const loanApplicationID = useSelector( state => state.plansStore.evaluationSession.loanApplicationID);
  const isSpinnerOn = useSelector( state => state.spinnerStore.isSpinnerOn);

  const _handleSelectPlan = (loanApplicationID, loanDefinitionID) => {
    selectPlan(loanApplicationID, loanDefinitionID);
  };

  return (
    isSpinnerOn ? <Spinner /> : hasApprovals ?
      <div className="flex flex-col flex-grow justify-start">
        <div className="border-b border-gray-300 flex flex-col items-center">
          <div className="flex flex-row pt-8 px-4 w-11/12 md:w-7/12">
            <div className="flex flex-col flex-grow">
              <h1 className="font-sans font-medium text-teal-500 tracking-wide text-4xl mb-2">
                Great job!
              </h1>
              <h1 className="font-sans font-medium mb-4 text-teal-500 tracking-wide text-4xl">
                Your Loan proposal is submitted!
              </h1>
            </div>
            <img
              alt="Intro Lend Loan Options Illustration"
              className="h-24 hidden max-w-md md:block md:h-auto md:w-auto w-64"
              src={process.env.PUBLIC_URL + "assets/illustrations/results.svg"}
            />
          </div>
        </div>
        <div className="flex flex-col flex-grow items-center">
          <div className="pt-8 px-4 w-11/12 md:w-7/12">
            <div className="flex flex-col flex-grow justify-start">
              <h1 className="font-sans font-medium text-gray-900 tracking-wide text-2xl mb-3">
                Last step!
              </h1>
              <h1 className="font-sans font-medium text-gray-900 tracking-wide text-2xl">
                Select the loan type that suites you the best!
              </h1>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-wrap items-center md:items-end justify-center md:flex-row mt-16 w-full">
          {
            loanPlans && loanPlans.filter(plan => plan.decisionResult === "Approve").map((plan, index) => (
              <LoanPlan
                key={`loan-plan-${index}`}
                isBest={false}
                loanAmount={plan.loanAmount}
                loanName={plan.loanName}
                monthlyPayment={plan.monthlyPayment}
                onChange={() => _handleSelectPlan(loanApplicationID, plan.loanDefinitionID)}
              />
            ))
          }
        </div>
      </div>
    : <Redirect to="referred-to-finance" />
  );
};

export default LoanPlansView;
