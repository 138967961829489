import RUF from "../../../util/redux";

export function contactDetails(state, action) {
  const applicantsArray = state.model.applicants;
  const currentApplicantIndex = parseInt(state.meta.currentApplicant);

  return RUF.updateObject(state, {
    model: RUF.updateObject(state.model, {
      applicants: [
        ...applicantsArray.slice(0, currentApplicantIndex),
        Object.assign({}, applicantsArray[currentApplicantIndex], {
          contactInfo: action.payload
        }),
        ...applicantsArray.slice(currentApplicantIndex+1)
      ]
    })
  });
}
