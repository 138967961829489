import RUF from "../../util/redux";

import {
  SET_SPINNER_FLAG
} from "../constants/_spinner_constants";

const initialState = {
  isSpinnerOn: false
};

export default function spinnerReducer(state = initialState, action) {
  switch(action.type) {
    case SET_SPINNER_FLAG: {
      return RUF.updateObject(state, { isSpinnerOn: action.payload });
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
