import {
  DELETE_STIPULATION,
  DOWNLOAD_STIPULATION,
  GET_LOANS,
  GET_LOAN_BIDS,
  GET_LOAN_STIPULATIONS,
  GET_USER_STIPULATIONS,
  SELECT_LOAN_BID,
  UPLOAD_STIPULATION
} from "../../constants/_docs_constants";
import {_callApi} from "../../../util/fetch";

export function GET_LOANS_ACTION() {
  return {
    type: GET_LOANS,
    payload: _callApi(
      "mortgage",
      "/Mortgage/list/customer",
      "GET",
      null
    )
  }
}

export function GET_LOAN_BIDS_ACTION(loanID) {
  return {
    type: GET_LOAN_BIDS,
    payload: _callApi(
      "mortgage",
      `/Mortgage/${loanID}/bids`,
      "GET",
      null
    )
  }
}

export function SELECT_LOAN_BID_ACTION(loanID, bidID) {
  return {
    type: SELECT_LOAN_BID,
    payload: _callApi(
      "mortgage",
      `/Mortgage/${loanID}/bids/${bidID}/select`,
      "PUT",
      {"lenderBidID": bidID, "loanApplicationID": loanID}
    )
  }
}

export function GET_LOAN_STIPULATIONS_ACTION(loanID) {
  return {
    type: GET_LOAN_STIPULATIONS,
    payload: _callApi(
      "loanApplications",
      `/LoanApplications/${loanID}/applicationstipulation`,
      "GET",
      null
    )
  }
}

export function GET_USER_STIPULATIONS_ACTION(loanID) {
  return {
    type: GET_USER_STIPULATIONS,
    payload: _callApi(
      "loanApplications",
      `/LoanApplications/${loanID}/stipulations`,
      "GET",
      null
    )
  }
}

export function UPLOAD_STIPULATION_ACTION(name, mimeType, loanApplicationID, applicantID, applicationStipulationID, stipulationDefinitionID, content) {
  return {
    type: UPLOAD_STIPULATION,
    payload: _callApi(
      "loanApplications",
      `/LoanApplications/${loanApplicationID}/stipulations`,
      "POST",
      {
        name, mimeType, loanApplicationID, applicantID, applicationStipulationID, stipulationDefinitionID, source: "consumer", content
      }
    )
  }
}

export function DELETE_STIPULATION_ACTION(loanApplicationID, userStipulationID) {
  return {
    type: DELETE_STIPULATION,
    payload: _callApi(
      "loanApplications",
      `/LoanApplications/${loanApplicationID}/stipulations`,
      "DELETE",
      {
        loanApplicationID, userStipulationID
      }
    )
  }
}

export function DOWNLOAD_STIPULATION_ACTION(loanApplicationID, userStipulationID) {
  return {
    type: DOWNLOAD_STIPULATION,
    payload: _callApi(
      "loanApplications",
      `/LoanApplications/${loanApplicationID}/stipulations/${userStipulationID}`,
      "GET",
      null
    )
  }
}
